<template>
  <div
      class="filter-bar d-flex justify-space-between align-center pa-3 text-h6"
  >
    {{filterText}}
    <v-spacer></v-spacer>
    <v-btn
        outlined
        color="#fff"
        :disabled="!this.appliedFilters.length"
        @click="onClick">
      {{$t('Clear filters')}}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ColleaguesFilterBar",
  props: {
    appliedFilters: {
      default: []
    },
    hiddenResults: {
      default: '0'
    }
  },
  methods: {
    onClick() {
      this.$emit('clearFilters');
    }
  },
  computed: {
    filterText() {
      return i18n.t(
          '{result_hidden} results are hidden by filters',
          { result_hidden: this.hiddenResults }
      );
    }
  }
}
</script>

<style scoped>
 .filter-bar {
   background-color: #1976d2;
   color: white;
 }

</style>