export const enumerateDaysBetweenDates = (startDate, endDate, format='YYYY-MM-DD') => {
  const now = startDate.clone(), dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format));
    now.add(1, 'days');
  }
  return dates;
};


export const enumerateMonthsBetweenDates = (startDate, endDate, format='YYYY-MM') => {
  const now = startDate.clone(), months = [];
  
  while (now.isSameOrBefore(endDate)) {
    months.push(now.format(format))
    now.add(1, 'months');
  }

  return months;
}