const chartMixin = {
  props: {
    columns: { type: Array },
    rows: { type: Array },
  },
  methods: {
    drawChart() {
      this.$refs.chart.drawChart();
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener('resize', vm.drawChart);
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener('resize', vm.drawChart);
  },
};

export default chartMixin;
