<template>
  <v-app>
    <v-card
        flat
        height="100%"
        tile
    >
      <NavigationBar :is-home-page="false"/>
      <v-alert
          v-model="notification.show"
          class="cancel-notifications"
          :type="notification.alertType"
          dense
          border="left"
          dismissible
      >{{notification.title}}
      </v-alert>
      <div class="reservations-subheader d-flex justify-space-between">
        <span>{{$t("My reservations")}}</span>
        <v-btn
            icon
            color="#25D366"
        >
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </div>
      <ReservationsList @onReservationAction="onAction"/>
    </v-card>
  </v-app>
</template>

<script>
import ReservationsList from "./ReservationsList";
import NavigationBar from "../NavigationBar/NavigationBar";

export default {
  name: "Reservations",
  components: {
    NavigationBar,
    ReservationsList
  },
  data: () => ({
    notification: {
      show: false,
      alertType: 'success',
      title: i18n.t('Sucessfully!')
    }
  }),
  methods: {
    onAction(response) {
      this.notification.show = true;
      if (response.error && response.errorInfo.code !== 'NO_EXISTS') {
        this.notification = {
          show: true,
          alertType: 'error',
          title: i18n.t('Something went wrong')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reservations-subheader {
  margin: 10px 0;
  font-size: 1.2rem;
  padding: 10px;
}

.cancel-notifications {
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 25;
}
</style>