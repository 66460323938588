<script>

  import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
  import Loading from 'vue-loading-overlay';


  export default {
    name: 'App',
    components: {
      NprogressContainer,
      Loading
    },
    data() {
      return {
        isLoading: false
      }
    },
    computed: {
      init() {
        console.log('this.$store.state.auth.init')
        console.log(this.$store.state.auth.init)
        return this.$store.state.auth.init
      }
    },
    created() {
      // this.$store.dispatch('auth/refresh')
    }
  };
</script>

<template>
  <v-app id="app" name="app" class="az-body">
    <nprogress-container></nprogress-container>
    <router-view></router-view>
  </v-app>
</template>


<style src="@/assets/scss/index.scss" lang="scss" />