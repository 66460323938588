<script>
/* eslint-disable no-underscore-dangle */
import { Bar } from 'vue-chartjs';

const chartStructure = props => ({
  labels: props.labels,
  datasets: [
    {
      type: props.chartType,
      label: 'rooms/floors occupation',
      backgroundColor: '#6f42c1',
      data: props.data,
    },
    {
      type: props.chartType,
      label: 'free',
      backgroundColor: '#3bb143',
      data: 0
    },
    {
      type: props.chartType,
      label: 'out of use',
      backgroundColor: '',
      data: 0
    }
  ],
});

const options = {
  title: {
    display: false,
  },
  scales: {
    yAxes: [{
      display: true,
      stacked: true,
      ticks: {
        beginAtZero: true,
      },
    }],
  },
};

export default {
  name: 'date-chart',
  extends: Bar,
  props: ['data', 'labels', 'chartType'],
  mounted() {
    this.renderChart(chartStructure(this), options);
  },
  watch: {
    data() {
      this.renderChart(chartStructure(this), options);
    },
    chartType() {
      this.renderChart(chartStructure(this), options);
    },
  },
};
</script>
