<template>
  <div>
    <div
        v-if="infoNoWorking"
        class="text-center pa-12 text-h6"
    >{{infoNoWorking}}
    </div>
    <div
        v-if="activeWorkHome"
        class="text-center pa-12 text-h6"
    >{{activeWorkHome}}
    </div>
        <div
            v-if="activeMeeting"
            style="background-color: #5F6364"
            class="clickable-info text-center pa-12 text-h6"
        >{{activeMeeting.text}}
        </div>
    <div
        v-if="activeWorkspace"
        v-ripple
        @click="showOnMap(activeWorkspace.localization)"
        class="clickable-info text-center pa-12 text-h6"
    >{{activeWorkspace.text}}
    </div>
    <div
        v-if="pendingMeeting"
        v-for="(meeting, index) in pendingMeeting" :key="index"
        v-ripple
        @click="showOnMap(pendingMeeting.localization)"
        style="background-color: green"
        class="clickable-info text-center pa-12 text-h6"
    >{{meeting.text}}
    </div>
    <div
        v-if="pendingWorkspace"
        v-for="(meeting, index) in pendingWorkspace" :key="index"
        v-ripple
        @click="showOnMap(pendingWorkspace.localization)"
        style="background-color: yellow"
        class="clickable-info text-center pa-12 text-h6"
    >{{meeting.text}}
    </div>
  </div>
</template>

<script>
import {CheckInEnum} from "../../variables";

export default {
  name: "ColleagueWorkInfo",
  props: {
    userInfo: {
    }
  },
  methods: {
    getCheckinDescription(checkin) {
      if (checkin.type === CheckInEnum.WORKSPACE) {
        return `${checkin.desk.room.floor.building.name} / ${checkin.desk.room.floor.name} / ${checkin.desk.room.name} / ${checkin.desk.name}`;
      }
      if (checkin.type === CheckInEnum.MEETING) {
        return `${checkin.room.floor.building.name} / ${checkin.room.floor.name} / ${checkin.room.name}`;
      }
      return null;
    },
    showOnMap(location) {
      const item = location.desk || location.room;
      this.$store.commit('app/set_building', item.room.floor.building);
      this.$store.commit('app/set_floor', item.room.floor);
      this.$store.commit('app/set_room', item.room);
      this.$store.commit('app/set_desk', item);
      this.$store.commit('findDesk/set_searched_desk', item);
      this.$router.push({ name: 'floor_layout' });
    }
  },
  computed: {
    infoNoWorking() {
      if (this.userInfo && this.userInfo.active_checkins && this.userInfo.active_checkins.length) {
        return null;
      }

      return i18n.t('No checked in at workplace');
    },
    activeWorkHome() {
      if (!this.userInfo || !this.userInfo.active_checkins) {
        return null;
      }
      const homeworkCheckin = this.userInfo.active_checkins.find(
          (c) => c.type === CheckInEnum.HOMEWORK
      );
      if (!homeworkCheckin) {
        return null;
      }
      return i18n.t('Homework');
    },
    activeMeeting() {
      if (!this.userInfo || !this.userInfo.active_checkins) {
        return null;
      }
      const meetingCheckin = this.userInfo.active_checkins.find(
          (c) => c.type === CheckInEnum.MEETING
      );

      if (!meetingCheckin) {
        return null;
      }

      return {
        text: i18n.t('He is in meeting: {place}',
            { place: this.getCheckinDescription(meetingCheckin),}
        ),
        localization: {
          desk: null,
          room: meetingCheckin.room
        }
      }
    },
    activeWorkspace() {
      if (!this.userInfo || !this.userInfo.active_checkins) {
        return null;
      }
      const workspaceCheckin = this.userInfo.active_checkins.find(
          (c) => c.type === CheckInEnum.WORKSPACE
      );

      if (!workspaceCheckin) {
        return null;
      }

      return {
        text: `${i18n.t('Reserved')}:\n\n${this.getCheckinDescription(workspaceCheckin)}`,
        localization: {
          desk: workspaceCheckin.desk,
          room: null
        }
      }
    },
    pendingMeeting() {
      if (!this.userInfo || !this.userInfo.pending_bookings) {
        return null;
      }

      const pendingMeeting = this.userInfo.pending_bookings.filter(
          (el) => el.room != null
      );

      if (!pendingMeeting.length) {
        return null;
      }

      return pendingMeeting.map((pm) => {
        const pending_text = i18n.t(
            'He might be in meeting but he may arrive at {time}: \n {place}',
            {
              place: this.getCheckinDescription(pm),
              time: pm.time,
            }
        );

        const accepted_text = i18n.t(
            'He might be in meeting but he will arrive at {time}: \n {place}',
            {
              place: this.getCheckinDescription(pm),
              time: pm.time,
            }
        );

        return {
          text: pm.accepted ? accepted_text : pending_text,
          localization: {
            desk: null,
            room: pm.room
          }
        }
      });
    },
    pendingWorkspace() {
      if (!this.userInfo || !this.userInfo.pending_bookings) {
        return null;
      }
      const pendingWorkspace = this.userInfo.pending_bookings.filter(
          (el) => el.desk != null
      );

      if (!pendingWorkspace.length) {
        return null;
      }

      return pendingWorkspace.map((pm) => {
        const pending_text = i18n.t(
            'He might be in desk but he may arrive at {start_time}: \n {place}',
            {
              place: this.getCheckinDescription(pm),
              start_time: pm.start_time,
            }
        );

        const accepted_text = i18n.t(
            'He might be in desk but he will arrive at {start_time}: \n {place}',
            {
              place: this.getCheckinDescription(pm),
              start_time: pm.start_time,
            }
        );

        return {
          text: pm.accepted ? accepted_text : pending_text,
          localization: {
            desk: pm.desk,
            room: null
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.clickable-info {
  transition: 0.3s;

  &::before {
    background-color: transparent;
  }

  &:hover {
    background-color: #F5F5F5;
    cursor: pointer;
  }
}
</style>