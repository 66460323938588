<template>
  <v-btn :style="cssVars"
         height="100%"
         :disabled="disabled"
         :ripple="false"
         text
  >
    <v-icon dark size="105"> {{ this.icon }} </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "IconDiamondButton",
  props: {
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
    }
  },
  computed: {
    cssVars () {
      return {
        backgroundColor : this.color,
        opacity: this.hide ? 0 : 1
      }
    }
  },
}
</script>

<style scoped lang="scss">
.v-btn {
  width: 50%;
  display: inline-block;


  ::v-deep span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
</style>