<template>
  <div id="infoBox"
       ref="infoBox"
       class="floor-map-container">
    <v-img v-if="loadMap.succeed && !loadMap.loading && !loadMap.error && !isNaN(imageW) && !isNaN(imageH)"
        contain
        style="position:relative"
        height="80vh"
        v-resize="onResize"
        :src="mapSrc"
    />
    <v-progress-circular
        v-if="loadMap.loading && !loadMap.error"
        class="floor-map-progress"
        indeterminate
        :value="100"
        color="primary"
    ></v-progress-circular>
    <IconPin
        v-if="!loadMap.loading && !loadMap.error && !isNaN(imageW) && !isNaN(imageH)"
        v-for="desk in this.mapDesks"
        :blink="shouldBlink(desk.id)"
        :deskInfo="desk"
        :pin-size="pinSize"
        :show-desk="showDesk"
        :show-person="showPerson"
        @iconClick="onDeskClick"
    />
    <MapNotAvailable v-if="isMapAvailable"/>
    <DeskDetails :is-open="dialog" :desk="selectedDesk" :display-map="false" @onClose="onDialogClose"/>
  </div>
</template>

<script>
import IconPin from "../IconPin/IconPin";
import MapNotAvailable from "../FindDesk/MapNotAvailable";
import DeskDetails from "../FindDesk/DeskDetails";

const floorMapImg = new Image();

export default {
  name: "FloorMap",
  components: {
    IconPin,
    MapNotAvailable,
    DeskDetails
  },
  props: {
    mapSrc: {},
    date: {},
    floorId: {},
    selectedOptions: {}
  },
  data: () => ({
    dialog: false,
    selectedDesk: {},
    floorDesks: [],
    mapDesks: [],
    containerSize: {
      windowRatio: null,
      windowWidth: null,
      windowHeight: null
    },
    imageW: null,
    imageH: null,
    mapImage: {
      x: 0,
      y: 0
    },
    pinSize: 14,
    loadMap: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  watch: {
    mapSrc(val) {
      floorMapImg.src = val;
      this.mapContainerSizes(this.$refs.infoBox);
    },
    async date(newDate) {
      await this.getFloorDesks(this.floorId, newDate);
    }
  },
  computed: {
    showDesk() {
      return this.selectedOptions && this.selectedOptions.includes(i18n.t('Desk'));
    },
    showPerson() {
      return this.selectedOptions && this.selectedOptions.includes(i18n.t('Person'));
    },
    isMapAvailable() {
      return this.loadMap.error || isNaN(this.imageW) || isNaN(this.imageH);
    }
  },
  async created (){
    floorMapImg.onload = async () => {
      this.imageDimensions(floorMapImg);
      await this.getFloorDesks(this.floorId, this.date);
    }
    floorMapImg.src = this.mapSrc;
  },
  methods: {
    shouldBlink(deskId) {
      if (!this.$store.state.findDesk ||  !this.$store.state.findDesk.searchedDesk) {
        return false;
      }

      return deskId === this.$store.state.findDesk.searchedDesk.id;
    },
    async getFloorDesks(id, date) {
      this.loadMap = {
        ...this.loadMap,
        loading: true,
        succeed: false
      }
      try {
        const response = await this.$http.get(`/floors/${id}/desks?date_query=${date}`);
        this.floorDesks = [].concat(response.data);
        this.mapDesks = [].concat(response.data).map(this.recalculateDeskCoordinates);
        this.loadMap.succeed = true;
      } catch (e) {
        this.loadMap = {
          ...this.loadMap,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.loadMap.loading = false;
      }

    },
    recalculateDeskCoordinates(desk) {
      return {
        ...desk,
        ...this.percentageToXY(desk.x, 100 - desk.y, this.pinSize / 2)
      };
    },
    mapContainerSizes(el) {
      this.containerSize.windowRatio = el.clientWidth / el.clientHeight;
      this.containerSize.windowHeight = el.clientHeight;
      this.containerSize.windowWidth = el.clientWidth;
    },
    onResize () {
      this.mapContainerSizes(this.$refs.infoBox);
      this.imageDimensions(floorMapImg);
      this.mapDesks = this.floorDesks.map(this.recalculateDeskCoordinates);
    },
    imageDimensions(img) {
      const imageRatio = img.width / img.height;
      let imageW = img.width;
      let imageH = img.height;

      if (imageRatio > this.containerSize.windowRatio) {
        imageW = this.containerSize.windowWidth;
        imageH = this.containerSize.windowWidth / imageRatio;
      } else {
        imageW = imageRatio * this.containerSize.windowHeight;
        imageH = this.containerSize.windowHeight;
      }

      this.mapImage = {
        x: this.containerSize.windowWidth / 2 - imageW / 2,
        y: this.containerSize.windowHeight / 2 - imageH / 2
      }
      this.imageH = imageH;
      this.imageW = imageW;
    },
    percentageToXY(x, y, pinSize=0) {
      if (!this.mapImage || !this.imageW || !this.imageH)
        return {x: 0, y: 0};
      return {
        x: this.mapImage.x + (x / 100) * this.imageW - pinSize,
        y: this.mapImage.y + (y / 100) * this.imageH - pinSize,
      };
    },
    onDeskClick(desk) {
      this.selectedDesk = desk;
      this.dialog = true;
    },
    onDialogClose() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped>
.floor-map-progress {
  display: block;
  width: 100px;
  height: 40vh !important;
  margin: 0 auto;
}

.floor-map-container {
  position: relative;
  display: block;
  overflow: hidden;
}
</style>