export const COLORS = {
  white: '#fff',
  black: '#000',
  lighterBlue: '#d9e3eb',
  lightBlue: '#85b0cc',
  blue: '#4785bb',
  darkBlue: '#0055bb',
  darkerBlue: '#306ea1',
};

export const BREAKPOINTS = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
};
