<template>
  <v-item-group
      v-model="selected"
      @change="onTimeSlotChange"
      multiple>
    <v-container>
      <v-row>
        <v-col
            v-for="(tSlot, index ) in timeSlots"
            :key="index"
            class="time-slot-col"
            cols="12"
            xs="6"
            sm="4"
            md="2"
        >
          <v-item
              :value="tSlot.timetable"
              :disabled="disabledAll || !!getTimeSlotStatus(tSlot)"
              v-slot="{ active, toggle }">
            <v-card
                :disabled="disabledAll || !!getTimeSlotStatus(tSlot)"
                class="d-flex align-center"
                :style="setStyle(tSlot)"
                @click="toggle"
            >
              <span class="time-slot-value">{{tSlot.time}}</span>
              <v-scroll-y-transition>
                <div v-if="active"/>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>

const item_colors = {
  used: {
    borderTop: '#F44336',
    bg: '#FFEBEE',
  },
  free: {
    borderTop: '#818281',
    bg: '#E8E9E8',
  },
  owner: {
    borderTop: 'green',
    bg: '#E8F5E9',
  },
  active_checkin: {
    borderTop: '#F44336',
    bg: '#FFEBEE',
  },
  exceeded_limit: {
    borderTop: '#F44336',
    bg: '#FFEBEE',
  },
};

export default {
  name: "DeskTimeSlots",
  data: () => ({
    selected: []
  }),
  props: {
    timeSlots: {
    },
    disabledAll:{
      default: false
    }
  },
  watch: {
    timeSlots() {
      this.selected = [];
    }
  },
  methods: {
    onTimeSlotChange (event) {
      this.$emit('timeSlots', this.selected)
    },
    getTimeSlotStatus(timeSlot) {
      const slotOptions = [
        'exceeded_limit',
        'active_checkin',
        'used',
        'reached_max_by_building',
        'reached_max_by_floor',
        'owner'
      ];

      for (const option of slotOptions) {
        if (timeSlot[option]) {
          return option;
        }
      }

    },
    setStyle(item) {
      const color_key = this.getTimeSlotStatus(item) || 'free';

      return {
        backgroundColor: item_colors[color_key].bg,
        borderTop: `1px solid ${item_colors[color_key].borderTop}`
      }
    }
  }
}
</script>

<style scoped>
.time-slot-col {
  min-width: 180px;
}

.time-slot-value {
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.4rem;
  margin: 15px auto;
}
.v-item-group .v-item--active {
  background-color: #CDDC39 !important;
  border-top: 1px solid #E6EDA2 !important;
}

</style>