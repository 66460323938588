<template>
  <div>
    <div class="text-center user-avatar-container">
      <v-badge
          overlap
          tile
          bordered
          :color="userInfo.active_checkins && userInfo.active_checkins.length ? 'success': 'error'"
      >
      <v-avatar
          class="user-avatar"
          color="grey lighten-1"
          rounded
          size="144"
      >
        <v-icon dark size="128">
          mdi-account-circle
        </v-icon>
      </v-avatar>
      </v-badge>
    </div>
    <div class="user-full-name">
      <span class="h2">{{userName}}</span>
      <span class="h4">{{userStatus}}</span>
    </div>
    <ColleagueWorkInfo :user-info="userInfo"/>
  </div>
</template>

<script>
import {API_VERSION, CheckInEnum} from "../../variables";
import ColleagueWorkInfo from "./ColleagueWorkInfo";

export default {
  name: "ColleagueDetails",
  components: {
    ColleagueWorkInfo
  },
  data: () => ({
    getUserDetails: {
      loading: false,
          error: false,
          errorInfo: null,
          succeed: false
    },
    userInfo: {}
  }),
  async mounted() {
    await this.getUsersList()
  },
  methods: {
    async getUsersList() {
      this.getUserDetails = {
        ...this.getUserDetails,
        loading: true,
        succeed: false
      }
      try {
        const response = await this.$http.get(`users/detail/${this.$route.params.id}`,
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.userInfo = response.data;
        this.getUserDetails.succeed = true;
      } catch (e) {
        this.getUserDetails = {
          ...this.getUserDetails,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.getUserDetails.loading = false;
      }
    },
    getCheckinDescription(checkin) {
      if (checkin.type === CheckInEnum.WORKSPACE) {
        return `${checkin.desk.room.floor.building.name} / ${checkin.desk.room.floor.name} / ${checkin.desk.room.name} / ${checkin.desk.name}`;
      }
      if (checkin.type === CheckInEnum.MEETING) {
        return `${checkin.room.floor.building.name} / ${checkin.room.floor.name} / ${checkin.room.name}`;
      }
      return null;
    }
  },
  computed: {
    userName() {
      return this.userInfo.first_name && this.userInfo.last_name
          ? `${this.userInfo.first_name} ${this.userInfo.last_name}` : '';
    },
    userStatus() {
      if (!this.userInfo || !this.userInfo.profile || !this.userInfo.profile.status) {
        return i18n.t('Unknown');
      }

      const status = () => {
        const user_status = this.userInfo.profile.status.find(
            (s) => s.id === this.userInfo.profile.status
        );
        if (user_status) {
          return user_status.name;
        }

        return i18n.t('Unknown');
      };

      return i18n.t('Status: {status}', {
        status: status(),
      });
    }
  }
}
</script>

<style scoped lang="scss">
.user-avatar-container {
  margin-top: 10vh;
  .v-badge {
    transform: rotate(45deg);
    .user-avatar {
      display: block;
      margin: auto;
        .v-icon {
          transform: rotate(-45deg);
        }
    }
  }
}

.user-full-name {
  margin-top: 50px;
  span {
    display: block;
    text-align: center;
  }
}
</style>