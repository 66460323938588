<script>
import chartMixin from '../mixins/chartMixin';

export default {
  name: 'donut-chart',
  mixins: [chartMixin],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        pieSliceText: 'none',
        chartArea: { left: '0', width: '90%' },
        legend: { position: 'right', alignment: 'begin' },
        pieHole: 0.5,
        height: 300,
      },
    };
  },
};
</script>

<template>
<vue-chart
  chart-type="PieChart"
  :columns="columns"
  :rows="rows"
  :options="options"
  ref="chart"
/>
</template>
