<script>
import { BDropdown, BModal, FormDatepickerPlugin } from 'bootstrap-vue'
import BuildingSwitcher from '@/components/Dashboard/components/BuildingSwitcher.vue';
import { DateTimePicker, DateTimePickerModal } from "@lazy-copilot/datetimepicker";

export default {
  inject: ['filter'],
  components: {
    BModal,
    BDropdown,
    BuildingSwitcher,
    DateTimePicker,
    DateTimePickerModal,
  },
  name: 'v-header-select',
  computed: {
    setDaysOfTheWeek() {
      return [
        { field: 100, label: this.$t('dashboard.days_of_the_week.all') },
        { field: 0,   label: this.$t('dashboard.days_of_the_week.sunday') },
        { field: 1,   label: this.$t('dashboard.days_of_the_week.monday') },
        { field: 2,   label: this.$t('dashboard.days_of_the_week.tuesday') },
        { field: 3,   label: this.$t('dashboard.days_of_the_week.wednesday') },
        { field: 4,   label: this.$t('dashboard.days_of_the_week.thursday') },
        { field: 5,   label: this.$t('dashboard.days_of_the_week.friday') },
        { field: 6,   label: this.$t('dashboard.days_of_the_week.saturday') },
      ];
    },
  },
  methods: {
    choosingDayOftheWeek(dayOfTheWeek) {
      this.filter.choosenDayOfTheWeek = dayOfTheWeek;
    },
    setDateRange($event){
      this.filter.timeFrom = `${$event.startTime.HH}:${$event.startTime.mm}`
      this.filter.timeTo = `${$event.endTime.HH}:${$event.endTime.mm}`
      this.filter.dateFrom = $event.startDate
      this.filter.dateTo = $event.endDate
      this.$bvModal.hide('modal-1')
      this.filter.onChangeDate()
    },
  },
  created() {
    this.$attrs['only-select-building'] = this.$attrs['only-select-building'] || false
    this.$attrs['start-one-building'] = this.$attrs['start-one-building'] || true
  }
}
</script>
<template>
  <div class="az-content-header-right">
    <b-modal 
      ref="modal-calendar"
      id="modal-1"
      hide-header
      hide-footer
    >
      <date-time-picker-modal 
        @cancelHandler="() => this.$bvModal.hide('modal-1')"
        @submitHandler="setDateRange($event)"
        :startDate="filter.dateFrom"
        :endDate="filter.dateTo"
        :singleDate="false"
        timeFormat="HH:mm"

      />
    </b-modal>

    <div class="media">
      <div class="media-body" v-b-modal.modal-1>
        <label>{{ $t('dashboard.search.start_datetime') }}</label>
        <h6>{{ filter.dateFrom ? `${this.$moment(filter.dateFrom).format('MMM Do YY')} ${filter.timeFrom}` : '-' }}</h6>
      </div>
    </div>
    <div class="media">
      <div class="media-body" v-b-modal.modal-1>
        <label>{{ $t('dashboard.search.end_datetime') }}</label>
        <h6>{{ filter.dateTo ? `${this.$moment(filter.dateTo).format('MMM Do YY')} ${filter.timeTo}` : '-' }}</h6>
      </div>
    </div>
    <div class="media">
      <div class="media-body" v-if="filter.showDaysOfTheWeek">
        <label>{{ $t('dashboard.search.day_of_the_week') }}</label>
        <b-dropdown v-if="setDaysOfTheWeek" variant="link" toggle-class="text-decoration-none" no-caret>
          <template slot="button-content">
            <h6>{{filter.choosenDayOfTheWeek.label}}</h6>
          </template>
          <b-dropdown-item v-for="dayOfTheWeek in setDaysOfTheWeek" v-bind:key="dayOfTheWeek.label" @click="choosingDayOftheWeek(dayOfTheWeek)">
            {{ dayOfTheWeek.label }}
          </b-dropdown-item>
        </b-dropdown>

      </div>
    </div>
    <div class="media">
      <div class="media-body">
        <label>{{ $t('dashboard.search.buildings') }}</label>
        <building-switcher 
          :only-select-building="$attrs['only-select-building']"
          :start-one-building="$attrs['start-one-building']" 
          :current="$route.params.id" 
          class="flex-nogrow" 
        />
      </div>
    </div>

    <!-- <a href="#/" class="btn btn-purple">Export</a> -->
  </div>
</template>