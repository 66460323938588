<template>
  <v-card
      flat
      tile
  >
  <NavigationBar :is-home-page="false"/>
  <router-view/>
  </v-card>
</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";

export default {
  name: "ColleaguesView",
  components: {
    NavigationBar
  }
}
</script>

<style scoped>

</style>