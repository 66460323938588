<template>
  <v-btn
      v-if="(!isReservationExpired() && (isDesk || isRoom)) && canCheckInOrOut(type)"
      text
      style="background-color: #fff"
      color="success"
      @click.stop="onCheck"
      :loading="checkInReservation.loading"
  >
    <v-icon left>
      mdi-check-bold
    </v-icon>
    {{statusText()}}
  </v-btn>
</template>

<script>
import moment from "moment";
import {ActionButtonsEnum, API_VERSION, CheckInEnum} from "../../variables";

export default {
  name: "ReservationCheckInButton",
  props: {
    reservation: {
    },
  },
  data: () => ({
    checkInReservation: {
      actionFrom: ActionButtonsEnum.CHECK_IN,
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  methods: {
    isReservationExpired() {
      const reservationStartTime = this.reservation.time.split('-')[0].trim();
      const reservationEndTime = this.reservation.time.split('-')[1].trim();
      return !moment().isBetween(`${this.reservation.date} ${reservationStartTime}`, `${this.reservation.date} ${reservationEndTime}`);
    },
    isDesk() {
      return !!this.reservation.desk;
    },
    isRoom() {
      return !!this.reservation.room;
    },
    async checkInWithoutBeacon() {
      this.checkInReservation = {
        ...this.checkInReservation,
        loading: true,
        succeed: false
      }
      try {
        await this.$http.post(
            `checkins/`,
            {
              desk: this.reservation.desk.id,
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.checkInReservation.succeed = true;
      } catch (e) {
        this.checkInReservation = {
          ...this.checkInReservation,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.checkInReservation.loading = false;
      }
    },
    async checkOutBeacon() {
      const checkInId = this.deskCheckin ? this.deskCheckin.id : this.meetingCheckin?.id;
      this.checkInReservation = {
        ...this.checkInReservation,
        loading: true,
        succeed: false
      }
      try {

        await this.$http.delete(
            `checkins/${checkInId}`,
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.checkInReservation.succeed = true;
      } catch (e) {
        this.checkInReservation = {
          ...this.checkInReservation,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.checkInReservation.loading = false;
      }
    },
    async onCheck() {
      this.isCheckIn ? await this.checkOutBeacon() : await this.checkInWithoutBeacon();
      this.$emit('onCheck', this.checkInReservation)
    },
    canCheckInOrOut(type) {
      switch (type) {
        case CheckInEnum.HOMEWORK:
          if (this.$store.state.configuration.settings.checkin.checkin_homework_read_only_mode) return false;
          break;
        case CheckInEnum.MEETING:
          if (this.$store.state.configuration.settings.checkin.checkin_meeting_read_only_mode) return false;
          break;
        case CheckInEnum.WORKSPACE:
          if (this.$store.state.configuration.settings.checkin.checkin_workspace_read_only_mode) return false;
          break;
        default:
          break;
      }
      return true;
    },
    statusText() {
      return this.isCheckIn ? i18n.t('Check out') : i18n.t('Check in');
    }
  },
  computed: {
    confirmationIsWaiting() {
      return this.reservation.status === 'waiting';
    },
    actualDate() {
      return moment().startOf('day').format('YYYY-MM-DD');
    },
    type() {
      return this.reservation.desk ?
          CheckInEnum.WORKSPACE : this.reservation.room ?
              CheckInEnum.MEETING : null;
    },
    isCheckIn() {
      return !!(this.deskCheckin || this.meetingCheckin);
    },
    deskCheckin() {
      return this.reservation.desk ? this.$store.state.auth.user.active_checkins.find(
          (c) =>
              c.type === CheckInEnum.WORKSPACE &&
              c.desk &&
              c.desk.id === this.reservation.desk.id
      ) : null;
    },
    meetingCheckin() {
      return this.reservation.room ? this.$store.state.auth.user.active_checkins.find(
          (c) => c.type === CheckInEnum.MEETING && c.room && c.room.id === this.reservation.room.id
      ) : null;
    },
  }
}
</script>

<style scoped>

</style>