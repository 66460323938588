<template>
  <v-app>
    <v-card
        flat
        height="100%"
        tile
    >
      <NavigationBar :is-home-page="false"/>
      <v-subheader class="map-subheader">
        <span class="map-building-name">{{building}}</span>
        <span class="map-floor-name">{{floor}}</span>
      </v-subheader>
      <FloorMapDisplayOptions @changed="onOptionChange"/>
      <DatePickerScroll @dateChange="onDateChange"/>
      <FloorMap
          v-if="!mapError"
          :mapSrc="map"
          :date="selectedDate"
          :floor-id="floorId"
          :selected-options="selectedOptions"/>
      <MapNotAvailable v-if="mapError" />
    </v-card>
  </v-app>
</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";
import FloorMapDisplayOptions from "./FloorMapDisplayOptions";
import DatePickerScroll from "../DateSlider/DateSlider";
import FloorMap from "./FloorMap";
import moment from "moment";
import MapNotAvailable from "../FindDesk/MapNotAvailable";

export default {
  name: "FloorLayout",
  components: {
    NavigationBar,
    FloorMapDisplayOptions,
    DatePickerScroll,
    FloorMap,
    MapNotAvailable
  },
  data: () => ({
    floorId: null,
    building: null,
    floor: null,
    map: '',
    selectedDate: moment().startOf('day').format('YYYY-MM-DD'),
    selectedOptions: [],
    desks: null,
    mapError: false
  }),
  async created (){
    await this.getFloorMap(this.$store.state.app.floor.id);
  },
  mounted() {
    this.floorId = this.$store.state.app.floor.id;
    this.building = this.$store.state.app.building.name;
    this.floor = this.$store.state.app.floor.name;
  },
  methods: {
    async getFloorMap(floorId) {
      this.map = null;
      this.mapError = null;
      this.$http.get(`/floors/${floorId}/map`).then((response) => {
        if (!response.data.map) {
          this.mapError = true;
        } else {
          this.map = response.data.map;
        }
      }).catch((error) => {
        this.mapError = true;
      });
    },
    percentageToXY(x, y, pinSize=0) {
      return {
        x: (x/100) * this.imageW - pinSize,
        y: (y/100) * this.imageH - pinSize,
      };
    },
    onOptionChange (options) {
      this.selectedOptions = options;
    },
    onDateChange (newDate) {
      this.selectedDate = newDate;
    }
  }
}
</script>

<style scoped lang="scss">
.map-subheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .map-building-name {
    font-weight: bold;
    font-size: 1rem;
  }

  .map-floor-name {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
</style>