<script>

export default {
  name: 'building-switcher',
  data() {
    return {
      selected: null,
      buildings: null,
      floors: null,
      selected_floor: null,
      rooms: null,
      selected_room: null
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name
    },
    activeBuilding() {
      const buildingId = parseInt(this.$route.params.id, 10);
      return this.buildings.find(b => b.id === buildingId) || (this.$attrs['start-one-building'] ? this.buildings[0] : null);
    },
    activeFloor() {
      const floorId = parseInt(this.$route.params.floor_id, 10) || 'all';
      return this.floors.find(b => b.id === floorId);
    },
    activeRoom() {
      const roomId = parseInt(this.$route.params.room_id, 10) || 'all';
      return this.rooms.find(b => b.id === roomId);
    },
  },
  mounted() {
    this.$http.get('/buildings').then((response) => {
      this.buildings = response.data;
      const current = this.buildings.find(i => i.id === this.$route.params.id);

      if (this.$attrs['start-one-building']) {
        this.selected = (current || this.buildings[0]);
        this.getFloors(this.selected.id);
      }

    });
  },
  methods: {
    getFloors(id) {
      if (this.$attrs['only-select-building']) return

      this.$http.get(`/buildings/${id}/floors/`).then((response) => {
        this.floors = [{ name: 'Alle', id: 'all' }].concat(response.data);
        const current = this.floors.find(i => i.id === this.$route.params.floor_id);
        this.selected_floor = (current || this.floors[0]);
      });
    },
    getRooms(id) {
      this.$http.get(`/floors/${id}/rooms/`).then((response) => {
        this.rooms = [{ name: 'Alle', id: 'all' }].concat(response.data.rooms);
        const current = this.rooms.find(i => i.id === this.$route.params.room_id);
        this.selected_room = (current || this.rooms[0]);
      });
    },
  },
  watch: {
    $route(to, _from) {
      if (to.params.id !== _from.params.id && to.params.id !== 'all') {
        this.getFloors(to.params.id);
      }
      if (to.params.floor_id !== _from.params.floor_id && to.params.floor_id !== 'all') {
        this.getRooms(to.params.floor_id);
      } else if (to.params.floor_id !== _from.params.floor_id && to.params.floor_id === 'all') {
        this.rooms = null;
      }
    },
  },
};

  // <b-dropdown v-if="setDaysOfTheWeek" variant="link" toggle-class="text-decoration-none" no-caret>
  //   <template slot="button-content">
  //     <h6>{{choosenDayOfTheWeek.label}}</h6>
  //   </template>
  //   <b-dropdown-item v-for="dayOfTheWeek in setDaysOfTheWeek" v-bind:key="dayOfTheWeek.label" @click="choosingDayOftheWeek(dayOfTheWeek)">
  //     {{ dayOfTheWeek.label }}
  //   </b-dropdown-item>
  // </b-dropdown>

</script>

<template>
  <b-dropdown right v-if="buildings" variant="link" toggle-class="text-decoration-none" no-caret>
    <template slot="button-content">
      <div class="building-nav">
        <h6 v-if="!activeBuilding">{{ $t('switch.all_buildings') }}</h6>
        <h6 v-if="activeBuilding">{{ activeBuilding.name }}</h6> <span v-if="floors">/</span> 
        <h6 v-if="floors">{{ activeFloor.name }}</h6> <span v-if="rooms">/</span> 
        <h6 v-if="rooms">{{ activeRoom.name }}</h6>
      </div>
    </template>

    <div class="dropdown-table">
      <div>
        {{ $t('switch.buildings') }}:
        
        <div v-if="!this.$attrs['start-one-building']">
          <router-link class="dropdown-link"
            :to="{ name: currentRoute, params: { id: 'all', floor_id: 'all', room_id: 'all' } }">
            {{ $t('switch.all_buildings')  }}
          </router-link>
        </div>

        <div v-for="building in buildings" :key="building.id" :has-link="true">
          <router-link class="dropdown-link"
            :to="{ name: currentRoute, params: { id: building.id, floor_id: 'all', room_id: 'all' } }">
            {{ building.name }}
          </router-link>
        </div>
      </div>

      <div v-if="floors">
        {{ $t('switch.floors') }}:
        <div v-for="floor in floors" :key="floor.id" :has-link="true">
          <router-link class="dropdown-link"
            :to="{ name:  currentRoute, params: { id: activeBuilding.id, floor_id: floor.id, room_id: 'all' } }">
            {{ floor.name }}
          </router-link>
        </div>
      </div>

      <div v-if="rooms">
        {{ $t('switch.rooms') }}:
        <div v-for="room in rooms" :key="room.id" :has-link="true">
          <router-link class="dropdown-link"
            :to="{ name: currentRoute, params: { id: activeBuilding.id, floor_id: activeFloor.id, room_id: room.id } }">
            {{ room.name }}
          </router-link>
        </div>
      </div>
    </div>

  </b-dropdown>
</template>

<style lang="scss" scoped>
</style>

