<template>
  <v-btn
      :class="blink ? 'desk-blinker' : 'desk'"
      :style="cssVars"
      fab
      x-small
      @click="openDialog"
  >
    <div class="desk-labels">
      <span v-if="showDesk && !blink" style="text-shadow: 0 0 2px white,0 0 2px white,0 0 2px white,0 0 2px white">{{deskInfo.name}}</span>
      <span v-if="showPerson && !blink" style="text-shadow: 0 0 2px white,0 0 2px white,0 0 2px white,0 0 2px white">{{userName}}</span>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "IconPin",
  props: {
    deskInfo: {
      required: true,
    },
    showDesk: {
      default: false
    },
    showPerson: {
      default: false
    },
    showRoom: {
      default: false
    },
    pinSize: {
      type: Number,
      default: 7
    },
    blink: {
      type:Boolean,
      default: false
    }
  },
  methods: {
    openDialog(event) {
      this.$emit('iconClick', this.deskInfo)
    },
  },
  computed: {
    cssVars () {
      return {
        position : 'absolute',
        top: `${this.deskInfo.y}px`,
        left: `${this.deskInfo.x}px`,
        zIndex: 10,
        width: `${this.pinSize}px`,
        height: `${this.pinSize}px`,
        backgroundColor: `${this.deskInfo.desk_color_map_pin}`,
      }
    },
    userName () {
      return this.deskInfo.user ? `${this.deskInfo.user.first_name} ${this.deskInfo.user.last_name}` : null;
    }
  },
}
</script>

<style scoped lang="scss">
.desk-blinker {
  animation-name: blinker;
  animation-duration: 1.2s;
  animation-iteration-count:infinite;
}

.v-btn {
  .desk-labels {
    display: flex;
    flex-direction: column;

    ::v-deep span {
      position: relative;
      text-transform: none;
      color: #0c0c0c;
      font-size: 1rem;
      top: 14px;
    }
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }
  100% {
    opacity: .6;
    transform: scale(3.0, 3.0);
  }
}
</style>