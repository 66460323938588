<script>
import bDropdown from './Dropdown';
import bDropdownItem from './DropdownItem';
import BuildingFormChooseHeaderInput from './BuildingFormChooseHeaderInput'

export default {
  name: 'building-form-choose',
  components: {
    'bv-dropdown': bDropdown,
    'bh-header': BuildingFormChooseHeaderInput,
    'bv-dropdown-item': bDropdownItem

  },
  data() {
    return {
      selected_building: null,
      buildings: null,
      floors: null,
      selected_floor: null,
      rooms: null,
      selected_room: null,
      desks: null,
      selected_desk: null,

      search: {
        building: '',
        floor: '',
        room: '',
        desk: ''
      },

      filtered_buildings: [],
      filtered_floors: [],
      filtered_rooms: [],
      filtered_desks: [],
    };
  },
  mounted() {
    this.$http.get('/buildings').then((response) => {
      this.buildings = [].concat(response.data);
    });
  },
  computed: {
    filterBuilding() {
      return this.buildings.filter((el) => el.name.toLowerCase().indexOf(this.search.building.toLowerCase()) != -1)
    },
  
    filterFloor() {
      return this.floors.filter((el) => el.name.toLowerCase().indexOf(this.search.floor.toLowerCase()) != -1)
    },

    filterRoom() {
      return this.rooms.filter((el) => el.name.toLowerCase().indexOf(this.search.room.toLowerCase()) != -1)
    },

    filterDesk() {
      return this.desks.filter((el) => el.name.toLowerCase().indexOf(this.search.desk.toLowerCase()) != -1)
    },
  },
  methods: {
    getFloors(id) {
      this.$http.get(`/buildings/${id}/floors/`).then((response) => {
        this.floors = [].concat(response.data);
      });
    },
    getRooms(id) {
      this.$http.get(`/floors/${id}/rooms/`).then((response) => {
        this.rooms = [].concat(response.data.rooms);
      });
    },

    getDesks(id) {
      this.$http.get(`/buildings/rooms/${id}/desks/`).then((response) => {
        this.desks = [].concat(response.data);
      });
    },

    setBuilding(building) {
      this.selected_building = building
      this.selected_floor = null
      this.selected_room = null
      this.selected_desk = null

      this.floors = null
      this.rooms = null
      this.desks = null

      if (building) {
        this.getFloors(building.id)
      }
      console.log('.... setBuilding')

      this.$attrs.setDesk(null)
      this.$attrs.setRoom(null)
      // this.$refs.dropdown.hide(true)
    },
    
    setFloor(floor) {
      this.selected_floor = floor
      this.selected_room = null
      this.selected_desk = null

      this.rooms = null
      this.desks = null
      this.getRooms(floor.id)

      console.log('.... setFloor')

      this.$attrs.setDesk(null)
      this.$attrs.setRoom(null)
    },
    
    setRoom(room) {
      this.selected_room = room
      this.selected_desk = null

      if (room.type != "MEETING") {
        this.desks = []
        this.getDesks(room.id)
        console.log('.... setRoom 1')

        this.$attrs.setDesk(null)
        this.$attrs.setRoom(null)
      } else {
        this.desks = null
        this.$refs.dropdown.hide()
        console.log('.... setRoom 2')

        this.$attrs.setRoom(room)
        this.$attrs.setDesk(null)
      }
    },
    
    setDesk(desk) {
      this.selected_desk = desk
      this.$refs.dropdown.hide()

      console.log('.... setDesk')

      this.$attrs.setDesk(desk)
      this.$attrs.setRoom(null)
    },

    reset() {
      this.search = {
        building: '',
        floor: '',
        room: '',
        desk: ''
      }
      
      this.selected_building= null;
      this.floors= null;
      this.selected_floor= null;
      this.rooms= null;
      this.selected_room= null;
      this.desks= null;
      this.selected_desk= null;

      // if (this.selected_desk) {
      //   this.filterDesk()
      // } else if (this.selected_room) {
      //   this.filterRoom()
      // } else if (this.selected_floor) {
      //   this.filterFloor()
      // } else if (this.selected_building) {
      //   this.filterBuilding()
      // }

    }

  },

};

  // <b-dropdown v-if="setDaysOfTheWeek" variant="link" toggle-class="text-decoration-none" no-caret>
  //   <template slot="button-content">
  //     <h6>{{choosenDayOfTheWeek.label}}</h6>
  //   </template>
  //   <bv-dropdown-item v-for="dayOfTheWeek in setDaysOfTheWeek" v-bind:key="dayOfTheWeek.label" @click="choosingDayOftheWeek(dayOfTheWeek)">
  //     {{ dayOfTheWeek.label }}
  //   </bv-dropdown-item>
  // </b-dropdown>

</script>

<template>

  <bv-dropdown ref="dropdown" block left variant="link" class="form-control buiding-switch-form" toggle-class="text-decoration-none">
    <template slot="button-content">
      <div class="building-nav">
        <div class="">

          <span class="label label-xs label-primary" v-if="selected_building">{{ selected_building.name }}</span> 
          <span v-if="floors">/</span> 

          <span class="label label-xs label-primary" v-if="selected_floor">{{ selected_floor.name }}</span> 
          <span v-if="rooms">/</span> 
          
          <span class="label label-xs label-primary" v-if="selected_room">{{ selected_room.name }}</span>
          <span v-if="desks">/</span> 
          
          <span class="label label-xs label-primary" v-if="selected_desk">{{ selected_desk.name }}</span>


          <span class="label label-xs label-primary" v-if="!selected_building && buildings">
            Choose building
          </span>
          <span class="label label-xs label-primary" v-if="floors && !selected_floor">
            Choose floor
          </span>
          <span class="label label-xs label-primary" v-if="rooms && !selected_room">
            Choose room
          </span>
          <span class="label label-xs label-primary" v-if="desks && !selected_desk">
            Choose desk
          </span> 
        </div>
      </div>
    </template>
      <div class="dropdown-table2">

        <div v-if="buildings && selected_building">
          <bv-dropdown-item :event="'hover'" class="dropdown-link" @click.prevent="setBuilding(null)" >
            {{ $t('switch.all_buildings') }}
          </bv-dropdown-item>
        </div>

        <div v-if="buildings && selected_building">
          <bv-dropdown-item :event="'hover'" class="dropdown-link"  @click.prevent="setBuilding(selected_building)" >
            {{ selected_building.name }}
          </bv-dropdown-item>
        </div>

        <div v-if="buildings && !selected_building">
          <div v-if="buildings.length">
            <bh-header
              :label="$t('switch.buildings')"
              slug="building"
              :reset="reset"
              :model="search.building"
            />
            
            <div v-for="building in filterBuilding" :key="building.id" :has-link="true">
              <bv-dropdown-item :event="'hover'" class="dropdown-link" @click.prevent="setBuilding(building)" >
                {{ building.name }}
              </bv-dropdown-item>
            </div>
          </div>
          <div v-if="filterBuilding.length == 0">
            {{ $t('switch.no_filtered_buildings') }}
          </div>
          <div v-if="buildings.length == 0">
            {{ $t('switch.no_buildings') }}
          </div>
        </div>

        <div v-if="floors && selected_floor">
          <bv-dropdown-item :event="'hover'" class="dropdown-link" @click.prevent="setFloor(selected_floor)" >
            <span class="divider-level-1"></span> {{ selected_floor.name }}
          </bv-dropdown-item>
        </div>

        <div v-if="floors && !selected_floor">
          <div v-if="floors.length">
            <bh-header
              :label="$t('switch.floors')"
              slug="floor"
              :reset="reset"
              :filter="filterFloor"
              :model="search.floor"
            />
            <div v-for="floor in filterFloor" :key="floor.id" :has-link="true">
              <bv-dropdown-item :event="'hover'" class="dropdown-link" @click.prevent="setFloor(floor)">
                <span class="divider-level-1"></span> {{ floor.name }}
              </bv-dropdown-item>
            </div>
          </div>
          <div v-if="filterFloor.length == 0">
            {{ $t('switch.no_filtered_floors') }}
          </div>
          <div v-if="floors.length == 0">
            {{ $t('switch.no_floors') }}
          </div>
        </div>

        <div v-if="rooms && selected_room">
          <bv-dropdown-item :event="'hover'" class="dropdown-link" @click.prevent="setRoom(selected_room)" >
            <span class="divider-level-2"></span> {{ selected_room.name }}
          </bv-dropdown-item>
        </div>

        <div v-if="rooms && !selected_room">
          <div v-if="rooms.length">
            <bh-header
              :label="$t('switch.rooms')"
              slug="room"
              :reset="reset"
              :filter="filterRoom"
              :model="search.room"
            />
            <div v-for="room in filterRoom" :key="room.id" :has-link="true">
              <bv-dropdown-item class="dropdown-link" @click.prevent="setRoom(room)">
                <span class="divider-level-2"></span> {{ room.name }}
              </bv-dropdown-item>
            </div>
          </div>
          <div v-if="filterRoom.length == 0">
            {{ $t('switch.no_filtered_rooms') }}
          </div>
          <div v-if="rooms.length == 0">
            {{ $t('switch.no_rooms') }}
          </div>
        </div>

        <div v-if="desks && selected_desk">
          <bv-dropdown-item :event="'none'" class="dropdown-link" >
            <span class="divider-level-3"></span> {{ selected_desk.name }}
          </bv-dropdown-item>
        </div>

        <div v-if="desks && !selected_desk">
          <div v-if="desks.length">
            <bh-header
              :label="$t('switch.desks')"
              slug="desk"
              :reset="reset"
              :filter="filterDesk"
              :model="search.desk"
            />
            <div v-for="desk in filterDesk" :key="desk.id" :has-link="true">
              <bv-dropdown-item class="dropdown-link" @click.prevent="setDesk(desk)">
                <span class="divider-level-3"></span> {{ desk.name }}
              </bv-dropdown-item>
            </div>
          </div>

          <div v-if="filterDesk.length == 0">
            {{ $t('switch.no_filtered_desks') }}
          </div>
          <div v-if="desks.length == 0">
            {{ $t('switch.no_desks') }}
          </div>
        </div>

      </div>

  </bv-dropdown>
</template>

<style lang="scss" scoped>
</style>

