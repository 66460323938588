<template>
  <v-card
      flat
      height="100%"
      tile
  >
    <NavigationBar :is-home-page="false"/>
    <v-treeview
        :active.sync="active"
        :items="items"
        :open.sync="open"
        :load-children="fetchChildren"
        item-key="id"
        color="primary"
        open-on-click
        open-all
        transition
    >
      <template slot="label" slot-scope="{ item }">
        <div class="tree-leaf" @click="openDialog(item)">
        <span style="margin-right: 10px; font-size: 1rem">{{item.name}}</span>
        <div v-if="item.children && !!item.type ">
          <v-chip
              small chip
              color="red"
              text-color="#fff"
          >{{getOccupiedDeskNumber(item)}}</v-chip>
          <v-chip
              style="margin-left: 10px"
              small chip
              color="green"
              text-color="#fff"
          >
            {{getAvailableDeskNumber(item)}}
          </v-chip>
          <v-btn
              style="margin-left: 10px"
              v-if="isRoomType(item.type)"
              icon
              @click.stop="goToFloorMap(item)"
              color="#555"
          >
            <v-icon>mdi-map</v-icon>
          </v-btn>
        </div>
        <div v-if="!item.children">
          <v-chip
              color="primary"
              text-color="white"
              small
          >
            {{item.desk_label}}
          </v-chip>
          <v-chip v-if="!item.reserved && !item.is_closed"
                  style="margin-left: 10px"
                  color="green"
                  text-color="white"
                  small
          >
            {{ $t('FREE') }}
          </v-chip>
          <v-chip v-if="item.reserved || item.is_closed"
                  style="margin-left: 10px"
                  color="orange"
                  text-color="white"
                  small
          >
            {{ item.reserved ? $t('BUSY') : $t('CLOSED') }}
          </v-chip>
        </div>
        </div>
      </template>
    </v-treeview>
    <DeskDetails :is-open="dialog" :desk="desksInfo" @onClose="onDialogClose"/>
  </v-card>

</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";
import DeskDetails from "./DeskDetails";
import moment from "moment";
import {BuildingTypeEnum} from "../../variables";

export default {
  name: "FindDesk",
  components: {
    NavigationBar,
    DeskDetails
  },
  mounted() {
    if (this.$store.state.findDesk.init) {
      this.desksInfo = this.$store.state.app.desk;
      this.items = this.$store.state.findDesk.items;
      this.open = this.$store.state.findDesk.open;
      this.active = this.$store.state.findDesk.active;
    }
    this.dialog = this.$store.state.findDesk.isDeskDisplay;
  },
  data: () => ({
    items: [
        {
          id: -1,
          name: i18n.t('Building'),
          children: []
        }
    ],
    getFromStore: false,
    buildingsStatus: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    },
    buildings: [],
    dialog: false,
    desksInfo: {},
    active: [],
    open: [],

  }),
  watch: {
    open(newValue) {
      this.$store.commit('findDesk/set_tree', {items: this.items, open: newValue, active: this.active});
    }
  },
  methods: {
    async fetchChildren(item) {
      switch ( item.type ) {
        case BuildingTypeEnum.BUILDING:
          await this.getFloors(item);
          break;
        case BuildingTypeEnum.FLOOR:
          await this.getRooms(item);
          break;
        case BuildingTypeEnum.ROOM:
          await this.getDesks(item);
          break;
        case BuildingTypeEnum.DESK:
          break;
        default:
          await this.getBuildings(item);
      }
      this.$store.commit('findDesk/set_tree', {items: this.items, open: this.open, active: this.active});
    },
    async getBuildings(item) {
      this.buildingsStatus = {
        ...this.buildingsStatus,
        loading: true,
        succeed: false
      };
      try {
        const response = await this.$http.get('/buildings');
        const buildings = [].concat(response.data)
                .map(building => (
                    ({name, id, reserved_count, desks}) =>
                        (
                            {name, id, children: [], reserved_count, desks, navigate: false, type: BuildingTypeEnum.BUILDING, parent: [] }))(building));
        item.children.push(...buildings);
        this.buildingsStatus.succeed = true;
      } catch (e) {
        this.buildingsStatus = {
          ...this.buildingsStatus,
          error: true,
          errorInfo: e.response.data
        };
      } finally {
        this.buildingsStatus.loading = false;
      }
    },
    async getFloors(item) {
      try {
        const response = await this.$http.get(`/buildings/${item.id}/floors/`);
        const floors = [].concat(response.data)
            .map(floor => (
                ({name, id, reserved_count, desks}) =>
                    ({name, id, reserved_count, desks, children: [], navigate: false, type: BuildingTypeEnum.FLOOR, parent: [...item.parent, {id: item.id, name: item.name}]}))(floor));
        item.children.push(...floors);
      } catch (e) {
      } finally {}
    },
    async getRooms(item) {
      try {
        const response = await this.$http.get(`/floors/${item.id}/rooms/`);
        const rooms = [].concat(response.data.rooms)
            .map(room => (
                ({ name, id, reservations, checkins, desk_count }) =>
                    ({ name, id, reservations, checkins, desk_count, children: [], navigate: false, type: BuildingTypeEnum.ROOM, parent: [...item.parent, {id: item.id, name: item.name}] }))(room));
        item.children.push(...rooms);
      } catch (e) {
      } finally {}
    },
    async getDesks(item) {
      try {
      const response = await this.$http.get(`/buildings/rooms/${item.id}/desks/`);
      const desks = [].concat(response.data)
            .map(desk =>
                (({ name, id, desk_label, reserved, desk_color_map_pin, user, is_closed }) =>
                    ({ name, id, desk_label, reserved, desk_color_map_pin, user, is_closed, type: BuildingTypeEnum.DESK, parent: [...item.parent, {id: item.id, name: item.name}]}))(desk));
      item.children.push(...desks);
      } catch (e) {

      } finally {
      }
    },
    onDialogClose() {
      this.dialog = false;
      this.$store.commit('findDesk/set_desk_display', this.dialog);
    },
    openDialog(item) {
      this.$store.commit('findDesk/set_tree', {items: this.items, open: this.open, active: this.active});
      if (item.children) return;
      this.$store.commit('app/set_building', item.parent[0]);
      this.$store.commit('app/set_floor', item.parent[1]);
      this.$store.commit('app/set_room', item.parent[2]);
      this.$store.commit('app/set_desk', item);
      this.desksInfo = item;
      this.dialog = true;
      this.$store.commit('findDesk/set_desk_display', this.dialog);

    },
    getOccupiedDeskNumber(item) {
      return item.type === BuildingTypeEnum.ROOM ?
          item.reservations + item.checkins || '0' : item.reserved_count || '0';
    },
    getAvailableDeskNumber(item) {
      return item.type === BuildingTypeEnum.ROOM ? item.desk_count : item.desks;
    },
    isRoomType(type) {
      return type === BuildingTypeEnum.ROOM;
    },
    goToFloorMap(item) {
      this.$store.commit('date/set_date', moment().startOf('day').format('YYYY-MM-DD'));
      this.$store.commit('app/set_building', item.parent[0]);
      this.$store.commit('app/set_floor', item.parent[1]);
      this.$store.commit('app/set_room', item);
      this.$store.commit('findDesk/set_tree', {items: this.items, open: this.open, active: this.active});
      this.$store.commit('findDesk/set_searched_desk', null);
      this.$router.push({ name: 'floor_layout' });
    }
  },
}
</script>

<style scoped lang="scss">
.tree-leaf {
  display: flex;
  justify-content: start;
  align-items: center;
}
</style>