<template>
  <v-card
      flat
      height="100%"
      tile
  >
    <NavigationBar :is-home-page="false"/>
    <v-alert
        v-model="booking.isError"
        type="warning"
        dense
        border="left"
        dismissible
    >
      {{booking.errorInfo}}
    </v-alert>
    <div class="navigation-buttons">
      <v-btn
          outlined
          color="primary"
          @click="goToReservations">
        {{$t('My reservations')}}
      </v-btn>
    </div>
    <v-subheader class="time-slot-subheader">
      <v-card class="time-slot-desk-name" color="primary">{{$t("Booking") +': '+ deskInfo.name}}</v-card>
    </v-subheader>
    <DateSlider @dateChange="onDateChange"/>
    <span class="no-time-slot" v-if="getTimeTables.succeed && !timeSlots.length">{{$t('Sorry, there are currently no available timeslots to book')}}</span>
    <DeskTimeSlots
        v-if="getTimeTables.succeed"
        @timeSlots="onTimeSlotSelected"
        :time-slots="timeSlots"
        :disabledAll="booking.isLoading"/>
    <v-progress-circular
        v-if="getTimeTables.loading"
        class="time-tables-progress"
        indeterminate
        color="primary"
    ></v-progress-circular>
    <div class="time-slot-footer">
      <v-btn
          color="primary"
          class="time-slot-booking"
          height="50"
          :loading="booking.isLoading"
          :disabled="booking.isLoading || !selectedTimeSlots.length"
          @click="setDeskReservation"
      >{{$t('Book now ({number})', { number: selectedTimeSlots.length })}}</v-btn>
    </div>
  </v-card>
</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";
import DateSlider from "../DateSlider/DateSlider";
import DeskTimeSlots from "./DeskTimeSlots";
import moment from "moment";

export default {
  name: "BookingTimeSlots",
  components: {
    NavigationBar,
    DateSlider,
    DeskTimeSlots
  },
  data: () => ({
    selected: [],
    deskInfo: {
      name: '',
      timetables: []
    },
    selectedDate: moment().startOf('day').format('YYYY-MM-DD'),
    selectedTimeSlots: [],
    getTimeTables: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    },
    booking: {
      isLoading: false,
      isError: false,
      errorInfo: null,
    },
    bookingError: false,
  }),
  async mounted() {
    await this.getDeskReservation(this.$route.params.id);
  },
  watch: {

  },
  methods: {
    async getDeskReservation(deskId) {
      this.getTimeTables = {
        ...this.getTimeTables,
        loading: true,
        succeed: false
      }
      try {
        const response = await this.$http.get(`booking/desk/${deskId}/timetables/`);
        this.deskInfo = response.data;
        this.getTimeTables.succeed = true;
      } catch (e) {
        this.getTimeTables = {
          ...this.getTimeTables,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.getTimeTables.loading = false;
      }
    },
    getBookingData() {
      return this.selectedTimeSlots.map(slotNr => ({
        date: this.selectedDate,
        timetable_id: slotNr,
        desk_id: this.$route.params.id
      }))
    },
    async setDeskReservation() {
      this.bookingError = false;
      this.bookingLoading = true;
      this.booking = {
        ...this.booking,
        isLoading: true,
        isError: false
      }
      const data = this.getBookingData();
      try {
        await this.$http.post('booking/new/', data);
        await this.getDeskReservation(this.$route.params.id);
      } catch (e) {
        this.bookingError = true;
        this.booking = {
          ...this.booking,
          errorInfo: e.response.data.code === 'ERROR_CONFLICT_BOOKING' ?
              'Conflict regarding chosen booking' :
              i18n.t('Sorry, something went wrong, please try again later'),
          isError: true
        }
      } finally {
        this.booking.isLoading = false;
        this.selectedTimeSlots = [];
        //await this.getDeskReservation(this.$route.params.id);
        if(!this.bookingError) this.goToReservations();
      }
    },
    onDateChange (newDate) {
      this.selectedTimeSlots = [];
      this.selectedDate = newDate;
      // TODO change it to have always the latest values!
      this.getDeskReservation(this.$route.params.id);
    },
    onTimeSlotSelected (newSelectedSlots) {
      this.selectedTimeSlots = newSelectedSlots;
    },
    goToReservations() {
      this.$router.push({ name: 'reservations' })
    },
  },
  computed: {
    timeSlots() {
      return this.deskInfo.timetables[this.selectedDate];
    }
  }
}
</script>

<style scoped lang="scss">
.navigation-buttons {
  margin: 10px 16px;
  .v-btn {
    width: 100%;
    text-transform: none;
  }
}

.time-slot-subheader {
  margin: 10px 0;

  .time-slot-desk-name {
    padding: 10px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    margin: auto;
  }
}

.no-time-slot {
  padding-top: 10vh;
  font: 1.2rem bold;
  display: block;
  text-align: center;
}

.time-tables-progress {
  left: 50%;
  top: 20%;
}

.time-slot-footer {
  position: fixed;
  margin: 0;
  top: 92%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .time-slot-booking {
    font-size: 1.2rem;
    text-transform: none;
    width: 90vw;
  }
}

</style>