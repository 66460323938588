<template>
  <v-footer color="#f5f5f5">
    <v-btn color="#CDDC39" class="social-button" height="50">{{ $t('Social Intranet') }}</v-btn>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.social-button{
  width: 40%;
  max-width: 500px;
  margin: 0 auto;
  text-transform: none;
  word-wrap: break-word;

    ::v-deep span {
      font-size : 1.2rem;
    }
}
</style>