<template>
  <v-card
      :disabled="cancelReservation.loading"
      @click="goToBooking">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div style="width: 100%">
        <v-card-title class="text-h9">{{`${$t('Desk')}: ${reservation.desk.name}`}}</v-card-title>
        <v-card-subtitle>{{reservation.time}}</v-card-subtitle>
        <v-card-actions style="background-color: #DCF8C6; border-top: 1px solid #ddd; margin: 0 10px 10px 10px" class="d-flex justify-space-between">
          <ReservationCheckInButton @onCheck="onCheck" :reservation="reservation"/>
          <ReservationConfirmButton @onConfirm="onConfirmReservation" :confirmation="reservation.open_confirmation"/>
          <ReservationCancelButton @onCancel="onCancel" @canceling="duringCanceling" :reservation-id="reservation.id"/>
        </v-card-actions>
      </div>
    </div>
    <v-progress-circular
        v-if="cancelReservation.loading"
        class="reservation-progress"
        indeterminate
        color="primary"
    ></v-progress-circular>
  </v-card>
</template>

<script>
import ReservationConfirmButton from "./ReservationConfirmButton";
import ReservationCancelButton from "./ReservationCancelButton";
import ReservationCheckInButton from "./ReservationCheckInButton";

export default {
  name: "ReservationDetails",
  components: {
    ReservationConfirmButton,
    ReservationCancelButton,
    ReservationCheckInButton
  },
  props: {
    reservation: {
    }
  },
  data: () => ({
    cancelReservation: {
      loading: false
    }
  }),
  methods: {
    goToBooking() {
      this.$store.commit('date/set_date', this.reservation.date);
      this.$router.push({ path: `booking_time_slots/${this.reservation.desk.id}` })
    },
    duringCanceling(event) {
      this.cancelReservation.loading = event;
    },
    async onCheck(event) {
      this.$emit('onAction', event)
    },
    async onCancel(event) {
      this.$emit('onAction', event)
    },
    async onConfirmReservation(event) {
      this.$emit('onAction', event)
    }
  }
}
</script>

<style scoped>
.reservation-progress {
  position: absolute;
  top: 30%;
  left: 50%;
}
</style>