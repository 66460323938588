import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'
import VueRx from 'vue-rx'
//import VCalendar from 'v-calendar';


import lodash from 'lodash';
import moment from 'moment';

import axios from 'axios';
import VueAxios from 'vue-axios';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import NProgress from 'vue-nprogress'
import ECharts from 'vue-echarts'

// VUE loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import HTTPDriver from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import RouterDriver from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';

import 'babel-polyfill';
import VueTimepicker from 'vue2-timepicker';


import VueGoogleChart from 'vue-charts';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n'
import vuetify from './plugins/vuetify';

const DRFDriver = {
  request(req, token) {
    if (req.disableAuthorization) {
      return
    }

    // eslint-disable-next-line
    this.drivers.http.setHeaders.call(
      this, req,
      { Authorization: `JWT ${token}` },
    );
  },
  response(res) {
    const token = (res.data && res.data.token) || null;
    return token;
  },
};

const nprogress = new NProgress()

const base_url = () => {
  if (process.env.VUE_APP_USE_ORIGIN) 
    return window.location.origin

  if (process.env.VUE_APP_BASE_API_URL) {
    return process.env.VUE_APP_BASE_API_URL
  }

  return 'https://flexapp-candid.approxx.nl'
}

// const opts = { ... }

Vue.use(Vuetify);
Vue.use(VueAxios, axios);
Vue.use(VueTimepicker);
Vue.use(BootstrapVue)
Vue.use(NProgress)
Vue.use(Loading)
Vue.use(VueGoogleChart);
Vue.use(VueRx)
//Vue.use(VCalendar)

Vue.router = router;

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: DRFDriver, 
    http: HTTPDriver,
    router: RouterDriver,
  },
  options: {
    loginData: {
      url: 'users/login/',
      method: 'POST',
      fetchUser: true,
    },
    fetchData: {
      url: 'users/self',
      method: 'GET',
      enabled: false,
    },
    refreshData: {
      url: 'users/self',
      method: 'GET',
      enabled: false
    },
    logoutData: {
      url: 'users/logout',
      method: 'POST'
    }, 
    rolesVar: 'roles',
  }
});

// Don't send cookies
Vue.axios.defaults.baseURL = `${base_url()}/api`;
Vue.axios.defaults.timeout = 20000;

store.dispatch('auth/fetch')

Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

Vue.config.productionTip = false;

new Vue({
  vuetify,
  nprogress,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
