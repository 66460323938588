<style>

</style>

<template>
  <div>
    <v-header></v-header>
    <router-view></router-view>
    <footer></footer>
  </div>
</template>

<script>
  import Header from './Header'
  import '../assets/lib/fontawesome-free/css/all.min.css'
  import '../assets/lib/ionicons/css/ionicons.min.css'
  import '../assets/lib/typicons.font/typicons.css'
  import '../assets/lib/flag-icon-css/css/flag-icon.min.css'

  export default {
    name: 'app-main',
    components: {
      'v-header': Header
    }
  }
</script>