import Vue from 'vue';
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    user: {},
    init: false,
    logged: false
  },
  mutations: {
    auth_success(state, user) {
      state.user = user
      state.logged = true
      i18n.locale = user.profile.language
      state.init = true
    },
    auth_error(state) {
      // @TODO
      state.user = {}
      state.logged = false
      state.init = true
    },
    auth_update_user(state, user) {
      state.user = user
      i18n.locale = user.profile.language
    },
    auth_logout(state) {
      state.user = {}
      state.logged = false
    }
    
  },
  actions: {
    fetch({ commit }) {
      const token = Vue.auth.token()

      if (!token) {
        commit('auth_error', {})
        return
      }

      return Vue.auth.fetch()
        .then((data) => {
          commit('auth_success', data.data)
        })
        .catch((e) => {
          commit('auth_error', {})
        })
    },
    refresh(data) {
      return Vue.auth.refresh(data);
    },
    login({commit}, user) {
      return new Promise((resolve, reject) => {
      })
    },
    logout({commit}) {
      Vue.auth.logout({redirect: null})
      commit('auth_logout')
    }

  }
}