<template>
    <v-slide-group
        v-model="selectedItem"
        @change="onDateSelectionChange"
        class="pa-4"
        active-class="active-date"
        mandatory
        show-arrows
    >
      <v-slide-item
          v-for="(date, index) in availableDates"
          :key="index"
          v-slot="{ active, toggle }"
      >
        <v-card
            :color="active ? undefined : 'grey lighten-1'"
            class="ma-4"
            height="100"
            width="100"
            @click="toggle"
        >
            <div class="date-title">
              <span>{{date.day}}</span>
              <span class="month">{{ date.month }}</span>
            </div>
        </v-card>
      </v-slide-item>
    </v-slide-group>
</template>

<script>
import moment from "moment";
import utilsMethods from "../utils";

export default {
  name: "DateSlider",
  data: () => ({
    selectedItem: 0,
    days: 10,
    dates: []
  }),
  async mounted() {
    if (!this.$store.state.configuration.init) {
      await this.getSettings();
      await this.getUserSettings();
    }
    this.days = this.$store.state.configuration.settings.booking.bookable_days;
    if (this.$store.state.date.init) {
      const storeDate = this.dates.findIndex(date => date.fullDate === this.$store.state.date.date)
      // TODO change that!
      this.selectedItem = storeDate >= 0 ? storeDate : 0;
    }
    this.$emit('dateChange', this.dates[this.selectedItem].fullDate);

  },
  methods: {
    ...utilsMethods,
    getNextDates(days) {
      moment.locale(this.$i18n.locale);
      const dates = [];
      for (let i = 0; i < days; i += 1) {
        const today = moment().startOf('day');
        dates.push({
          fullDate: today.add(i, 'days').format('YYYY-MM-DD'),
          day: today.format('D'),
          month: today.format('MMM')
        });
      }
      this.dates = dates

      return dates;
    },
    onDateSelectionChange (event) {
      this.$store.commit('date/set_date', this.dates[event].fullDate);
      this.$emit('dateChange', this.dates[event].fullDate);
    },
  },
  computed: {
    selected() {
      return this.selectedItem;
    },
    availableDates() {
      return this.getNextDates(this.days);
    }
  }
}
</script>

<style scoped lang="scss">

.active-date {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  .date-title span {
    color: white;
  }
}

.date-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  span {
    font-size: 1.2rem;
  }

  .month {
    font-weight: bold;
  }
}
</style>