<script>
import { BarChart, AreaChart, DonutChart } from '@/components/Charts';
import BuildingSwitcher from '@/components/Dashboard/components/BuildingSwitcher.vue';
import Statistics from '@/components/Dashboard/components/Statistics.vue';
import Grid from '@/components/Dashboard/components/Grid.vue';
import UserCard from '@/components/Dashboard/components/UserCard.vue';
import DateChart from '@/components/Charts/components/DateChart.vue';
import { MonthPickerInput } from 'vue-month-picker'
import HeaderSelect from '@/components/HeaderSelect/HeaderSelect'
import '@lazy-copilot/datetimepicker/dist/datetimepicker.css'
import { DateTimePicker, DateTimePickerModal } from "@lazy-copilot/datetimepicker";

import VueTimepicker from 'vue2-timepicker';
import { BDropdown, BModal, FormDatepickerPlugin } from 'bootstrap-vue'

const d = new Date();
const currentMonth =  d.getFullYear() + "-" + (d.getMonth()+1);

export default {
  name: 'dashboard',
  components: {
    Statistics,
    Grid,
    UserCard,
    BarChart,
    AreaChart,
    DonutChart,
    BuildingSwitcher,
    DateChart,
    VueTimepicker,
    DateTimePicker,
    DateTimePickerModal,
    MonthPickerInput,
    'b-dropdown': BDropdown,
    'b-modal': BModal,
    FormDatepickerPlugin,
    'v-header-select': HeaderSelect
  },
  provide() {
    return {
      filter: this.filter
    }
  },

  data() {
    return {

      filter: {
        dateFrom: this.$moment().subtract(1, 'month').startOf('day').toDate(),
        dateTo: this.$moment().endOf('day').toDate(),
        timeFrom: '00:00',
        timeTo: '23:59',
        showDaysOfTheWeek: true,
        choosenDayOfTheWeek: { field: 100, label: 'Alle' },
        onChangeDate: () => {
          this.showStatsByDate()
        }
      },

      isLoading: false,
      activeUsers: null,
      workspaceDurations: null,
      meetingDurations: null,
      checkInSummaries: null,
      workspaceLatestCheckIns: null,
      meetingLatestCheckIns: null,
      userCounts: null,
      workspaceUsage: null,
      meetingUsage: null,
      workspaceUsageAsc: null,
      meetingUsageAsc: null,
      deskUsage: null,
      deskUsageAsc: null,
      buildings: null,
      questionnaires: null,
      monthQuestionnaire: currentMonth,
      modalMonthQuestionnaire: false,
      stats: [],
      statsDays: [],
      statsValues: [],
      dashboardChartType: 'bar',

      reactive: false,

      modal: false,
      secondModal: false,

      menu: false,
      secondMenu: false,
      styleObject: {
        color: 'red',
        fontSize: '13px',
      },
      counter: 0,

      table_columns: {
        stats: [
          { label: this.$t('dashboard.table.columns.room'), field: 'room' },
          { label: this.$t('dashboard.table.columns.floor'), field: 'floor' },
          { label: this.$t('dashboard.table.columns.building'), field: 'building' },
          { label: this.$t('dashboard.table.columns.this_month'), field: 'this_month' }
        ],
        stats1: [
          { label: this.$t('dashboard.table.columns.name'), field: 'name' },
          { label: this.$t('dashboard.table.columns.added'), field: 'added' },
          { label: this.$t('dashboard.table.columns.last_booked_by'), field: 'last_booked_by' },
        ],
        stats2: [
          { field: 'desk', label: this.$t('dashboard.table.columns.desk') },
          { field: 'room', label: this.$t('dashboard.table.columns.room') },
          { field: 'floor', label: this.$t('dashboard.table.columns.floor') },
          { field: 'building', label: this.$t('dashboard.table.columns.building') },
          { field: 'this_month', label: this.$t('dashboard.table.columns.this_month') }
        ]
      }
    };
  },
  computed: {
    userCounters() {
      let userCounters = [];

      if (this.userCounts) {
        userCounters = [
          { label: this.$t('dashboard.table.columns.identified_employees'), value: this.userCounts.checked_in - this.userCounts.anonymous },
          { label: this.$t('dashboard.table.columns.anonymous_employees'), value: this.userCounts.anonymous },
          { label: this.$t('dashboard.table.columns.bhv_employees'), value: this.userCounts.bhv_users },
        ];
      }

      return userCounters;
    },
    occupationCounters() {
      let freeCount = 0;
      let occupactionCount = 0;
      let outOfDeskCount = 0;

      if (this.buildings) {
        if (this.$route.params.id === 'all') {
          this.buildings.forEach((building) => {
            freeCount += (building.desks - building.reserved_count);
            occupactionCount += building.reserved_count;
            outOfDeskCount += building.out_of_desk;
          });
        } else {
          const building = this.buildings.find(b => b.id === parseInt(this.$route.params.id, 10));
          freeCount = (building.desks - building.reserved_count);
          occupactionCount = building.reserved_count;
          outOfDeskCount = building.out_of_desk;
        }
      }


      return [
        { label: this.$t('dashboard.table.columns.free_workplaces'), value: freeCount },
        { label: this.$t('dashboard.table.columns.out_of_desks'), value: outOfDeskCount },
        { label: this.$t('dashboard.table.columns.occupied_workplaces'), value: occupactionCount },
      ];
    },
    statistics() {
      return this.occupationCounters.concat(this.userCounters);
    },
    averageCheckInDurations() {
      const months = this.$moment.localeData().monthsShort()
        .map(month => month.toUpperCase());

      const columns = [
        { type: 'string', label: this.$t('dashboard.table.columns.month') },
        { type: 'number', label: this.$t('dashboard.table.columns.workplaces') },
        { type: 'string', role: 'tooltip', p: { html: true } },
        { type: 'number', label: this.$t('dashboard.table.columns.meeting_rooms') },
        { type: 'string', role: 'tooltip', p: { html: true } },
      ];

      if (!this.workspaceDurations || !this.meetingDurations) {
        return null;
      }

      const durations = months.map(() => ({ workspace: 0, meeting: 0 }));
      this.workspaceDurations.checkin_durations.forEach((item) => {
        durations[item.month - 1].workspace = +item.average;
      });

      this.meetingDurations.checkin_durations.forEach((item) => {
        durations[item.month - 1].meeting = +item.average;
      });

      const rows = [];

      const currentMonthIndex = this.$moment().month();
      for (let i = currentMonthIndex + 1; i < currentMonthIndex + 13; i += 1) {
        const monthIndex = i % 12;
        rows.push([
          months[monthIndex],
          durations[monthIndex].workspace,
          `
          <div class="google-visualization-tooltip-label">
            <b>${months[monthIndex]}</b>
            <br/>
          </div>
          <div class="google-visualization-tooltip-data">
            <span>${this.$t('dashboard.table.columns.average')}:</span> 
            <b>${Math.floor(durations[monthIndex].workspace / 3600)}h ${Math.ceil(durations[monthIndex].workspace % 60)}m</b>
          </div>
          `,
          durations[monthIndex].meeting,
          `
          <div class="google-visualization-tooltip-label">
            <b>${months[monthIndex]}</b>
          </div>
          <div class="google-visualization-tooltip-data">
            <span>${this.$t('dashboard.table.columns.average')}:</span> 
            <b>${Math.floor(durations[monthIndex].meeting / 3600)}h ${Math.ceil(durations[monthIndex].meeting % 60)}m</b>
          </div>
          `,
        ]);
      }
      return { rows, columns };
    },
    checkInsPerMonth() {
      const months = this.$moment.localeData().monthsShort()
        .map(month => String.prototype.toUpperCase.call(month));
      const columns = [
        { type: 'string', label: this.$t('dashboard.table.columns.month') },
        { type: 'number', label: this.$t('dashboard.table.columns.staff_members') },
      ];
      let rows = [];

      if (!this.checkInSummaries) {
        return null;
      }
      rows = this.$lodash.map(
        this.checkInSummaries.checkin_counts,
        summary => [months[summary.month - 1], summary.count],
      );

      return { rows, columns };
    },
    checkInDeskSummaries() {
      const columns = this.table_columns.stats1;
      let rows = [];

      if (this.workspaceLatestCheckIns) {
        rows = this.workspaceLatestCheckIns.checkins
          .filter(checkin => checkin.user !== null)
          .map(checkin => ({
            name: (checkin.desk || checkin.room || { name: 'misconfigured' }).name,
            added: this.$moment(checkin.created).fromNow(),
            last_booked_by: checkin.user,
          }));
      }
      return { rows, columns };
    },
    userStatusCounts() {
      const columns = [
        { type: 'string', label: this.$t('dashboard.table.columns.type') },
        { type: 'number', label: this.$t('dashboard.table.columns.count') },
      ];
      let rows = [];

      if (this.userCounts) {
        rows = this.userCounts.by_status.map(count => [
          count.status || this.$t('dashboard.table.columns.unknown'),
          count.count,
        ]);
      }

      return { rows, columns };
    },
    checkInRoomSummaries() {
      const columns = this.table_columns.stats1;
      let rows = [];

      if (this.meetingLatestCheckIns) {
        rows = this.meetingLatestCheckIns.checkins
          .filter(checkin => checkin.user !== null)
          .map(checkin => ({
            name: (checkin.desk || checkin.room || { name: 'misconfigured' }).name,
            added: this.$moment(checkin.created).fromNow(),
            last_booked_by: checkin.user,
          }));
      }

    return { rows, columns };
    },
    mostUsedRooms() {
      const columns = this.table_columns.stats;
      let rows = [];

      if (this.workspaceUsage) {
        rows = this.workspaceUsage.usage.map((usage) => {
          const hours = usage.last_month_usage / 3600;
          const minutes = (hours % 1) * 60;

          return {
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
    mostUsedMeetingRooms() {
      const columns = this.table_columns.stats;

      let rows = [];

      if (this.meetingUsage) {
        rows = this.meetingUsage.usage.map((usage) => {
          const hours = usage.last_month_usage / 60 / 60;
          const minutes = (hours % 1) * 60;

          return {
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
    leastUsedRooms() {
      const columns = this.table_columns.stats;

      let rows = [];

      if (this.workspaceUsageAsc) {
        rows = this.workspaceUsageAsc.usage.map((usage) => {
          const hours = usage.last_month_usage / 3600;
          const minutes = (hours % 1) * 60;

          return {
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
    leastUsedMeetingRooms() {
      const columns = this.table_columns.stats;

      let rows = [];

      if (this.meetingUsageAsc) {
        rows = this.meetingUsageAsc.usage.map((usage) => {
          const hours = usage.last_month_usage / 60 / 60;
          const minutes = (hours % 1) * 60;

          return {
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
    mostUsedDesks() {
      const columns = this.table_columns.stats2;
      let rows = [];

      if (this.deskUsage) {
        rows = this.deskUsage.usage.map((usage) => {
          const hours = usage.last_month_usage / 3600;
          const minutes = (hours % 1) * 60;

          return {
            desk: usage.desk_name,
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
    leastUsedDesks() {
      const columns = this.table_columns.stats2;
      let rows = [];

      if (this.deskUsageAsc) {
        rows = this.deskUsageAsc.usage.map((usage) => {
          const hours = usage.last_month_usage / 60 / 60;
          const minutes = (hours % 1) * 60;

          return {
            desk: usage.desk_name,
            room: usage.room_name,
            floor: usage.floor_name,
            building: usage.building_name,
            this_month: `${Math.floor(hours)}h ${Math.ceil(minutes)}m`,
          };
        });
      }
      return { rows, columns };
    },
  },
  methods: {
    getDaysFromStats(stats) {
      return stats.map(x => (
        x.day
      ));
    },
    getValuesFromStats(stats) {
      return stats.map(x => (
        x.count
      ));
    },
    setStatsChart(stats) {
      this.$data.stats = this.statsDayOfThWeekFiltering(this.filter.choosenDayOfTheWeek.field, stats);
      this.setChartData(this.stats);
    },
    setChartData(data) {
      this.$data.statsDays = this.getDaysFromStats(data);
      this.$data.statsValues = this.getValuesFromStats(data);
    },

    statsDayOfThWeekFiltering(chosenDayNumber, stats) {
      if (chosenDayNumber === 100) {
        return stats;
      }
      return stats.filter(x => chosenDayNumber === this.getDayOfTheWeekNumber(x));
    },
    getDayOfTheWeekNumber(currentStat) {
      const currentStatDate = this.parseDate(currentStat.day);
      const currentStatParsedDate = new Date(currentStatDate);
      return currentStatParsedDate.getDay();
    },
    parseDate(dateStr) {
      const pattern = /(\d{2})\/(\d{2})\/(\d{2})/;
      return dateStr.replace(pattern, '$2-$1-20$3');
    },
    showStatsByDate() {
      this.statsDays.length = 0;
      this.statsValues.length = 0;
      return this.$http.post('/checkins/stats/?sort=asc', {
        start_date: this.$moment(this.filter.dateFrom).format('YYYY-MM-DD'),
        start_time: this.filter.timeFrom,
        end_date: this.$moment(this.filter.dateTo).format('YYYY-MM-DD'),
        end_time: this.filter.timeTo,
        room: this.$route.params.room_id,
        building: this.$route.params.id,
        floor: this.$route.params.floor_id
      }).then((response) => {
        this.setStatsChart(response.data.total);
      })
    },
    setTimeFrom(e) {
      this.$data.timeFrom = e.data.HH + ":" + e.data.mm;
    },
    setTimeTo(e) {
      this.$data.timeTo = e.data.HH + ":" + e.data.mm;
    },
    getQuestionnaire() {
        const q = this.monthQuestionnaire
        
        this.$http.get('reports/questionnaires', { params: { month: `${q.year}-${q.monthIndex}` } })
          .then((response) => {
            this.questionnaires = response.data
          })
    },
    getData() {
      const params = {};

      if (this.$route.params.id && Number.isInteger(this.$route.params.id)) {
        params.building_id = this.$route.params.id;
      }
      this.isLoading = true;
      Promise.all([
        this.$http.get('reports/checkin_durations', { params: { ...params, checkin_type: 'WORKSPACE' } }),
        this.$http.get('reports/checkin_durations', { params: { ...params, checkin_type: 'MEETING' } }),
        this.$http.get('reports/checkin_summaries', { params }),
        this.$http.get('reports/active_users', { params }),
        this.$http.get('reports/latest_checkins', { params: { ...params, checkin_type: 'WORKSPACE' } }),
        this.$http.get('reports/latest_checkins', { params: { ...params, checkin_type: 'MEETING' } }),
        this.$http.get('reports/user_counts', { params }),
        this.$http.get('reports/room_usage', { params: { ...params, checkin_type: 'WORKSPACE' } }),
        this.$http.get('reports/room_usage', { params: { ...params, checkin_type: 'MEETING' } }),
        this.$http.get('reports/room_usage', { params: { ...params, checkin_type: 'WORKSPACE', sort: 'asc' } }),
        this.$http.get('reports/room_usage', { params: { ...params, checkin_type: 'MEETING', sort: 'asc' } }),
        this.$http.get('reports/desk_usage', { params }),
        this.$http.get('reports/desk_usage', { params: { ...params, sort: 'asc' } }),
        this.$http.get('buildings'),
        this.showStatsByDate(),
        this.$http.get('reports/questionnaires', { params: { ...params, month: this.monthQuestionnaire } })
      ]).then((response) => {
        this.setData(response);
        
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setData(response) {
      this.workspaceDurations = response[0].data;
      this.meetingDurations = response[1].data;
      this.checkInSummaries = response[2].data;
      this.activeUsers = response[3].data.results;
      this.workspaceLatestCheckIns = response[4].data;
      this.meetingLatestCheckIns = response[5].data;
      this.userCounts = response[6].data;
      this.workspaceUsage = response[7].data;
      this.meetingUsage = response[8].data;
      this.workspaceUsageAsc = response[9].data;
      this.meetingUsageAsc = response[10].data;
      this.deskUsage = response[11].data;
      this.deskUsageAsc = response[12].data;
      this.buildings = response[13].data;
      this.questionnaires = response[15].data;
    },
  },
  created() {
    this.getData();
  },
  watch: {
    $route: 'getData',
    showStatsByDate: 'showStatsByDate',
    monthQuestionnaire: 'getQuestionnaire'

  },
};

</script>

<template>
  <b-overlay :show.sync="isLoading" rounded="sm">
    <div class="az-content az-content-dashboard">

      <div class="container" id="dashboard">
      
        <div class="az-content-body">
          <div class="az-dashboard-one-title">
            <div>
              <h2 class="az-dashboard-title">{{ $t('dashboard.welcome') }}</h2>
              <p class="az-dashboard-text">{{ $t('dashboard.welcome_desc') }}</p>
            </div>

            <v-header-select :only-select-building="false" :start-one-building="true" />      
          </div>
        

          <b-container>
            <b-row>
              <b-col class="mg-b-20">
                <statistics :data="statistics" />
              </b-col>
              <b-col class="mg-b-20" cols="9">
                <div class="card card-dashboard-one">

                  <div class="card-header">
                    <div>
                      <h6 class="card-title">{{ $t('dashboard.occupation_table.title') }}</h6>
                      <p class="card-text">{{ $t('dashboard.occupation_table.desc') }}</p>
                    </div>
                    <div class="btn-group">
                      <b-button class="btn" v-bind:class="{ active: dashboardChartType == 'bar'}" @click="dashboardChartType='bar'">Bar</b-button>
                      <b-button class="btn" v-bind:class="{ active: dashboardChartType == 'line'}" @click="dashboardChartType='line'">Line</b-button>
                    </div>

                  </div>
                  <div class="card-body">
                    <div v-bind:style="{marginBottom: '1.7rem'}">
                      <date-chart :height="75" :data="statsValues" :labels="statsDays" :chartType="dashboardChartType"/>
                    </div>
                  </div>

                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.average_check_in_time') }}:</h6>
                  <p class="mg-b-20"></p>
                  <div class="card-body">
                    <bar-chart
                      v-if="averageCheckInDurations"
                      class="bar-chart"
                      :rows="averageCheckInDurations.rows"
                      :columns="averageCheckInDurations.columns"
                    />
                  </div>
                </div>
              </b-col>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.checkins_per_month') }}:</h6>
                  <p class="mg-b-20"></p>
                  <div class="card-body">
                    <area-chart
                      v-if="checkInsPerMonth"
                      class="area-chart"
                      :rows="checkInsPerMonth.rows"
                      :columns="checkInsPerMonth.columns"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.availability_overview') }}:</h6>
                  <p class="mg-b-20"></p>
                  <donut-chart
                    v-if="userStatusCounts"
                    class="donut-chart"
                    :columns="userStatusCounts.columns"
                    :rows="userStatusCounts.rows"
                  />
                </div>
              </b-col>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.recently_used_meeting_room') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="checkInRoomSummaries.columns"
                    :items="checkInRoomSummaries.rows"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.most_used_rooms') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="mostUsedRooms.columns"
                    :items="mostUsedRooms.rows"
                  />
                </div>
              </b-col>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.most_used_meeting_rooms') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="mostUsedMeetingRooms.columns"
                    :items="mostUsedMeetingRooms.rows"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.least_used_rooms') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="leastUsedRooms.columns"
                    :items="leastUsedRooms.rows"
                  />
                </div>
              </b-col>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.least_used_meeting_rooms') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="leastUsedMeetingRooms.columns"
                    :items="leastUsedMeetingRooms.rows"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.most_used_workplaces') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="mostUsedDesks.columns"
                    :items="mostUsedDesks.rows"
                  />
                </div>
              </b-col>

              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('dashboard.least_used_workplaces') }}:</h6>
                  <p class="mg-b-20"></p>
                  <b-table
                    :columns="leastUsedDesks.columns"
                    :items="leastUsedDesks.rows"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="mg-b-20">
                <div class="card card-table-one">
                  <h6 class="card-title">{{ $t('questionnaires.title') }}:</h6>
                  <p class="mg-b-20"></p>

                  <b-row row wrap v-bind:style="{marginBottom: '40px'}">
                    <b-col xs12 sm12 md12>
                      <month-picker-input 
                        v-model="monthQuestionnaire" 
                        :no-default="true">
                      </month-picker-input>
                    </b-col>
                  </b-row>

                  <div 
                    v-bind:style="{border: '1px solid black', padding: '20px', textAlign: 'left'}"
                    v-for="questionnaire in questionnaires" :key="questionnaire.id">
                    <h3 v-bind:style="{ fontWeight: 'bold'}">{{ questionnaire.name }}</h3>

                    <div v-for="question in questionnaire.questions" :key="question.id">
                      <h4 v-bind:style="{ whiteSpace: 'pre-wrap' }">{{ question.title.replace('\\n', "\n") }}</h4>
                      <ul v-bind:style="{ paddingTop: '20px', borderTop: '1px solid #bebebe', marginTop: '20px', paddingLeft: 0, display: 'flex' }">
                        <li 
                          v-bind:style="{ border: '1px solid #444', display: 'inline-block', flex: '1', padding: '10px' }"
                          v-for="answer in question.answers" :key="answer.id">
                          <b>{{ answer.answer }} ({{ answer.count }})</b>
                          <div v-if="answer.is_custom == 'True'" >
                            <ul v-bind:style="{ paddingLeft: '20px', listStyleType: 'decimal' }">
                              <li v-for="(info, index) in answer.additional_info" :key="`info${index}`">
                                {{ info }}
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-bind:style="{border: '1px solid black', padding: '50px', textAlign: 'center'}" v-if="!questionnaires || !questionnaires.length">
                    <h3>{{ $t('questionnaires.no_data') }}</h3>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<style lang="scss" scoped>
.chart {
  max-width: 50%;
  min-width: 50%;
}

section {
  padding-top: 2em;
}

.dashboard {
  padding-top: 4em;
  padding-bottom: 4em;
}
.user-card {
  height: 100%;
  width: 6em;
}

h2 {
  font-weight: bold;
  font-size: 130%;
  text-align: left;
  padding-bottom: 1em;
}

</style>
