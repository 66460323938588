<template>
  <v-combobox
      v-model="select"
      :items="items"
      style="width: 80vw; margin: auto"
      @change="onSelectionChange"
      :label="$t('Show names:')"
      clearable
      multiple
      chips
  >
    <template v-slot:selection="data">
      <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
      >
        <v-avatar
            class="accent white--text"
            left
            v-text="data.item.slice(0, 1).toUpperCase()"
        ></v-avatar>
        <strong>{{ data.item }}</strong>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "FloorMapDisplayOptions",
  data () {
    return {
      select: [],
      items: [
        i18n.t('Room'),
        i18n.t('Desk'),
        i18n.t('Person')
      ],
    }
  },
  methods: {
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
    onSelectionChange (event) {
      this.$emit('changed', this.select)
    }
  },
}
</script>

<style scoped>

</style>