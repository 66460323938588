<template>
  <v-responsive  :aspect-ratio="2" class="center">
    <div class="center" style="flex-direction: column">
      <v-icon x-large>mdi-eye-off</v-icon>
      <span>{{$t('Sorry, there is no floor plan')}}</span>
    </div>
  </v-responsive>
</template>

<script>
export default {
  name: "MapNotAvailable"
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>