<template>
  <v-container>
    <v-row>
      <v-card
          v-if="reservationsDates.length && !userReservation.loading && userReservation.succeed"
          width="100%"
          style="margin: 10px 0"
          v-for="resDate in reservationsDates"
          :key="resDate">
        <v-card-title class="text-h5" style="background-color: #ddd">
          <v-badge
              color="#555"
              :content="reservations[resDate].length">
            {{changeDateFormat(resDate)}}
          </v-badge>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-for="(res, i) in reservations[resDate]"
                     :key="i"
                     xl="4"
                     lg="6"
              >
                <ReservationDetails @onAction="onReservationAction" :reservation="res"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <span
          v-if="!reservationsDates.length && !userReservation.loading && userReservation.succeed"
          class="text-h5"
          style="margin: auto">
        {{$t('No reservations')}}
      </span>
      <v-progress-circular
          v-if="userReservation.loading"
          class="reservation-list-progress"
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import ReservationDetails from "./ReservationDetails";
import {ActionButtonsEnum, API_VERSION} from "../../variables";
import moment from "moment";
import utilsMethods from "../utils";

export default {
  name: "ReservationsList",
  components: {
    ReservationDetails
  },
  data: () => ({
    reservations: {},
    reservationsDates: [],
    userReservation: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    },
    error: ''
  }),
  async created() {
    await this.getSettings();
    await this.getUserSettings();
    await this.getMyReservation();
  },
  methods: {
    ...utilsMethods,
    async getMyReservation() {
      this.userReservation = {
        ...this.userReservation,
        loading: true,
        succeed: false
      };
      try {
        const response = await this.$http.get(
            'booking/reservations/me/',
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.reservations = response.data.list;
        this.reservationsDates = Object.keys(response.data.list);
        this.userReservation.succeed = true;
      } catch (e) {
        this.userReservation = {
          ...this.userReservation,
          error: true,
          errorInfo: e.response.data
        };
      } finally {
        this.userReservation.loading = false;
      }
    },
    async onReservationAction(response) {
      if (response.succeed || response.actionFrom === ActionButtonsEnum.CANCEL && response.errorInfo.code === 'NO_EXISTS')  {
        await this.getSettings();
        await this.getUserSettings();
        await this.getMyReservation();
      }
      this.$emit('onReservationAction', response)
    },
    changeDateFormat(date) {
      return moment(date).format('DD-MM-YYYY');
    }
  }
}
</script>

<style scoped>
.reservation-list-progress {
  margin: auto;
}
</style>