import { BDropdown } from 'bootstrap-vue/src/components/dropdown/dropdown.js';
import { attemptFocus, closest, contains, isVisible, requestAF, selectAll } from 'bootstrap-vue/src//utils/dom'

export default {
  name: 'bDropdownTest',
  mixins: [BDropdown],
  methods: {
    rootCloseListener(vm) {
      debugger
      // prevent close when dropdown item is clicked
      if (vm instanceof MouseEvent) {
        return;
      }
      // close when other dropdown on page is opened
      bDropdown.mixins[1].methods.rootCloseListener.call(this, vm);
    },
    hideHandler(evt) {
      const { target } = evt
      if (this.visible && target.className != "dropdown-item" && !contains(this.$refs.menu, target) && !contains(this.toggler, target)) {
        this.hide()
      }
    }
  }
};