<template>
  <div class="buttons-container">
    <DiamondButtons :buttons="this.buttonsToDisplay"/>
  </div>
</template>

<script>
import DiamondButtons from "../DiamondButtons/DiamondButtons";
export default {
  name: "CheckInOptions",
  components: {
    DiamondButtons
  },
  data: () => ({
    buttonsToDisplay:  {
      topButtons: [
        {disabled: false, hide: false, title: "", color: "#f5f5f5", icon: 'mdi-information-variant'},
        {disabled: false, hide: false, title: i18n.t("workplace"), color: "#01579B"},
      ],
      bottomButtons: [
        {disabled: false, hide: false, title: i18n.t("conference\nroom"), color: "#f5f5f5"},
        {disabled: false, hide: false, title: i18n.t('homework'), color: "#01579B"}
      ],
    },
  }),
}
</script>

<style scoped>
.buttons-container {
  position: relative;
  height: 70vh;
  min-height: 700px;
}

@media only screen and (max-width: 650px){
  .buttons-container {
    height: 100vw;
    min-height: unset;
  }
}

@media only screen and (max-height: 600px){
  .buttons-container {
    height: 60vw;
    min-height: unset;
  }
}

@media only screen and (max-width: 400px) and (max-height: 600px) {
  .buttons-container {
    height: 100vw;
    min-height: unset;
  }
}
</style>