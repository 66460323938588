<template>
  <v-toolbar dense class="navigation-bar">
    <v-btn v-if="!this.isHomePage && !!!logoSrc" @click="goHome" icon>
      <v-icon>home</v-icon>
    </v-btn>
    <v-btn v-if="!this.isHomePage" @click="goBack" icon>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-img contain height="24" v-if="logoSrc" :src="logoSrc" @click="goHome"></v-img>
    <v-spacer></v-spacer>
    <MenuOptions/>
  </v-toolbar>
</template>

<script>
import MenuOptions from "../MenuOptions/MenuOptions";
import Vue from 'vue'
import VueRouter from 'vue-router'
import utilsMethods from "../utils";

Vue.use(VueRouter)


export default {
  name: "NavigationBar",
  components: {
    MenuOptions
  },
  props: {
    isHomePage: Boolean,
    default: true,
  },
  async mounted() {
    if (!this.$store.state.configuration.init) {
      await this.getSettings();
    }
  },
  methods: {
    ...utilsMethods,
    goBack() {
      this.$router.back();
    },
    goHome() {
      this.$router.push({name: "home_page"});
    },

  },
  computed: {
    logoSrc: function() {
      if(this.$store.state.configuration.settings.theme)
        return this.$store.state.configuration.settings.theme.logo
      else return ""
    },
  }
}
</script>

<style scoped>

</style>