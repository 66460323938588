import Vue from 'vue';
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    building: {},
    floor: {},
    room: {},
    desk: {},
    init: false,
  },
  mutations: {
    set_building(state, building) {
      state.building = building
      state.init = true
    },
    set_floor(state, floor) {
      state.floor = floor
      state.init = true
    },
    set_room(state, room) {
      state.room = room
      state.init = true
    },
    set_desk(state, desk) {
      state.desk = desk
      state.init = true
    }
  },
  actions: {
  }
}