import Vue from 'vue';
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    items: {},
    open: {},
    active: {},
    isDeskDisplay: false,
    searchedDesk: {},
    init: false,
  },
  mutations: {
    set_tree(state, building) {
      state.items = building.items
      state.open = building.open
      state.active = building.active
      state.init = true
    },
    set_searched_desk(state, desk) {
      state.searchedDesk = desk;
    },
    set_desk_display(state, display) {
      state.isDeskDisplay = display
    },
  },
  actions: {
  }
}