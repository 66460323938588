<template>
  <div class="az-signin-wrapper" >
    <div style="padding: 50px;">
      <h2>
        {{$t('error_no_found.header')}}
      </h2>

      {{$t('error_no_found.description')}}
    </div>
  </div>
</template>

<script>

import router from '@/router'

export default {
  name: 'error_no_found',
};
</script>