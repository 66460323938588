export const API_VERSION = 'v5';

export const CheckInEnum = {
    WORKSPACE: 'WORKSPACE',
    MEETING: 'MEETING',
    WORKSPACE_OR_MEETING: 'WORKSPACE|MEETING',
    HOMEWORK: 'HOMEWORK'
};

export const ActionButtonsEnum  = {
    CHECK_IN: 'CHECK_IN',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
}

export const BuildingTypeEnum  = {
    BUILDING: 'BUILDING',
    FLOOR: 'FLOOR',
    ROOM: 'ROOM',
    DESK: 'DESK',
}

export const FilterTypeEnum = {
    WORKING_HOME :'user.working_home',
    WORKING_BUILDING: 'user.working_building',
    BHV: 'user.bhv',
    NO_WORKING: 'user.no_working'

}