<script>
export default {
  methods: {
    collapedSidebar: function () {
      document.body.classList.toggle("az-header-menu-show");
    }
  }
}
</script>
<template>
  <div class="az-header">
    <div class="container">

      <div class="az-header-left">
        <router-link class="az-logo"
          :to="{ name: 'my_dashboard', params: { id: 'all', floor_id: 'all', room_id: 'all' } }">
          <span></span> flexapp
        </router-link>
        <a href="#" id="azMenuShow" class="az-header-menu-icon d-lg-none" @click="collapedSidebar()"><span></span></a>
      </div><!-- az-header-left -->

      <div class="az-header-menu">
        <div class="az-header-menu-header">
          <a href="index.html" class="az-logo"><span></span> flexapp</a>
          <a href="#" class="close" @click="collapedSidebar()">&times;</a>
        </div><!-- az-header-menu-header -->
        <ul class="nav">
          <li class="nav-item" v-bind:class="{ active: $route.name == 'my_dashboard'}">
            <router-link class="nav-link"
              :to="{ name: 'my_dashboard', params: { id: 'all', floor_id: 'all', room_id: 'all' } }">
              <i class="typcn typcn-chart-area-outline"></i> {{ $t('menu.dashboard') }}
            </router-link>
          </li>
          <li class="nav-item" v-bind:class="{ active: $route.name == 'checkins'}">
            <router-link class="nav-link"
              :to="{ name: 'checkins' }">
              <i class="typcn typcn-chart-pie-outline "></i> {{ $t('menu.checkins') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="$store.state.configuration.settings.theme.menu_enable_booking" v-bind:class="{ active: $route.name == 'bookings' || $route.name == 'bookings_date'}">
            <router-link class="nav-link"
              :to="{ name: 'bookings', params: { id: 'all', floor_id: 'all', room_id: 'all' } }">
              <i class="typcn typcn-phone-outline "></i> {{ $t('menu.bookings') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="$store.state.configuration.settings.theme.enable_register_guest" v-bind:class="{ active: $route.name == 'register_guest'}">
            <router-link class="nav-link"
              :to="{ name: 'register_guest' }">
              <i class="typcn typcn-user-outline "></i> {{ $t('menu.register_guest') }}
            </router-link>
          </li>
        </ul>
      </div><!-- az-header-menu -->
      <div class="az-header-right">

        <b-dropdown class="dropdown az-header-notification">
          <template slot="button-content">
            <a href="" class="new"><i class="typcn typcn-bell"></i></a>
          </template>

          <div class="az-dropdown-header mg-b-20 d-sm-none">
            <a href="" class="az-header-arrow"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <h6 class="az-notification-title">Notifications</h6>
          <p class="az-notification-text">You have 2 unread notification</p>
          <div class="az-notification-list">
            <div class="media new">
              <div class="media-body">
                <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries</p>
                <span>Mar 15 12:32pm</span>
              </div><!-- media-body -->
            </div><!-- media -->
            <div class="media new">
              <div class="media-body">
                <p><strong>Joyce Chua</strong> just created a new blog post</p>
                <span>Mar 13 04:16am</span>
              </div><!-- media-body -->
            </div><!-- media -->
            <div class="media">
              <div class="media-body">
                <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                <span>Mar 13 02:56am</span>
              </div><!-- media-body -->
            </div><!-- media -->
            <div class="media">
              <div class="media-body">
                <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                <span>Mar 12 10:40pm</span>
              </div><!-- media-body -->
            </div><!-- media -->
          </div><!-- az-notification-list -->
          <div class="dropdown-footer"><a href="">View All Notifications</a></div>
        </b-dropdown><!-- az-header-notification -->

        <b-dropdown class="dropdown az-profile-menu az-img-user">
          <template slot="button-content">
            <img src="../img/face1.jpg" alt="">
          </template>

          <div class="az-dropdown-header d-sm-none">
            <a href="" class="az-header-arrow"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <div class="az-header-profile">
            <div class="az-img-user">
              <img src="../img/face1.jpg" alt="">
            </div><!-- az-img-user -->
            <h6>{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</h6>
            <span>{{ $t('user_statuses.admin') }}</span>
          </div><!-- az-header-profile -->

          <b-dropdown-item href="#" disabled><i class="typcn typcn-user-outline"></i> {{ $t('menu.my_profile') }}</b-dropdown-item>
          
          <b-dropdown-item>
            <router-link class="nav-link"
              :to="{ name: 'profile_edit' }">
              <i class="typcn typcn-edit"></i> {{ $t('menu.edit_profile') }}
            </router-link>            
          </b-dropdown-item>

          <b-dropdown-item href="#" disabled><i class="typcn typcn-time"></i> {{ $t('menu.activity_logs') }}</b-dropdown-item>
          <b-dropdown-item href="#" disabled><i class="typcn typcn-cog-outline"></i> {{ $t('menu.account_settings') }}</b-dropdown-item>
          <b-dropdown-item>
            <router-link class="nav-link"
              :to="{ name: 'logout' }">
              <i class="typcn typcn-power-outline"></i> {{ $t('menu.sign_out') }}
            </router-link>
          </b-dropdown-item>

        </b-dropdown>

      </div><!-- az-header-right -->
    </div><!-- container -->
  </div><!-- az-header -->
</template>