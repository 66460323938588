<template>
  <v-btn
      v-if="confirmation"
      text
      style="background-color: #fff"
      color="success"
      :disabled="confirmationIsWaiting"
      @click.stop="onConfirm"
  >
    <v-icon left>
      mdi-calendar-check
    </v-icon>
    {{!confirmationIsWaiting ? $t('Confirm') : $t('Confirmed')}}
  </v-btn>
</template>

<script>

import {ActionButtonsEnum, API_VERSION} from "../../variables";

export default {
  name: "ReservationConfirmButton",
  props: {
    confirmation: {
    },
  },
  data: () => ({
    confirmReservation: {
      actionFrom: ActionButtonsEnum.CONFIRM,
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  methods: {
    async confirm() {
      this.confirmReservation = {
        ...this.confirmReservation,
        loading: true,
        succeed: false
      }
      try {
        await this.$http.post(
            `booking/reminders/${this.confirmation.id}/update/`,
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.confirmReservation.succeed = true;
      } catch (e) {
        this.confirmReservation = {
          ...this.confirmReservation,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.confirmReservation.loading = false;
      }
    },
    async onConfirm() {
      await this.confirm();
      this.$emit('onConfirm', this.confirmReservation)
    },
  },
  computed: {
    confirmationIsWaiting() {
      return this.confirmation.status === 'waiting';
    }
  }
}
</script>

<style scoped>

</style>