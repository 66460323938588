<template>
  <div class="buttons-container">
    <DiamondButtons :buttons="this.buttonsToDisplay"/>
<!--    TODO Investigate if below button is useless!-->
<!--    <router-link :to="{name: 'checkin'}">-->
<!--      <v-btn class="check-button" color="#01579B" height="80">{{ $t('check in -\ncheck out') }}</v-btn>-->
<!--    </router-link>-->
  </div>
</template>

<script>
import DiamondButtons from "../DiamondButtons/DiamondButtons";

export default {
  name: "SelectionOptions",
  components: {
    DiamondButtons
  },
  data: () => ({
    buttonsToDisplay:  {
      topButtons: [
        {disabled: true, hide: true, title: "", color: "#f5f5f5"},
        {disabled: false, hide: false, title: i18n.t('find\ncolleague'), color: "#01579B", navigateToRoute: {name: 'colleagues_list'}},
      ],
      bottomButtons: [
        {disabled: false, hide: false, title: i18n.t('find\ndesk'), color: "#CDDC39", navigateToRoute: {name: 'find_desk'}},
        // {disabled: false, hide: false, title: i18n.t('booking'), color: "#CDDC39", navigateToRoute: {name: 'floor_layout', params: {building: 'Randwycksingel 22', floor: 'Verdieping 01 - C1', floorId: 9}}}
        {disabled: false, hide: false, title: i18n.t('check in -\ncheck out'), color: "#CDDC39", navigateToRoute: {name: 'reservations'}}
      ],
    },
  }),
}
</script>

<style scoped>
.buttons-container {
  position: relative;
  height: 70vh;
  min-height: 700px;
}

.check-button {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 250px;
  top: 90%;
  white-space: normal;
  word-wrap: break-word;
  font-weight: bold;
  text-transform: none;
}

.check-button ::v-deep span {
  font-size : 1.2rem;
}

@media only screen and (max-width: 650px){
  .buttons-container {
    height: 100vw;
    min-height: unset;
  }

  .check-button {
    max-width: unset !important;
    min-width: 110px !important;
    width: 40%;
    height: 15vw !important;
    min-height: 50px;
  }

}

@media only screen and (max-height: 600px){
  .buttons-container {
    height: 60vw;
    min-height: unset;
  }

  .check-button {
    max-width: unset !important;
    max-height: 90px;
    width: 25%;
    height: 10vw !important;
  }
}

@media only screen and (max-width: 400px) and (max-height: 600px) {
  .buttons-container {
    height: 100vw;
    min-height: unset;
  }

}
</style>