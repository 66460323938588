<script>
import { BREAKPOINTS } from '@/constants';

export default {
  name: 'grid',
  props: {
    columns: { type: Number, default: 4 },
    columnsXs: { type: Number },
    columnsSm: { type: Number },
    columnsMd: { type: Number },
    columnsLg: { type: Number },
  },
  methods: {
    reRender() {
      this.$forceUpdate();
    },
    getCurrentColumns() {
      let { columns } = this;

      if (this.columnsXs && window.innerWidth < BREAKPOINTS.xs) {
        columns = this.columnsXs;
      } else if (this.columnsLg && window.innerWidth >= BREAKPOINTS.md) {
        columns = this.columnsLg;
      } else if (this.columnsMd && window.innerWidth >= BREAKPOINTS.sm) {
        columns = this.columnsMd;
      } else if (this.columnsSm && window.innerWidth >= BREAKPOINTS.xs) {
        columns = this.columnsSm;
      }

      return columns;
    },
    createItem(node) {
      return (
        <div class="column is-narrow">
          {node}
        </div>
      );
    },
    createRow(children) {
      return (
        <div class="columns">
          {children.map(this.createItem)}
        </div>
      );
    },
    createContent(slots = []) {
      const rows = [];
      const columns = this.getCurrentColumns();

      for (let i = 0; i < slots.length; i += (columns)) {
        rows.push(this.createRow(slots.slice(i, (i + columns))));
      }

      return (
        <div>
          {rows}
        </div>
      );
    },
  },
  mounted() {
    window.addEventListener('resize', this.reRender);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reRender);
  },
  render() {
    return (
      <div class="grid">
        {this.createContent(this.$slots.default)}
      </div>
    );
  },
};

</script>
