<template>
  <v-menu
      bottom
      right
      transition="slide-x-reverse-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon
          v-bind="attrs"
          v-on="on"
      >
      </v-app-bar-nav-icon>
    </template>

    <v-list>
      <v-list-item
          v-for="(item, i) in items"
          :key="i"
      >
        <v-btn text class="option-button" :to="item.to">
          <v-icon
              left
              dark
          >
            {{ item.icon }}
          </v-icon>
          {{ $t(item.title) }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "MenuOptions",
  data: () => ({
    items: [
      { title: 'Messages', icon: 'mdi-email-outline', to: "" },
      { title: 'Notifier', icon: 'mdi-bell-outline', to: "" },
      { title: 'Settings', icon: 'mdi-settings-outline', to: "" },
      { title: 'Logout', icon: 'mdi-logout-variant', to: {name: "logout"} },
    ],
  }),
}
</script>

<style scoped>
.option-button {
  text-transform: none;
}

</style>