<script lang="ts">
import html2pdf from 'html2pdf.js'

import _ from 'lodash';
import * as echarts from "echarts/core";
import { first } from 'rxjs/operators'

import Select2 from '@/components/Select2';
import HeaderSelect from '@/components/HeaderSelect/HeaderSelect'

import { combineLatest, from  } from 'rxjs'
import { BModal } from 'bootstrap-vue'
import '@lazy-copilot/datetimepicker/dist/datetimepicker.css'
import { DateTimePicker, DateTimePickerModal } from "@lazy-copilot/datetimepicker";
import VueHtml2pdf from 'vue-html2pdf'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import { saveAs } from 'file-saver';
import { parse } from 'json2csv';
import { enumerateDaysBetweenDates, enumerateMonthsBetweenDates } from '@/utils/date'

import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, ScatterChart, HeatmapChart, LineChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  CalendarComponent,
  VisualMapComponent,
  MarkLineComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  MarkLineComponent,
  HeatmapChart,
  ScatterChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  CalendarComponent,
  VisualMapComponent
])

export default {
  components: {    
    VChart,
    Select2,
    BModal,
    DateTimePicker,
    DateTimePickerModal,
    VueHtml2pdf,
    Calendar,
    'v-header-select': HeaderSelect
  },
  provide() {
    return {
      // [THEME_KEY]:  'dark',
      filter: this.filter
    }
  },

  data() {
    const self = this;

    const chartOptions = {
      grid: {
          show: true,
          top: 30,
          bottom: 70,
          left: 80,
          right: 80,
          backgroundColor: '#fff',
          borderWidth: 0
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["bar"]
      },

      dataZoom: [{
          type: 'slider',
          start: 0,
          end: 100
      }, {
          type: 'inside',
          start: 0,
          end: 100
      }],

      xAxis: {
        data: []
      },
      yAxis: {
          axisTick: {show: false},
          splitLine: {show: true},
          axisLine: {show: true},
          axisLabel: {show: true},
          min: 0,

      },
      series: []
    }

    return {
      isLoading: false,
  
      buildings: [],
      buildings$: null,
      bookings: [],
      bookings_pdf: [],
      cached_params: {},
      summary: {
        most_person: null,
        most_desk: null,
        count_unique_person: 0,
        count_unique_desk: 0
      },

      pagination: {
        currentPage: 1,
        rows: 1,
        perPage: 100,
        results: 0
      },
      pdf : {
        processing: false,
        processing_page: 1,
        processing_pages: 1
      },
      calendar: {
        masks: {
          weekdays: 'WWW',
        }, 
        attributes: []
      },
      calendarOptions: {

        calendar: [{
          top: 30,
          left: 50,
          right: 20,
          bottom: 20,
          range: '2021-04',
          cellSize: [24, 24],
          dayLabel: {
            firstDay: 1,
            nameMap: 'en'
          },
          yearLabel: {show: false},
          monthLabel: {
            show: true
          }
        }],

        animation: false,
        visualMap: {},
        series: []
      },
      chatUpdateOptions: {
        noMerge: true,
        replaceMerge: ['series']
      },
      chartOptions,
      chartLineOptions: {
        ...chartOptions,
        legend: {
          data: ["line"]
        },
      },

      filter: {
        modeView: 'line',
        dateFrom: this.$moment().toDate(),
        dateTo: this.$moment(this.$store.state.configuration.settings.booking.bookable_date).add(3, 'days').toDate(),
        timeFrom: '00:00',
        timeTo: '23:59',
        showDaysOfTheWeek: false,
        onChangeDate: () => {
          self.updateRouter()
        },
        building: null,
        building_info: null
      },
    }
  },
  computed: {
    table_fields: (self) => {
      return [
        { key: 'id', sortable: true },
        { 
          key: 'date_time', 
          sortable: true, 
          formatter: (value, key, item) => {
            if (value) {
              const dates = value.split(/ (.+)/)
              return dates
            }
            return '-'
          }
        },
        { key: 'user_name', sortable: true },
        { key: 'location', sortable: true },
        { 
          key: 'created', 
          sortable: true, 
          formatter: (value, key, item) => {
            if (value) {
              if (self.disable_pretty_date) {
                return self.$moment(value).format('LLL')
              }
              return self.$moment(value).fromNow()
            }
            return '-'
          } 
        }
      ]
    }
  },

  watch: {
    $route: 'getBookingsWatch'
  },

  methods: {
    getBuildings() {
      return this.$http.get('/buildings/')
    },

    parseParams() {
      const building_id = this.$route.params.id === 'all' ? 0 : this.$route.params.id
      const floor_id = this.$route.params.floor_id === 'all' ? 0 : this.$route.params.floor_id
      const room_id = this.$route.params.room_id === 'all' ? 0 : this.$route.params.room_id
      return {building_id, floor_id, room_id}
    },

    getBookingsWatch() {
      this.getBookings()
    },

    getBookingsReports(start_date, end_date) {
      const {building_id, floor_id, room_id} = this.parseParams()
      return this.$http.get(`/reports/bookings/${building_id}.${floor_id}.${room_id}.0`, {
        params: {
          start_date,
          end_date,
        }
      })
    },

    getBookingsSummary(start_date, end_date) {
      const {building_id, floor_id, room_id} = this.parseParams()
      return this.$http.get(`/reports/bookings/${building_id}.${floor_id}.${room_id}.0/summary`, {
        params: {
          start_date,
          end_date,
        }  
      })
    },

    getBookingsAjax(start_date, end_date, per_page) {

      const {building_id, floor_id, room_id} = this.parseParams()

      this.$data.cached_params = {
        start_date,
        end_date,
        desk: null,
        room: room_id,
        floor: floor_id,
        building: building_id,
        page: this.pagination.currentPage,
        limit: per_page
      }

      return this.$http.get('/booking/list/all/', {
        params: this.$data.cached_params
      })
    },

    // loadStats() {
    //   combineLatest([
    //     this.buildings$,
    //     this.getBookingsAjax()
    //   ]).subscribe(([buildings, bookings]) => {
    //     this.chartOptions.series = booki
    //   })      
    // }, 
    
    changedRouteParamsPage(dateFrom, dateTo, perPage) {
      if (!this.$data.cached_params) return false

      const {building_id, floor_id, room_id} = this.parseParams()

      const params = {
        start_date: dateFrom,
        end_date: dateTo,
        desk: null,
        room: room_id,
        floor: floor_id,
        building: building_id,
        page: this.pagination.currentPage,
        perPage
      }

      const keys = _.reduce(this.$data.cached_params, function(result, value, key) {
          return _.isEqual(value, params[key]) ?
              result : result.concat(key);
      }, []);

      return keys.length == 1 && keys[0] == 'page'
    },

    parseBookings(bookings) {
      return bookings.map((el) => ({
        date_time: `${this.$moment(el.date_start).format('YYYY-MM-DD HH:mm')} - ${this.$moment(el.date_end).format('HH:mm')}`,
        ...el,
      }))
    },

    getBookings(perPage=10, loadReports=true, loadSummary=true, onlyBookings=false) {
      this.isLoading = true;
      const self = this;

      if (this.$route.name === 'bookings_date') {
        this.$data.filter.dateFrom = this.$moment(this.$route.params.date_start).toDate()
        this.$data.filter.dateTo = this.$moment(this.$route.params.date_end ).toDate()
        this.$data.pagination.currentPage = this.$route.params.page ? parseInt(this.$route.params.page) : 1
      }

      this.$data.filter.building = this.$route.params.id

      const dateFrom = this.$moment(this.$data.filter.dateFrom).format('YYYY-MM-DD')
      const dateTo = this.$moment(this.$data.filter.dateTo).format('YYYY-MM-DD')

      const onlyChangedPage = this.changedRouteParamsPage(dateFrom, dateTo, perPage)
     
      if (onlyChangedPage) {
        loadReports = false;
        loadSummary = false;
      }

      if (!this.bookingsReports$ || loadReports) {
        this.bookingsReports$ = this.getBookingsReports(dateFrom, dateTo)
      }

      if (!this.bookingsSummary$ || loadSummary) {
        this.bookingsSummary$ = this.getBookingsSummary(dateFrom, dateTo)
      }

      if (onlyBookings) {
        return this.getBookingsAjax(dateFrom, dateTo, perPage)
      }

      return combineLatest([
        this.buildings$,
        this.bookingsReports$,
        this.bookingsSummary$ ,
        this.getBookingsAjax(dateFrom, dateTo, perPage),
      ]).subscribe(([buildings, reports, summary, bookings]) => {

        this.summary.results = bookings.data.count
        this.summary.most_person = _.maxBy(summary.data.users, 'total_desks')
        this.summary.most_desk = _.maxBy(summary.data.desks, 'total_users')
        this.summary.count_unique_person = summary.data.unique_users
        this.summary.count_unique_desk = summary.data.unique_desks

        this.pagination.results = bookings.data.count
        this.pagination.rows = Math.floor(bookings.data.count / bookings.data.page_size)
        this.pagination.perPage = bookings.data.page_size
        
        this.bookings = this.parseBookings(bookings.data.results)

        this.buildings = buildings.data.map((el) => ({
          id: el.id,
          text: el.name 
        }))

        const selected_buildings = buildings.data
          .filter(el => this.filter.building === 'all' || this.filter.building === el.id)

        const grouping = this.bookings
          .reduce((groups, booking) => {
            const date = booking.date;
            if (!groups[date]) {
              groups[date] = [];
            }

            groups[date].push(booking)
            return groups;
          }, {});

        // this.calendar.attributes = Object.keys(grouping)
        //   .map((date, index) => {
        //     return {
        //       key: index,
        //       customData: {
        //         title: grouping[date].length,
        //         class: 'bg-red-600 label-success text-white',
        //         data: grouping
        //       },
        //       dates: new Date(date),
        //     }
        //   })

        const building_series = _.reduce(reports.data, (result, value) => {
          const date = this.$moment(`${value.year}-${value.month}-${value.day}`).format('YYYY-MM-DD')
          if (!result[value.building_id]) {
            result[value.building_id] = {};
          }

          if (!result[value.building_id][date]) {
            result[value.building_id][date] = value.average
          }

          return result
        }, {})


        const days = enumerateDaysBetweenDates(
          this.$moment(this.$data.filter.dateFrom),
          this.$moment(this.$data.filter.dateTo)
        )

        const months = enumerateMonthsBetweenDates(
          this.$moment(this.$data.filter.dateFrom),
          this.$moment(this.$data.filter.dateTo)
        )

        const max = _.maxBy(selected_buildings, 'desks').desks + 5

        const series = (type) => selected_buildings
          .map((el) => {
          const markLines = [
            {
              name: this.$t('bookings.chart.total_desks'),
              yAxis: el.desks,
              label: {
                formatter: '{b}',
                position: 'middle'
              },
              silent: true,
              symbol: 'none'
            }
          ];

          if (this.$store.state.configuration.settings.booking.bookable_date) {
            markLines.push({
              name: this.$t('bookings.chart.possible_reservation'),
              xAxis: this.$store.state.configuration.settings.booking.bookable_date,
              label: {
                formatter: '{b}',
                position: 'middle'
              },
              silent: true,
              symbol: 'none'
            })
          }
          
          return {
            data: days.map(
                day => (
                  el.id in building_series && day in building_series[el.id] ? 
                    building_series[el.id][day] 
                      : 
                    0
                )
            ),
            name: el.name,
            type,
            markLine: {
              data: markLines
            }
          }
        })

        // const max = buildings.data.reduce((p, v) => p.desks > v.desks ? p.desks : v.desks)
        this.chartOptions.yAxis.max = max
        this.chartOptions.xAxis.data = days;
        this.chartOptions.series = series('bar');
        this.chartOptions.legend.data = buildings.data.map((el) => el.name)

        // this.chartLineOptions.yAxis.max = 100
        this.chartLineOptions.max = max
        this.chartLineOptions.series = series('line');
        this.chartLineOptions.legend.data = this.chartOptions.legend.data

        const scatterSeries = {
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 1,
          label: {
              show: true,
              formatter: (params) => {
                  var d = echarts.number.parseDate(params.value[0]);
                  return d.getDate();
              },
              color: '#000'
          },
          data: days.map((day) => [day, 0])
        }

        const calendarSeries = selected_buildings.map((el) => {
          return {
            data: el.id in building_series ? Object.keys(building_series[el.id]).map(date => [date, building_series[el.id][date]]) : [],
            name: el.name,
            type: 'heatmap',
            coordinateSystem: 'calendar',
          }
        })

        this.calendarOptions.visualMap = {
          show: false,
          min: 0,
          max: max,
          calculable: true,
          seriesIndex: calendarSeries.map((el, index) => index+1),
          orient: 'horizontal',
          left: 'center'
        };

        this.calendarOptions.calendar[0].range = [dateFrom, dateTo];
        this.calendarOptions.series = [scatterSeries, ...calendarSeries];

        this.isLoading = false
      })
    },

    updateBuilding($event) {
      this.filter.building_info = $event
      this.buildings = [$event]
    },

    changeBuilding($event) {
      this.filter.building = $event
      this.pagination.currentPage = 1
      this.getData()
    },

    generatePDF() {
      this.getBookings(-1, false, false, true)
        .then(async (bookings) => {
          this.bookings_pdf_all = this.parseBookings(bookings.data.results)
          this.bookings_pdf = this.bookings_pdf_all.slice(0, 10)

			    const pdfContent = this.$refs.html2Pdf.$refs.pdfContent
			    const options = this.$refs.html2Pdf.setOptions()

          const pages = _.range(Math.ceil(this.bookings_pdf_all.length / 10));

          this.pdf.processing = true
          this.pdf.processing_pages = pages.length
          this.pdf.processing_page = 1

          let worker = html2pdf().set(options)
            .from(pdfContent).toContainer().get('container')

          pages.slice(1).forEach(page => {
              worker = worker.toPdf().get('pdf').then(pdf => {
                this.bookings_pdf = this.bookings_pdf_all.slice( page * 10, (page + 1) * 10 )
                this.pdf.processing_page = page
                return pdf.addPage()
              })
                .from(pdfContent).toContainer().get('container')
                .toCanvas().toPdf();
          })

          const pdfBlobUrl = await worker.save().output('bloburl')
          const res = await fetch(pdfBlobUrl)
          const blobFile = await res.blob()

          this.$emit('hasDownloaded', blobFile)
        })
        .finally(() => {
          this.isLoading = false
          this.pdf.processing = false
        })
    },

    saveCSV(bookings) {
      const csv = parse(bookings, { fields: this.table_fields.map(el => el.key) });
      const fileName = `flexapp_bookings_${this.$data.filter.dateFrom.toLocaleDateString().replace('.', '_')}_${this.$data.filter.dateTo.toLocaleDateString().replace('.', '_')}.csv`
      const fileToSave = new Blob([csv], {
          type: 'text/csv',
      });
      saveAs(fileToSave, fileName)
    },

    generateCSV() {
      this.getBookings(-1, false, false, true)
        .then(bookings => {
          this.saveCSV(bookings.data.results)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    saveJSON(bookings) {
      const fileName = `flexapp_bookings_${this.$data.filter.dateFrom.toLocaleDateString().replace('.', '_')}_${this.$data.filter.dateTo.toLocaleDateString().replace('.', '_')}.json`
      const fileToSave = new Blob([JSON.stringify(bookings)], {
          type: 'application/json',
      });
      saveAs(fileToSave, fileName)
    },

    generateJSON() {
      this.getBookings(-1, false, false, true)
        .then(bookings => {
          this.saveJSON(bookings.data.results)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setChartType(name) {
      this.filter.modeView = name
    },

    updateRangeDate(amount, day_or_month_or_year, first_day=false) {
      let dateFrom = this.$moment();
      let dateTo = this.$moment();

      if (amount < 0) {
        amount = amount * -1
        dateFrom = dateFrom.subtract(amount, day_or_month_or_year)
        dateTo = dateTo.subtract(1, day_or_month_or_year)
      } else if (amount > 0) {
        dateFrom = dateFrom.add(1, day_or_month_or_year)
        dateTo = dateTo.add(amount, day_or_month_or_year)
      } 

      if (first_day) {
        dateFrom = dateFrom.startOf(day_or_month_or_year)
        dateTo = dateTo.endOf(day_or_month_or_year)
      }

      this.$data.filter.dateFrom = dateFrom.toDate()
      this.$data.filter.dateTo = dateTo.toDate()

      this.updateRouter()
    },

    updateRouter() {
      this.$router.push({name: 'bookings_date', 
        params: {
          ...this.$route.params,
          date_start: this.$moment(this.$data.filter.dateFrom).format('YYYY-MM-D'),
          date_end: this.$moment(this.$data.filter.dateTo).format('YYYY-MM-D'),
          page: this.$data.pagination.currentPage
        }
      })
    },

    updatePage(page) {
      this.$data.pagination.currentPage = page
      this.updateRouter()
    }

  },
  created() {
    this.buildings$ = from(this.getBuildings())
    if (!this.$route.params.date_start) {
      this.updateRouter()
    } else {
      this.getBookings()
    }
  }
}
</script>

<template>
  <b-overlay :show.sync="isLoading" rounded="sm">
    <template #overlay>
      <b-spinner />
      <br />
      <span>{{ $t('bookings.processing_page', {page: pdf.processing_page, pages: pdf.processing_pages}) }}</span>
    </template>
    <div class="az-content az-content-dashboard">
      <div class="container" id="dashboard">
        
        <div class="az-content-body">
          <div class="az-dashboard-one-title">
            <div>
              <h2 class="az-dashboard-title">{{ $t('bookings.title') }}</h2>
              <p class="az-dashboard-text">{{ $t('bookings.desc') }}</p>
            </div>
            <v-header-select :only-select-building="true" :start-one-building="false" />
          </div>

          <b-row class="mg-b-20">
            <b-col cols="3">
              <div class="card card-dashboard-page-views" style="height:auto;">
                <div class="card-header">
                  <h6 class="card-title">{{ $t('bookings.summary.title') }}</h6>
                </div>
                <div class="card-body">

                  <div class="az-list-item">
                    <div>
                      <h6>{{ $t('bookings.summary.results') }}</h6>
                      <span></span>
                    </div>
                    <div>
                      <h6 class="tx-primary">{{ pagination.results }}</h6>
                      <span></span>
                    </div>
                  </div>


                  <div class="az-list-item">
                    <div>
                      <h6 style="text-align:left;">{{ $t('bookings.summary.most_desk') }}</h6>
                      <span>{{ $t('bookings.summary.most_desk_users_and_unique_users')}}</span>
                    </div>
                    <div v-if="summary.most_desk">
                      <h6 class="tx-primary">{{ summary.most_desk.desk__name }}</h6>
                      <span>
                        {{ summary.most_desk.total_users }} ({{ summary.most_desk.total_unique_users }})
                      </span>
                    </div>
                  </div>

                  <div class="az-list-item">
                    <div>
                      <h6>{{ $t('bookings.summary.count_unique_person') }}</h6>
                      <span></span>
                    </div>
                    <div>
                      <h6 class="tx-primary">{{ summary.count_unique_person }}</h6>
                      <span></span>
                    </div>
                  </div>

                  <div class="az-list-item">
                    <div>
                      <h6>{{ $t('bookings.summary.count_unique_desk') }}</h6>
                      <span></span>
                    </div>
                    <div>
                      <h6 class="tx-primary">{{ summary.count_unique_desk}}</h6>
                      <span></span>
                    </div>
                  </div>

                </div>
              </div>
            </b-col>

            <b-col cols="9">
              <div class="card card-dashboard-one mg-b-20" style="height:auto;">
                <div class="card-header">
                  <h6 class="card-title">{{ $t('bookings.statistics') }}</h6>
                  <p class="card-text"></p>

                  <div class="btn-group">

                    <b-dropdown text="Range date" variant="primary" >
                      <b-dropdown-item @click="updateRangeDate(30, 'day')">{{ $t('bookings.range.past_30_days') }}</b-dropdown-item>

                      <b-dropdown-item @click="updateRangeDate(-1, 'year', true)"> {{ $t('bookings.range.last_year') }}</b-dropdown-item>
                      <b-dropdown-item @click="updateRangeDate(-6, 'month', true)">{{ $t('bookings.range.last_6_months') }}</b-dropdown-item>
                      <b-dropdown-item @click="updateRangeDate(-1, 'month', true)">{{ $t('bookings.range.last_month') }}</b-dropdown-item>

                      <b-dropdown-item @click="updateRangeDate(0, 'year', true)">{{ $t('bookings.range.this_year') }}</b-dropdown-item>
                      <b-dropdown-item @click="updateRangeDate(0, 'month', true)">{{ $t('bookings.range.this_month') }}</b-dropdown-item>

                      <b-dropdown-item @click="updateRangeDate(1, 'month', true)">{{ $t('bookings.range.past_month') }}</b-dropdown-item>
                      <b-dropdown-item @click="updateRangeDate(6, 'month', true)">{{ $t('bookings.range.past_6_months') }}</b-dropdown-item>
                      <b-dropdown-item @click="updateRangeDate(1, 'year', true)">{{ $t('bookings.range.past_year')}} </b-dropdown-item>
                    </b-dropdown>

                    <b-button class="btn" style="border-top: none, border-bottom: none;"> </b-button>


                    <b-button class="btn" v-bind:class="{ active: filter.modeView == 'heatmap'}" @click="setChartType('heatmap')">{{ $t('bookings.chart.heatmap') }}</b-button>
                    <b-button class="btn" v-bind:class="{ active: filter.modeView == 'bar'}" @click="setChartType('bar')">{{ $t('bookings.chart.bar') }}</b-button>
                    <b-button class="btn" v-bind:class="{ active: filter.modeView == 'line'}" @click="setChartType('line')">{{ $t('bookings.chart.line') }}</b-button>
                  </div>
                </div>
                
                <div class="card-body">
                  <v-chart 
                    :key="'chart'" 
                    v-if="filter.modeView == 'bar'" 
                    class="qchart" 
                    :option="chartOptions"
                    :autoresize="true"
                  />

                  <v-chart 
                    :key="'calendar'" 
                    v-if="filter.modeView == 'heatmap'" 
                    class="qchart" 
                    :option="calendarOptions" 
                    :autoresize="true"

                  />

                  <v-chart 
                    :key="'chart-line'" 
                    v-if="filter.modeView == 'line'" 
                    class="qchart" 
                    :option="chartLineOptions"
                    :autoresize="true"
                    :update-options="chatUpdateOptions"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
         
          <b-container>
            <b-row>
              <b-col>
                <div class="card card-table-one mg-b-20" >

                  <b-row >
                    <b-col class="lg-9">
                      <h6 class="card-title">{{ $t('bookings.table.title') }}</h6>
                      <p class="mg-b-20">{{ $t('bookings.table.desc') }}: {{ pagination.results }}</p>
                    </b-col>
                    <b-col class="lg-3 text-align-right" style="text-align:right">
                      <b-dropdown class="m-md-2">
                        <template #button-content>
                          <i class="typcn typcn-download "></i>
                          {{ $t('checkins.actions.download')}}
                        </template>
                        <b-dropdown-item @click="generatePDF()">PDF</b-dropdown-item>
                        <b-dropdown-item @click="generateCSV()">CSV</b-dropdown-item>
                        <b-dropdown-item @click="generateJSON()">JSON</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>

                      <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="pagination.results"
                        :per-page="pagination.perPage"
                        @change="updatePage"
                        aria-controls="booking-table"
                      ></b-pagination>

                      <div class="table-responsive table-bookings">

                        <b-table
                          id="booking-table"
                          responsive="sm"
                          bordered
                          :fields="table_fields"
                          :items="bookings"
                        >

                          <template #cell(date_time)="data">
                            {{data.value[0]}}
                            <br />
                            <span class="tx-11 tx-gray-500">{{data.value[1]}}</span>
                          </template>

                          <template v-slot:cell(location)="row">
                            <ol :key="`location-${row.index}-${row.item.id}`" class="breadcrumb" style="margin-bottom: 0">
                              <li v-for="location in row.item.location" :key="`location-${row.index}-${row.item.id}-${location.id}`" class="breadcrumb-item">
                                {{ location.name }}
                              </li>
                            </ol>
                          </template>
                        </b-table>

                      </div>

                      <div v-if="bookings.length == 0">
                        <b-card
                          style="max-width: 100em; background-color:#eee"
                          class="lg-2 mg-b-20"
                        >
                          <b-card-text>
                            {{ $t('bookings.table.no_data') }}
                          </b-card-text>
                        </b-card>
                      </div>

                      <b-pagination
                        class="mg-t-20"
                        v-model="pagination.currentPage"
                        :total-rows="pagination.results"
                        :per-page="pagination.perPage"
                        @change="updatePage"
                        aria-controls="booking-table"
                      ></b-pagination>

                    </b-col>
                  </b-row>

                  <p class="mg-b-20"></p>

                  <vue-html2pdf 
                    :paginate-elements-by-height="1400"
                    ref="html2Pdf"
                    pdf-content-width="100%"
                    :manualPagination="true"
                  >
                    <section class="pdf-content" slot="pdf-content" >
                      <b-row v-if="filter.modeView == 'calendar'">
                        <b-col style="justify-content: center;">
                          <v-calendar
                            :masks="calendar.masks"
                            :locale="$i18n.locale"
                            :attributes="calendar.attributes"
                            class="custom-calendar max-w-full"
                            is-expanded
                          >
                            <template v-slot:day-content="{ day, attributes }">
                              <div class="flex flex-col h-full z-10 overflow-hidden">
                                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                                <div class="flex-grow overflow-y-auto overflow-x-auto">
                                  <p
                                    v-for="attr in attributes"
                                    :key="attr.key"
                                    class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                                    :class="attr.customData.class"
                                  >
                                    {{$i18n.t('Reserved desks: ')}}{{ attr.customData.title }}
                                  </p>
                                </div>
                              </div>
                            </template>
                          </v-calendar>
        
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <div class="table-responsive table-bookings">
                            
                            <b-table
                              responsive="sm"
                              bordered
                              :fields="table_fields"
                              :items="bookings_pdf"
                            >

                              <template #cell(date_time)="data">
                                {{data.value[0]}}
                                <br />
                                <span class="tx-11 tx-gray-500">{{data.value[1]}}</span>
                              </template>

                              <template #cell(location)="row">
                                <ol style="margin-bottom: 0" :key="`pdf-location-${row.item.id}-list`">
                                  <li v-for="(location, index) in row.item.location" :key="`pdf-location-${row.item.id}-${location.id}-${index}-b`">
                                    {{ location.name }}
                                  </li>
                                </ol>
                              </template>
                            </b-table>
                          </div>
                        </b-col>
                      </b-row>
                    </section>
                  </vue-html2pdf>

                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
  </b-overlay>
</template>


<style lang="css" scoped>

.qchart {
  height: 200px;
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>