<script>
import { COLORS } from '@/constants';
import chartMixin from '../mixins/chartMixin';

export default {
  name: 'area-chart',
  mixins: [chartMixin],
  computed: {
    computedColumns() {
      const columns = [...this.columns];
      columns.push({ type: 'string', role: 'style' });
      return columns;
    },
    computedRows() {
      const vm = this;
      const rows = [...vm.rows];

      return rows.map(row => row.concat(`point {
        size: 5;
        fill-color: ${COLORS.white};
        stroke-width: 2;
        stroke-color: ${COLORS.darkBlue};
        visible: true;
      }`));
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        chartArea: { width: '80%' },
        legend: 'none',
        colors: [COLORS.darkBlue],
      },
    };
  },
};
</script>

<template>
<vue-chart
  chart-type="AreaChart"
  :rows="computedRows"
  :columns="computedColumns"
  :options="options"
  ref="chart"
/>
</template>
