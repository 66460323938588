<template>
  <div class="az-signin-wrapper" >
    <b-spinner v-if="!error"></b-spinner>
    <div v-if="error">
      <h3>
        {{$t('loader.error_network')}}
      </h3>
      <br />
      <button class="form-control" @click="setup">{{$t('loader.refresh')}}</button>
    </div>
    <div style="opacity: 0">{{init}}</div>
  </div>
</template>

<script>


export default {
  name: 'loader',
  data() {
    return {
      error: false
    } 
  },
  methods: {
    setup() {
      const self = this;

      if (this.$store.state.auth.init) {
        // @TODO: use prev url stored 
        const prev_url = this.$router.history._startLocation
        this.$http.get('configuration/config', { timeout: 10000 }) 
          .then((data) => {
            this.$store.commit('configuration/load_settings', data.data)
            if (this.$route.query.nextUrl) {
              this.$router.replace(this.$route.query.nextUrl)
            } else if(this.$store.state.auth.user && this.$store.state.auth.user.roles && this.$store.state.auth.user.roles.includes("staff")) {
              this.$router.replace({
                name: 'my_dashboard', 
                params: { 
                  id: 'all',
                  floor_id: 'all',
                  room_id: 'all'
                } 
              })
            } else {
              this.$router.replace({
                name: 'login'
              })
            }
            

          })
          .catch((e) => {
            console.log("error:", e)
            self.error = true
          })        
      }
    }
  },
  computed: {
    init() {
      this.setup()
      return this.$store.state.auth.init
    }
  }
};
</script>