<template>
  <v-btn :style="cssVars"
         class="text-wrap"
         height="100%"
         @click="onClick"
         :disabled="disabled"
         :ripple="false"
  >{{ title }}</v-btn>
</template>

<script>
export default {
  name: "DiamondButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    onClickNavigateTo: {
    }
  },
  computed: {
    cssVars () {
      return {
        backgroundColor : this.color,
        opacity: this.hide ? 0 : 1
      }
    }
  },
  methods: {
    onClick() {
      if (this.onClickNavigateTo) this.$router.push(this.onClickNavigateTo);
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn {
  width: 50%;
  display: inline-block;
  word-wrap: break-word;
  text-transform: none;
  text-align: center;
  font-weight: bold;


  ::v-deep span {
    transform: rotate(-45deg);
    font-size : 1.2rem;
  }
}

</style>