export default {
  namespaced: true,
  state: {
    settings: {},
    init: false
  },
  mutations: {
    load_settings(state, settings) {
      state.settings = settings
      state.init = true
    },    
  },
  actions: {
  }
}