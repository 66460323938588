<template>
  <div class="az-signin-wrapper">
    <div class="az-card-signin"> 
      <b-form  @submit.stop.prevent="submit">
        <h1 class="az-logo">Flexapp {{ $t('login.dashboard') }}</h1>
        <div class="az-signin-header">
          <h2>{{ $t('login.welcome') }}</h2>
          <h4>{{ $t('login.welcome_description') }}</h4>
          <b-form-group :label="$t('login.username')" class="form-group"> 
            <b-form-input 
              v-model="credentials.username" 
              class="form-control"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateState('username')">
              {{ $t('login.username_fill') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('login.password')" class="form-group">
            <b-form-input 
              type="password"
              class="form-control" 
              v-model="credentials.password"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateState('password')">
              {{ $t('login.password_fill') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-invalid-feedback :state="validated_auth">
              {{ $t('login.error_authorization') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button class="ml-5" type="submit" variant="primary" >
            {{ $t('login.login') }}
            <b-spinner v-if="loggingIn" small label="Small Spinner" style="float:right"></b-spinner>
          </b-button>
          <v-btn class="ma-5" @click="loginAdfs()">{{ $t('Login for employees') }}</v-btn>



        </div>
      </b-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'login',

  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      loggingIn: false,
      subbmited: false,
      validated_auth: null,
      error: {
        username: null,
        password: null,
        server: null
      },
    };
  },
  mounted() {
    console.log("query:",this.$route.query)
    if(this.$route.query.code){
      this.$auth.oauth2('adfs', {
        url: 'users/adfs_login/',
        code: true,
        data: {
            code: this.$route.query.code
        },
        state: this.$route.query.state,
        redirect: {name: "home_page"},
        fetchUser: false
      })
      .then(() => {
          return this.$auth.fetch()
        })
        .then((data) => {
          this.$store.commit('auth/auth_success', data.data)
          if(data.data.roles.includes("staff")){
            this.$router.replace({
            name: 'my_dashboard', 
            params: { 
              id: 'all',
              floor_id: 'all',
              room_id: 'all'
            } 
          })
          }
          else {
            this.$router.replace({
            name: 'home_page'
          })
          }
          
        })
    }
    
  },
  methods: {
    validateState(name) {
      if (this.credentials[name].length || !this.subbmited) return true
      return false  
    },
    submit() {
      if (this.loggingIn) return


      this.error.username = !this.credentials.username
      this.error.password = !this.credentials.password

      this.subbmited = true
      this.validated_auth = null
      
      if (this.error.username || this.error.password) {
          return
      }

      this.$nprogress.start()
      this.loggingIn = true

      const self = this

      this.$auth.login({
        data: {
          username: this.credentials.username,
          password: this.credentials.password,
        },
        redirect: '/dashboard/buildings/all/all/all',
        fetchUser: false,
        rememberMe: true,
      })
        .then(() => {
          return this.$auth.fetch()
        })
        .then((data) => {
          this.$store.commit('auth/auth_success', data.data)
          if(data.data.roles.includes("staff")){
            this.$router.replace({
            name: 'my_dashboard', 
            params: { 
              id: 'all',
              floor_id: 'all',
              room_id: 'all'
            } 
          })
          }
          else {
            this.$router.replace({
            name: 'home_page'
          })
          }
          
        })
        .catch(e => {
          // this.error = true
          self.validated_auth = false
        })
        .finally(() => {
          self.loggingIn = false
          // this.$nprogress.stop()
        });
    },
    loginAdfs() {
      var redirect_uri=`https://${window.location.hostname}/login/`
      var base_url=this.$store.state.configuration.settings.theme.adfs_base_url
      var client_id=this.$store.state.configuration.settings.theme.adfs_client_id
      window.location = `${base_url}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}`
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 900;
  font-size: 150%;
}

.ev-login {
  text-align: left;
  margin-top: 5em;
  margin-bottom: 5em;
}

.login-form {
  margin-top:2em;
}
</style>
