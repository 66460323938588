<template>
  <div class="desk-choice-buttons">
      <v-btn
          color="primary"
          v-if="isOnNotifierList"
          elevation="2"
          @click="notifierDelete('desk', desk.id)"
      >{{$t('Remove this from notifier list')}}</v-btn>
      <v-btn
          v-if="!isOnNotifierList"
          elevation="2"
          @click="notifierAdd('desk', desk.id)"
      >{{$t('Add this to notifier list')}}</v-btn>
    <v-btn
        elevation="2"
        @click="goToBooking"
        :disabled="this.desk.reserved || this.desk.is_closed"
    >{{$t('Make booking')}}</v-btn>
  </div>
</template>

<script>

export default {
  name: "DeskActionButtons",
  props: {
    desk: {
      required: true,
    }
  },
  watch: {
    desk() {
      this.checkNotifiersList();
    }
  },
  mounted() {
    this.checkNotifiersList();
  },
  methods: {
    notifierAdd(type, id) {
      this.$http.post(`notifiers/${type}/${id}/`).catch((e) => (e));
      this.isOnNotifierList = true;
    },
    notifierDelete(type, id) {
      this.$http.delete(`notifiers/${type}/${id}/`).catch((e) => (e));
      this.isOnNotifierList = false;
    },
    checkNotifiersList() {
      this.$http.get('notifiers/').then((response) => {
        this.isOnNotifierList = !![].concat(response.data).find(notifier => notifier.desk === this.desk.id);
      });
    },
    goToBooking() {
      this.$router.push({ path: `booking_time_slots/${this.desk.id}` })
    }
  },
  data: () => ({
    isOnNotifierList: false
  }),
}
</script>

<style scoped lang="scss">
.desk-choice-buttons{
  display: flex;
  flex-direction: column;

  .v-btn {
    margin-bottom: 10px;
    text-transform: none;
  }
}
</style>