<script>
export default {
  methods: {
    collapedSidebar: function () {
      document.body.classList.toggle("az-header-menu-show");
    }
  }
}
</script>
<template>
  <div class="az-header">
    <div class="container">

      <div class="az-header-left">
        <a class="az-logo">
          <span></span> flexapp
        </a>
        <a href="#" id="azMenuShow" class="az-header-menu-icon d-lg-none" @click="collapedSidebar()"><span></span></a>
      </div><!-- az-header-left -->

      <div class="az-header-menu">
        <div class="az-header-menu-header">
          <a href="index.html" class="az-logo"><span></span> flexapp</a>
          <a href="#" class="close" @click="collapedSidebar()">&times;</a>
        </div><!-- az-header-menu-header -->
        <ul class="nav">
          <li class="nav-item" v-if="$store.state.configuration.settings.theme.enable_public_register_guest" v-bind:class="{ active: $route.name == 'register_guest'}">
            <router-link class="nav-link"
              :to="{ name: 'public_register_guest' }">
              <i class="typcn typcn-user-outline "></i> {{ $t('menu.register_guest') }}
            </router-link>
          </li>
        </ul>
      </div><!-- az-header-menu -->

    </div><!-- container -->
  </div><!-- az-header -->
</template>