<template>
  <v-dialog
      v-model="filterView"
      max-width="800"
      @click:outside="closeWithoutApply"
  >
    <template v-slot:default="filterView">
      <v-card>
        <v-card-title>
          <h2 class="text-h4">
            Filter
          </h2>
        </v-card-title>
        <v-card-text>
          <h2 class="text-h6 mb-2">
            {{$t('People')}}
          </h2>
          <v-chip-group
              v-model="chosenFilters"
              column
              multiple
          >
            <v-chip
                color="primary"
                label
                filter
                outlined
                v-for="tag in filterTags"
                :key="tag.text"
            >
              {{ tag.text }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
              color="primary"
              outlined
              @click="clearFiltersTags"
          >
            {{$t('Clear all')}}
          </v-btn>
          <v-btn
              color="primary"
              @click="applyFilters"
          >
            {{$t('Apply filters')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {FilterTypeEnum} from "../../variables";

export default {
  name: "ColleaguesFilterPopup",
  props: {
    filterView: {},
    clearFilter: {},
  },
  data: () => ({
    prevFilters: [],
    chosenFilters: [],
    filterTags: [
      { text: i18n.t('Working at home'), value: FilterTypeEnum.WORKING_HOME },
      { text: i18n.t('Working in the building'), value: FilterTypeEnum.WORKING_BUILDING },
      { text: i18n.t('No working at anywhere'), value: FilterTypeEnum.NO_WORKING },
      { text: i18n.t('Only BHV'), value: FilterTypeEnum.BHV },
    ],
  }),
  methods: {
    clearFiltersTags() {
      this.chosenFilters = [];
    },
    closeWithoutApply() {
      this.chosenFilters = [...this.prevFilters];
      this.$emit('filterView', false);
    },
    async applyFilters() {
      this.prevFilters = [...this.chosenFilters];
      this.$emit('appliedFilters', this.chosenFilters.map(index => this.filterTags[index].value));
      this.$emit('filterView', false);
    }
  },
  watch: {
    clearFilter() {
      this.prevFilters = [];
      this.chosenFilters = [];
    },
  },
}
</script>

<style scoped>

</style>