<script>
import placeholder from '@/assets/media/placeholder.png';

export default {
  name: 'user-card',
  props: {
    user: { type: Object },
    size: { type: String, default: 'normal' },
  },
  computed: {
    image() {
      if (this.user.anonymous) {
        return placeholder;
      }
      return this.user.profile.thumb || placeholder;
    },
    name() {
      if (this.user.anonymous) {
        return this.$t('user_info.anonymous');
      }
      return `${this.user.first_name} ${this.user.last_name}`;
    },
  },
};
</script>

<template>
<div :class="['user-card',]">
  <div class="user-media"><img :src="image" /></div>
  <span class="user-title">{{ name }}</span>
</div>
</template>

<style src="../styles/UserCard.scss" lang="scss" />
