<script>

import { BDropdownItem } from 'bootstrap-vue/src/components/dropdown/dropdown-item.js';

export default {
  name: 'bvDropdownItem',
  mixins: [BDropdownItem],
  methods: {
    onClick(evt) {
      this.$emit('click', evt)
      // this.closeDropdown()
    }
  }
};
</script>