<template>
  <v-app>
    <v-card
        flat
        height="100%"
        tile
    >
      <NavigationBar :is-home-page="false"/>
      <CheckInOptions/>
      <div class="middle-block-divider"></div>
    </v-card>
    <Footer/>
  </v-app>
</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
import CheckInOptions from "./CheckInOptions";

export default {
  name: "CheckIn",
  components: {
    NavigationBar,
    CheckInOptions,
    Footer
  }
}
</script>

<style scoped>

</style>