import Vue from 'vue';
import Vuex from 'vuex';
import auth from './stores/auth'
import app from './stores/app'
import configuration from './stores/configuration'
import findDesk from "./stores/findDesk";
import date from "./stores/date";

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  modules: {
    auth,
    findDesk,
    configuration,
    app,
    date
  }
});
