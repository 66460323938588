
<script>
import Select2 from '@/components/Select2';
import { TimePicker } from "@/components/DateTimePicker";
import { TheMask } from 'vue-the-mask'
import BuildingFormChoose from '@/components/BuildingFormChoose/BuildingFormChoose.vue';

export default {
  name: 'register_guest',
  components: {
    Select2,
    TheMask,
    BuildingFormChoose
  },
  created() {
    const self = this

    this.reset_form = { ...this.form }
    this.interval_id = setInterval(() => {
      self.now = Date.now()
      self.form.time_start = this.$moment(self.now).format('HH:mm')
      self.form.date = this.$moment(self.now).format('YYYY-MM-DD')
    }, 1000)

    this.is_public = this.$router.history.current.meta.public

  },
  beforeDestroy () {
    clearInterval(this.interval_id)
  },
  data() {
    const self = this
    const now = Date.now()

    return {
      response_errors: {},
      form: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        phone_number: '',
        desk: null,
        meeting_room: null,
        date: this.$moment(now).format('YYYY-MM-DD'),
        time_start: this.$moment(now).format('HH:mm'),
        time_end: null,
        visit_person: ''
      },
      now: now,
      isLoading: false,
      submitting: false,
      subbmited: false,
      validated: null,
      errors: [],
      error: {
        first_name: null,
        last_name: null,
        contact_required: null,
        visit_person_required: null,
        desk_or_room: null,
        time_start: null,
        time_end: null
      },

      desks: [],
      meeting_rooms: [],
      buildings: [],

      settings_desks: {
        allowClear: true,
        placeholder: this.$t('register_guest.placeholder_desk'),
        // minimumInputLength: 2,
        escapeMarkup: (data) => { return data },
        ajax: {
          delay: 1000,
          cacheDataSource: [{
            value: 'desk', 
            key: 1
          }],
          transport: self.getDesksData,
          cache: true
        }
      },
      
      settings_rooms: {
        allowClear: true,
        placeholder: this.$t('register_guest.placeholder_room'),
        // minimumInputLength: 2,
        escapeMarkup: (data) => { return data },
        ajax: {
          delay: 1000,
          cacheDataSource: [],
          transport: self.getRoomsData,
          cache: true
        }
      },

      settings_buildings: {
        allowClear: true,
        placeholder: this.$t('register_guest.placeholder_building'),
        escapeMarkup: (data) => { return data },
        ajax: {
          delay: 1000,
          cacheDataSource: [],
          transport: self.getBuildingsData,
          cache: true
        }
      }
    };
  },
  methods: {
    debug() {
      const now = Date.now()

      this.form = {
        first_name: 'Maciej',
        last_name: 'P',
        email: 'maciej.po@gmail.com',
        company_name: 'Test',
        phone_number: '1231231',
        desk: 46,
        meeting_room: null,
        date: this.$moment(now).format('YYYY-MM-DD'),
        time_start: this.$moment(now).format('HH:mm'),
        time_end: '23:00'
      }

      this.desks = [{
        id: 46,
        text: "Desk 4"
      }]
    },

    reset() {
      this.form = { ...this.reset_form }

      this.now = Date.now()
      this.form.time_start = this.$moment(this.now).format('HH:mm')
      this.form.date = this.$moment(this.now).format('YYYY-MM-DD')

      if (this.$refs.bfc) this.$refs.bfc.reset()
    },

    exists(name) {
      return this.form[name] != null
    },

    validateState(name) {
      if (!this.subbmited) return true

      if (name === 'email') {
        return this.validateEmail(this.form[name])
      }

      if (name === 'desk' || name === 'meeting_room') {
        return this.exists('desk') || this.exists('meeting_room')
      }

      if (this.form[name] && this.form[name].length) return true
      return false  
    },

    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    submit() {
      if (this.submitting) return

      const is_public = this.$router.history.current.meta.public

      this.errors = []
      this.response_errors = {}
      
      this.error.contact_required = !this.form.phone_number && !this.form.email

      if (!is_public) {
        this.error.desk_or_room = !this.form.desk && !this.form.meeting_room
      } 

      this.error.visit_person_required = !this.form.visit_person

      this.error.time_end = !this.form.time_end || this.form.time_end <= this.form.time_start

      this.subbmited = true
      this.validated = null
      
      if (
        this.error.contact_required || 
        this.error.desk_or_room || 
        this.error.visit_person_required ||
        this.error.time_end
      ) {
          return
      }

      this.$nprogress.start()
      this.submitting = true

      const self = this

      this.$http.post('users/register_quest/', this.form)
        .then(() => {
          self.subbmited = false
          self.$bvModal.show('modal-success')
          self.reset()
        })
        .catch(e => {
          self.validated = false
          if (e.response && e.response.data && e.response.data.errors) { 
            self.response_errors = e.response.data.errors

            const fnFilter = (ob, exclude) => {
              const k = Object.keys(ob)
              return k
                .filter((el) => {
                  return exclude.indexOf(el) === -1
                })
                .map((el) => {
                  return ob[el] 
                })
            }
            self.errors = [...Object.values(fnFilter(e.response.data.errors, ['desk', 'meeting_room'])).map(el => el[0])]
            self.validated = true
          }
        })
        .finally(() => {
          self.submitting = false
        })
      ;
    },

    getDesksData(params, success, failure) {
      this.$http.get('autocomplete/desks/', { params: { ...params.data } })
        .then((data) => {
          data.data.results = data.data.results.map(el => {
            return {
            ...el, 
            text: _.unescape(el.text)
          }})

          success(data.data)
        })
        .catch((e) => failure(e))
    },

    getRoomsData(params, success, failure) {
      this.$http.get('autocomplete/meeting_rooms/', { params: { ...params.data } })
        .then((data) => {
          data.data.results = data.data.results.map(el => {
            return {
            ...el, 
            text: _.unescape(el.text)
          }})
          success(data.data)
        })
        .catch((e) => failure(e))
    },

    getBuildingsData(params, success, failure) {
      this.$http.get('autocomplete/buildings/', { params: params.data })
        .then((data) => {
          data.data.results = data.data.results.map(el => {
            return {
            ...el, 
            text: _.unescape(el.text)
          }})
          success(data.data)
        })
        .catch((e) => failure(e))
    },

    updateMeetingRoom($event) {
      this.form.meeting_room_info = $event
      this.meeting_rooms = [$event]
    },

    changeMeetingRoom($event) {
      this.form.meeting_room = $event   
    },

    updateDesk($event) {
      this.form.desk_info = $event
      this.desks = [$event]
    },

    changeDesk($event) {
      this.form.desk = $event   
    },
    
    updateBuilding($event) {
      this.form.building_info = $event
      this.buildings = [$event]
    },

    changeBuilding($event) {
      this.form.building = $event   
    },

    setDesk(desk) {
      console.log('setDesk:', desk)

      this.desks = (desk) ? [{id: desk.id, text: desk.name}] : []
      this.form.desk = desk ? desk.id : null
      this.form.desk_info = desk ? {id: desk.id, text: desk.name} : null
    },

    setRoom(room) {
      console.log('setRoom:', room, room ? true : false)
      this.meeting_rooms = room ? [{id: room.id, text: room.name}] : []
      this.form.meeting_room = room ? room.id : null
      this.form.meeting_room_info = room ? {id: room.id, text: room.name} : null
    }

  },
};
</script>

<template>
  <b-overlay :show.sync="isLoading" rounded="sm">
    <div class="az-content az-content-dashboard">
      <div class="container" id="dashboard">

        <b-modal 
          ref="modal-succes"
          id="modal-success"
          hide-header
          ok-only

          centered
          size="sm"
          modal-class="modal-confirm"
        >
          <div class="d-block text-center">
            {{ $t('register_guest.sucess_save') }}
          </div>
        </b-modal>

        <b-container>
          <b-row>
            <b-col>
              <div class="card card-table-one">
                <h1 class="card-title">{{ $t('register_guest.title') }}</h1>
                <p class="mg-b-20">{{ $t('register_guest.desc') }}</p>

                <b-form @submit.stop.prevent="submit">
                  <b-row>
                    <b-col>
                      <b-form-group :label="$t('register_guest.first_name')" class="form-group"> 
                        <b-form-input 
                          v-model="form.first_name" 
                          class="form-control"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="validateState('first_name')">
                          {{ $t('login.username_fill') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('register_guest.last_name')" class="form-group"> 
                        <b-form-input 
                          v-model="form.last_name" 
                          class="form-control"
                        ></b-form-input>
                        <b-form-invalid-feedback :state="validateState('last_name')">
                          {{ $t('login.username_fill') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group :label="$t('register_guest.company_name')" class="form-group"> 
                    <b-form-input 
                      v-model="form.company_name" 
                      class="form-control"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validateState('company_name')">
                      {{ $t('login.username_fill') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="$t('register_guest.email')" class="form-group"> 
                    <b-form-input 
                      v-model="form.email" 
                      class="form-control"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validateState('email')">
                      {{ $t('login.username_fill') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="$t('register_guest.phone_number')" class="form-group"> 
                    <b-form-input 
                      v-model="form.phone_number" 
                      class="form-control"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validateState('phone_number')">
                      {{ $t('login.username_fill') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="$t('register_guest.visiting_person')" class="form-group"> 
                    <b-form-input 
                      v-model="form.visit_person" 
                      class="form-control"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validateState('visit_person')">
                      {{ $t('register_guest.visiting_person_fill') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <div class="divider"></div>

                  <b-tabs v-if="!$router.history.current.meta.public" card content-class="tab-building-switch" fill >
                    <b-tab :title="$t('register_guest.tab_manual')" active>
                      <b-form-group :label="$t('register_guest.desk_or_meeting_room')" class="form-group"> 
                        <building-form-choose ref="bfc" class="flex-nogrow" :setDesk="setDesk" :setRoom="setRoom"  />

                        <b-form-invalid-feedback :state="validateState('meeting_room')">
                          {{ $t('register_guest.required_place') }}
                        </b-form-invalid-feedback>
                        
                      </b-form-group>
                    </b-tab>

                    <b-tab :title="$t('register_guest.tab_search')">
                      <b-form-group :label="$t('register_guest.desk')" class="form-group"> 
                        <Select2
                          ref="select2_desk"
                          v-model="form.desk"
                          :options="desks"
                          :settings="settings_desks"
                          @change="changeDesk($event)"
                          @select="updateDesk($event)"
                        />
                        <b-form-invalid-feedback :state="validateState('desk')">
                          {{ $t('register_guest.required_place') }}
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="response_errors && response_errors.desk" :state="false">
                          {{ response_errors.desk[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>


                      <b-form-group :label="$t('register_guest.room')" class="form-group"> 
                        <Select2
                          ref="select2_meeting_room"
                          v-model="form.meeting_room"
                          :options="meeting_rooms"
                          :settings="settings_rooms"
                          @change="changeMeetingRoom($event)"
                          @select="updateMeetingRoom($event)"
                        />
                        <b-form-invalid-feedback :state="validateState('meeting_room')">
                          {{ $t('register_guest.required_place') }}
                        </b-form-invalid-feedback>
                        
                        <b-form-invalid-feedback v-if="response_errors && response_errors.meeting_room" :state="false">
                          {{ response_errors.meeting_room[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>

                  <b-row>
                    <b-col>
                      <b-form-group :label="$t('register_guest.date')" class="form-group"> 
                        <b-form-input 
                          v-model="form.date" 
                          disabled
                          class="form-control"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('register_guest.time_start')" class="form-group"> 

                        <b-form-input 
                          v-model="form.time_start" 
                          disabled
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('register_guest.time_end')" class="form-group"> 

                        <the-mask 
                          v-model="form.time_end"
                          :masked="true"
                          class="form-control"
                          :mask="['##:##']" 
                        />

                        <b-form-invalid-feedback :state="validateState('time_end')">
                          {{ $t('register_guest.time_end_required') }}
                        </b-form-invalid-feedback>

                        <b-form-invalid-feedback :state="!this.error.time_end">
                          {{ $t('register_guest.time_end_invalid') }}
                        </b-form-invalid-feedback>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group>
                    <b-form-invalid-feedback :state="validated">
                      {{ $t('login.error_authorization') }}
                    </b-form-invalid-feedback>

                    <b-form-invalid-feedback :state="errors.length == 0">
                      <span v-for="(error, index) in errors" :key="`error-${index}`">
                        {{ error }}
                      </span>
                    </b-form-invalid-feedback>
    
                  </b-form-group>

                  <b-button type="submit" variant="primary" >
                    {{ $t('register_guest.save') }}
                    <b-spinner v-if="submitting" small label="Small Spinner" style="float:right"></b-spinner>
                  </b-button>
                </b-form>

              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </b-overlay>
</template>