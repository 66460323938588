import Vue from 'vue';
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    date: {},
    init: false,
  },
  mutations: {
    set_date(state, date) {
      state.date = date
      state.init = true
    }
  },
  actions: {
  }
}