<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title class="desk-details-title">
          <div class="desk-details-name">
            <span>{{this.desk.name}}</span>
            <span v-if="this.desk.user">{{this.userName}}</span>
          </div>
          <div>
            <v-chip
                class="desk-label"
                color="primary"
                text-color="white"
            >
              {{this.desk.desk_label}}
            </v-chip>
            <v-chip v-if="!this.desk.reserved && !this.desk.is_closed"
                    style="margin-left: 10px"
                    color="green"
                    text-color="white"
            >
              {{ $t('FREE') }}
            </v-chip>
            <v-chip v-if="this.desk.reserved || this.desk.is_closed"
                    style="margin-left: 10px"
                    color="orange"
                    text-color="white"
            >
              {{ this.desk.reserved ? $t('BUSY') : $t('CLOSED')}}
            </v-chip>
          </div>
      </v-card-title>
      <v-card-text>
        <DeskMapPreview v-if="displayMap" :desk="this.desk"/>
        <DeskActionButtons :desk="this.desk"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="closeDialog"
        >
          {{$t('Close')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeskActionButtons from "./DeskActionButtons";
import DeskMapPreview from "./DeskMapPreview";

export default {
  name: "DeskDetails",
  components: {
    DeskActionButtons,
    DeskMapPreview
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    desk: {
      required: true,
    },
    displayMap: {
      default: true
    }
  },
  methods: {
    closeDialog (event) {
      this.$emit('onClose', false);
    }
  },
  computed: {
    dialog() {
      return this.isOpen;
    },
    userName () {
      return this.desk.user ? `${this.desk.user.first_name} ${this.desk.user.last_name}` : null;
    }
  }
}
</script>

<style scoped lang="scss">
.desk-details-title {
  display: flex;
  justify-content: space-between;

  .desk-details-name {
    display: flex;
    flex-direction: column;
  }
}
</style>
