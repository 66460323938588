<template>
  <div>
    <v-card
        flat
        height="100%"
        tile
    >
      <NavigationBar is-home-page/>
      <SelectionOptions/>
      <div class="middle-block-divider"></div>
    </v-card>
    <Footer/>
  </div>
</template>

<script>
import NavigationBar from "../NavigationBar/NavigationBar";
import SelectionOptions from "../SelectionOptions/SelectionOptions";
import Footer from "../Footer/Footer";

export default {
  name: "Home",
  components: {
    NavigationBar,
    SelectionOptions,
    Footer
  }
}
</script>

<style scoped>
.middle-block-divider{
  width: 100%;
  height: 10vh;
}
</style>