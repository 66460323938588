<script>
export default {
  data() {
    return {
      form: {
        first_name: this.$store.state.auth.user.first_name, 
        last_name: this.$store.state.auth.user.last_name,
        language: this.$store.state.auth.user.profile.language
      },
      languages: [
        { value: 'en', text: 'English' },
        { value: 'nl', text: 'Netherlands' }
      ]
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.$http.put('users/self', {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        profile: {
          language: this.form.language
        }
      } , { params: { } }) 
        .then((data) => {
          this.$store.commit('auth/auth_update_user', data.data)
          this.$i18n.locale = data.data.profile.language
        })
        .catch((e) => {
          // @TODO
        })
    },
    onReset(evt) {      
      evt.preventDefault()
      this.form = { 
        first_name: this.$store.state.auth.user.first_name, 
        last_name: this.$store.state.auth.user.last_name,
        language: this.$store.state.auth.user.profile.language
      }
    }
  }
}
</script>

<template>
  <b-overlay rounded="sm">
    <div class="az-content az-content-dashboard">
      <div class="container" id="dashboard">
        <div class="az-content-left az-content-left-components">
          
        </div>
        <div class="az-content-body pd-lg-l-40 d-flex flex-column">
          <div class="az-content-breadcrumb">
            <span>{{ $t('breadcrumbs.profile') }}</span>
            <span>{{ $t('breadcrumbs.profile_edit') }}</span>
          </div>
          
          <h2 class="az-content-title">{{ $t('profile.edit_title') }}</h2>
          
          <b-form @submit="onSubmit" @reset="onReset">
            
            <b-form-group id="input-group-2" :label="$t('profile.first_name')" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.first_name"
                required
                :placeholder="$t('profile.enter_first_name')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" :label="$t('profile.last_name')" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.last_name"
                required
                :placeholder="$t('profile.enter_last_name')"
              ></b-form-input>
            </b-form-group>
            
            <b-form-group id="input-group-3" :label="$t('profile.language')" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="form.language"
                :options="languages"
                required
              ></b-form-select>
            </b-form-group>

            <b-button type="submit" variant="primary">{{ $t('profile.submit') }}</b-button>
            <b-button type="reset" variant="danger">{{ $t('profile.reset') }}</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </b-overlay>
</template>