import Vue from 'vue';
import Router from 'vue-router';
import Loader from '@/components/Loader/Loader.vue';
import Login from '@/components/Login/Login.vue';
import Dashboard from '@/components/Dashboard';
import Checkins from '@/components/Checkins/Checkins.vue';
import Bookings from '@/components/Bookings/Bookings.vue';
import ProfileEdit from '@/components/Profile/ProfileEdit.vue';
import RegisterGuest from '@/components/RegisterGuest/RegisterGuest.vue'
import ErrorNoFound from '@/components/ErrorNoFound/ErrorNoFound.vue'

import auth from '@websanova/vue-auth/src/auth';
import Main from '@/layouts/Main.vue';
import MainPublic from '@/layouts/public/Main.vue';

import store from './store'
import WebApp from "./components/WebApp/WebApp";
import CheckIn from "./components/WebApp/components/CheckIn/CheckIn";
import Home from "./components/WebApp/components/Home/Home";
import FindDesk from "./components/WebApp/components/FindDesk/FindDesk";
import FloorLayout from "./components/WebApp/components/FloorMap/FloorLayout";
import BookingTimeSlots from "./components/WebApp/components/BookingTimeSlots/BookingTimeSlots";
import Reservations from "./components/WebApp/components/Booking/Reservations";
import ColleaguesList from "./components/WebApp/components/FindColleague/ColleaguesList";
import ColleagueDetails from "./components/WebApp/components/FindColleague/ColleagueDetails";
import ColleaguesView from "./components/WebApp/components/FindColleague/ColleaguesView";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/dashboard/loader',
      name: 'loading',
      component: Loader,
      meta: {
        loader: true
      }
    },
    {
      path: '/app',
      name: 'web_app',
      component: WebApp,
      meta: {
        auth: true,
        loader: true
      },
      children: [
        {
          path: '/app',
          name: 'home_page',
          component: Home,
          meta: {
            auth: true,
          },
        },
        {
          path: '/app/checkins',
          name: 'checkin',
          component: CheckIn,
          meta: {
            auth: true
          }
        },
        {
          path: '/app/find_desk',
          name: 'find_desk',
          component: FindDesk,
          meta: {
            auth: true
          }
        },
        {
          path: '/app/reservations',
          name: 'reservations',
          component: Reservations,
          meta: {
            auth: true,
            loader: true
          }
        },
        {
          path: '/app/floor_layout',
          name: 'floor_layout',
          component: FloorLayout,
          props: true,
          meta: {
            auth: true
          }
        },
        {
          path: '/app/booking_time_slots/:id',
          name: 'booking_time_slots',
          component: BookingTimeSlots,
          meta: {
            auth: true
          }
        },
        {
          path: '/app/colleagues',
          name: 'colleagues',
          component: ColleaguesView,
          meta: {
            auth: true
          },
          children: [
            {
              path: '',
              name: 'colleagues_list',
              component: ColleaguesList,
              meta: {
                auth: true
              }
            },
            {
              path: 'colleague_details/:id',
              name: 'colleague_details',
              component: ColleagueDetails,
              meta: {
                auth: true
              }
            },
          ]
        },

      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        auth: true,
        no_start: true
      },
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch('auth/logout')
        console.log("logout")
        return next({ name: 'login' })
      }
    },

    {
      path: '/dashboard/404',
      name: 'error_404',
      component: ErrorNoFound,
      meta: {
        public: true
      }
    },

    // Public pages
    {
      path: '/dashboard/public/',
      name: 'public_dashboard',
      component: MainPublic,
      meta: {
        no_start: true,
        public: true
      },
      children: [
        {
          path: '/dashboard/public/register_guest',
          name: 'public_register_guest',
          component: RegisterGuest,
          meta: {
            public: true,
            config_requires: ['enable_public_register_guest']
          }
        }
      ]
    },
    // Private pages
    {
      path: '/dashboard/',
      name: 'dashboard',
      component: Main,
      meta: {
        auth: true,
        no_start: true
      },
      children: [
        {
          path: '/dashboard/buildings/:id/:floor_id/:room_id',
          name: 'my_dashboard',
          component: Dashboard,
          meta: {
            auth: true,
          },
        },
        {
          path: '/dashboard/checkins',
          name: 'checkins',
          component: Checkins,
          meta: {
            auth: true
          }
        },

        {
          path: '/dashboard/bookings/:id/:floor_id/:room_id',
          name: 'bookings',
          component: Bookings,
          meta: {
            auth: true,
            config_requires: ['menu_enable_booking',],
          }
        },

        {
          path: '/dashboard/bookings/:id/:floor_id/:room_id/:date_start/:date_end/:page',
          name: 'bookings_date',
          component: Bookings,
          meta: {
            auth: true,
            config_requires: ['menu_enable_booking',],
          }
        }, 

        {
          path: '/dashboard/register_guest',
          name: 'register_guest',
          component: RegisterGuest,
          meta: {
            auth: true,
            config_requires: ['enable_register_guest',],
          }
        },
        {
          path: '/dashboard/profile/edit',
          name: 'profile_edit',
          component: ProfileEdit,
          meta: {
            auth: true
          }
        },
      ]
    },
    {
      path: '/dashboard/*',
      redirect: '/dashboard/loader',
    },
    {
      path: '*',
      beforeEnter (to) {
        window.location = `/404`
      }
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.body.classList.remove("az-header-menu-show");
  if(to.name === "logout") return next()

  const goStartPage = () => {
    const is_public = to.meta.public
    console.log('goStartPage', to, is_public)

    if (is_public) {
      return next({ name: 'error_404' })
    }
    if(store.state.auth.user && store.state.auth.user.roles.includes("staff"))
    {
      next({ 
        path: '/dashboard/buildings/all/all/all', 
      })
    }
    else return next({ name: 'home_page' })
  }

  const nextLoader = () => {
    next({
      path: '/dashboard/loader',
      query: { nextUrl: to.meta.no_start ? null : to.fullPath }
    })
  }

  const nextLogin = () => {
    next({
      name: 'login',
      query: { nextUrl: to.meta.no_start ? null : to.fullPath }
    })
  }

  const canNextPage = (cb, f) => {
    console.log('canNextPage')
    const index = to.matched.findIndex(record => record.meta.config_requires != null)
    if (index === -1) return true

    const config = to.matched[index].meta.config_requires.map(
      el => store.state.configuration.settings.theme[el]
    )

    return config.indexOf(false) === -1
  }


  if (!store.state.auth.init || !store.state.configuration.init) {
    console.log('1')

    if(to.matched.some(record => record.meta.loader)) {
      return next()
    }

    return nextLoader()
    
  } else if(to.matched.some(record => record.meta.auth)) {
    console.log('2')

    if (!store.state.auth.logged) {
      console.log("not logged", store.state.auth)
      return nextLogin()
    }

    if(!canNextPage() || to.name == "dashboard"){
      return goStartPage()
    }
      
    return next()
    
  } else if (to.matched.some(record => record.meta.guest)) {
    console.log('3')

    if(!store.state.auth.logged){
      console.log("not logged", store.state.auth)
      return next()
    } 
    
    return goStartPage()
    
  } else {
    console.log('4')

    if (to.meta.auth && !store.state.auth.logged || to.path === "/") {
      return next({name: 'login'})
    } 
     
    if (!canNextPage()) return goStartPage()

    return next()

  }

})


export default router

