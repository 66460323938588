export default {
    async getSettings() {
        const response = await this.$http.get('configuration/config', {timeout: 10000});
        this.$store.commit('configuration/load_settings', response.data);
    },
    async getUserSettings() {
        const response = await this.$http.get('users/self/', {timeout: 10000});
        this.$store.commit('auth/auth_update_user', response.data);
    },
}
