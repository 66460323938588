<script>
import { COLORS } from '@/constants';
import chartMixin from '../mixins/chartMixin';

export default {
  name: 'bar-chart',
  mixins: [chartMixin],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        chartArea: { width: '90%' },
        legend: { position: 'top' },
        vAxis: { ticks: [] },
        colors: [COLORS.darkerBlue, COLORS.lightBlue],
        tooltip: { isHtml: true },
        animation: {
          duration: 300,
          easing: 'out',
        },
      },
    };
  },
};
</script>

<template>
<vue-chart
  chart-type="ColumnChart"
  :columns="columns"
  :rows="rows"
  :options="options"
  ref="chart"
/>
</template>

