<template>
  <v-btn
      text
      style="background-color: #fff"
      color="error"
      @click.stop="onCancel"
      :loading="cancelReservation.loading"
  >
    <v-icon left>
      mdi-close
    </v-icon>
    {{ $t('Cancel') }}
  </v-btn>
</template>

<script>
import {ActionButtonsEnum, API_VERSION} from "../../variables";

export default {
  name: "ReservationCancelButton",
  props: {
    reservationId: {
    },
  },
  data: () => ({
    cancelReservation: {
      actionFrom: ActionButtonsEnum.CANCEL,
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  methods: {
    async deleteReservation() {
      this.cancelReservation = {
        ...this.cancelReservation,
        loading: true,
        succeed: false
      }
      this.canceling();
      try {
        await this.$http.delete(
            `booking/reservations/delete/${this.reservationId}/`,
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.cancelReservation.succeed = true;
      } catch (e) {
        this.cancelReservation = {
          ...this.cancelReservation,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.cancelReservation.loading = false;
        this.canceling();
      }
    },
    async onCancel() {
      await this.deleteReservation();
      this.$emit('onCancel', this.cancelReservation)
    },
    canceling() {
      this.$emit('canceling', this.cancelReservation.loading)
    },
  }
}
</script>

<style scoped>

</style>