<template>
  <div class="desk-map-info" @click="goToMap">
    <div
        v-if="!this.getPreviewMap.error"
        ref="mapContainer"
    >
      <v-img
          v-if="map"
          v-resize="onResize"
          :src="map"
      >
      </v-img>
      <v-responsive v-if="!map"  :aspect-ratio="2" class="map-progress">
        <v-row
            align="center"
            justify="center"
            style="width: 100%"
        >
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-row>
      </v-responsive>
      <IconPin v-if="map" :desk-info="deskUpdateInfo" :pin-size="pinSize" blink/>
    </div>
    <MapNotAvailable v-if="this.getPreviewMap.error"/>
  </div>
</template>

<script>
import IconPin from "../IconPin/IconPin";
import MapNotAvailable from "./MapNotAvailable";
import moment from "moment";

export default {
  name: "DeskMapPreview",
  components: {
    IconPin,
    MapNotAvailable
  },
  props: {
    desk: {
      required: true,
    }
  },
  data: () => ({
    map: '',
    imageW: null,
    imageH: 226,
    pinSize: 14,
    getPreviewMap: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  watch: {
    async desk() {
      await this.getDeskMap();
    },
    map() {
      this.updateImgSize();
    },
  },
  async mounted() {
    await this.getDeskMap();
  },
  computed: {
    deskUpdateInfo() {
      return {
        ...this.desk,
        ...this.setDeskCoordinate(this.pinSize/2)
      };
    },
  },
  methods: {
    async getDeskMap() {
      this.map = null;
      this.getPreviewMap = {
        ...this.getPreviewMap,
        loading: true,
        succeed: false
      }
      try {
        const response = await this.$http.get(`/buildings/desks/${this.desk.id}/map`);
        this.map = response.data.map;
        this.getPreviewMap.succeed = true;
      } catch (e) {
        this.getPreviewMap = {
          ...this.getPreviewMap,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.getPreviewMap.loading = false;
      }
    },
    setDeskCoordinate(pinSize=0) {
      return {
        x: 0.5 * this.imageW - pinSize,
        y: 0.5 * this.imageH - pinSize,
      };
    },
    goToMap() {
      this.$store.commit('date/set_date', moment().startOf('day').format('YYYY-MM-DD'));
      this.$store.commit('findDesk/set_searched_desk', this.desk);
      this.$router.push( {name: 'floor_layout'});
    },
    updateImgSize() {
      this.imageH = this.$refs.mapContainer.clientHeight;
      this.imageW = this.$refs.mapContainer.clientWidth;
    },
    onResize() {
      if (this.$refs.mapContainer.clientHeight > 0) {
        this.updateImgSize();
      }
    }
  },
}
</script>

<style scoped>
.desk-map-info {
  position: relative;
  height: 230px;
  margin-bottom: 20px;
}

.map-progress {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>