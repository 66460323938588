<template>
    <div class="action-button-flex">
      <v-container class="content">
        <v-row justify="space-between" v-for="row in this.buttons">
          <template v-for="button in row">
            <template v-if="!button.icon">
              <DiamondButton
                  class="quarter-diamond"
                  :disabled="button.disabled"
                  :hide="button.hide"
                  :title="button.title"
                  :color="button.color"
                  :on-click-navigate-to="button.navigateToRoute"
              />
            </template>
            <template v-else>
              <IconDiamondButton
                  class="quarter-diamond"
                  :icon="button.icon"/>
            </template>
          </template>
        </v-row>
      </v-container>
    </div>
</template>

<script>

import DiamondButton from "../DiamondButton/DiamondButton";
import IconDiamondButton from "../IconDiamondButton/IconDiamondButton";

export default {
  name: "DiamondButtons",
  components: {
    DiamondButton,
    IconDiamondButton
  },
  props: {
    buttons: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

.action-button-flex{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 60%;
  min-width: 30%;

  &:before{
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.container.content {
  .row {
    &:first-of-type {
      .quarter-diamond {
        position: relative;
        top: -10px;
        &:first-of-type {
          right: 10px;
        }
        &:last-of-type {
          right: -10px;
        }
      }
    }

    &:last-of-type {
      .quarter-diamond {
        position: relative;
        bottom: -10px;
        &:first-of-type {
          left: -10px;
        }
        &:last-of-type {
          left: 10px;
        }
      }
    }
  }

}

.content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
}

@media only screen and (min-width: 650px) {
  .action-button-flex {
    max-width: 400px;
    min-width: unset;
  }
}

@media only screen and (max-height: 600px) {
  .action-button-flex {
    width: 35%;
  }
}

@media only screen and (max-width: 400px) and (max-height: 600px) {
  .action-button-flex {
    width: 50%;
  }
}

</style>