<script>

import Select2 from '@/components/Select2';
import vSelect from 'vue-select'

import '@lazy-copilot/datetimepicker/dist/datetimepicker.css'
import { DateTimePicker, DateTimePickerModal } from "@lazy-copilot/datetimepicker";

import { BModal, BFormCheckbox } from 'bootstrap-vue'

import VueHtml2pdf from 'vue-html2pdf'
import { saveAs } from 'file-saver';
import { parse } from 'json2csv';


export default {
  components: {
    Select2,
    vSelect,
    'b-modal': BModal,
    'b-form-checkbox':  BFormCheckbox,
    DateTimePicker,
    DateTimePickerModal,
    VueHtml2pdf
  },
  
  data() {
    const self = this;

    return {
      isLoading: false,

      meeting_room_info: null,
      desk_info: null,
      person_info: null,

      meeting_room: null,
      desk: null,
      person: null,
      
      only_guest: false,
      disable_pretty_date: false,

      checkins: [],
      table: {
        fields: [],
      },
      persons: [],
      desks: [],
      meeting_rooms: [],

      dateFrom: this.$moment().subtract(14, 'days').toDate(),
      dateTo: this.$moment().endOf('day').toDate(),

      timeFrom: '00:00',
      timeTo: '23:59',

      settings_persons: {
        allowClear: true,
        search: '',
        placeholder: this.$t('checkins.search.placeholder_person'),
        minimumInputLength: 2,
        ajax: {
          delay: 1000,
          transport: self.getUsersData
        }
      },
      settings_desks: {
        allowClear: true,
        placeholder: this.$t('checkins.search.placeholder_desk'),
        minimumInputLength: 2,
        ajax: {
          delay: 1000,
          transport: self.getDesksData
        }
      },
      settings_rooms: {
        allowClear: true,
        placeholder: this.$t('checkins.search.placeholder_room'),
        minimumInputLength: 2,
        ajax: {
          delay: 1000,
          transport: self.getRoomsData
        }
      }
    }
  },
  computed: {
    table_fields: (self, show_ids = false) => {
      const enable_register_guest = self.$store.state.configuration.settings.theme.enable_register_guest
      return [
        { key: 'id', sortable: true },
        { key: 'desk_name', sortable: true, hide: [self.only_guest] },
        { key: 'room_name', sortable: true, hide: [self.only_guest] },
        { key: 'type', sortable: true, hide: [self.only_guest] },
        { key: 'user_name', sortable: true, hide: [self.only_guest] },
        { key: 'guest_name', sortable: true, hide: [!enable_register_guest] },
        { key: 'guest_company', sortable: true, hide: [!enable_register_guest], show: [ self.only_guest ] },
        { key: 'guest_email', sortable: true, hide: [!enable_register_guest], show: [ self.only_guest ] },
        { key: 'guest_phonenumber', sortable: true, hide: [!enable_register_guest], show: [ self.only_guest ] },
        { key: 'visit_person', sortable: true, hide: [!enable_register_guest] },
        { key: 'created', sortable: true, formatter: (value, key, item) => {
          if (value) {
            if (self.disable_pretty_date) {
              return self.$moment(value).format('LLL')
            }
            return self.$moment(value).fromNow()
          }
          return '-'
        } },
        { key: 'deactivated_at', sortable: true, formatter: (value, key , item) => {
          if (value) {
            if (self.disable_pretty_date) {
              return  self.$moment(value).format('LLL')
            }
            return self.$moment(value).fromNow()
          }
          return '-'
        } }
      ].filter((el) => {
        if (el.hide && el.show) {
          return el.hide.indexOf(true) === -1 && el.show.indexOf(true) !== -1
        }

        if (el.hide) {
          return el.hide.indexOf(true) === -1
        }

        if (el.show) {
          return el.show.indexOf(true) !== -1
        }

        return true
      })
    },
    filtered_checkins: (self) => {
      function pretty_date(date) {
        if (self.disable_pretty_date) {
          return date
        }
        return self.$moment(date).fromNow()
      }

      return self.checkins.filter((el) => {
        if (self.only_guest) {
          return el.guest !== null
        }

        return true
      })
    }
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get('reports/checkin_activities/', { params: { 
        room: this.meeting_room,
        desk: this.desk,
        user: this.person,
        // period: '2w',
        period: 'all',
        limit: -1,
        start_date: this.$moment(this.$data.dateFrom).format('YYYY-MM-DD'),
        // start_time: this.$data.timeFrom,
        end_date: this.$moment(this.$data.dateTo).format('YYYY-MM-DD'),
        // end_time: this.$data.timeTo,
      }})
      .then((data) => {
        this.checkins = data.data.checkins
      })
      .finally(() => {
        this.isLoading = false;
      })
    },

    getUsersData(params, success, failure) {
      this.$http.post('reports/users/', { params })
        .then((data) => {
          self.persons = data.data.data.map(({id, first_name, last_name}) => { return { id, text: `${first_name} ${last_name}` }})
          success({ results: self.persons })
        })
        .catch((e) => failure(e))
    },


    getUsersData2(search, loading) {
      if (search.length < this.data.settings_persons.minimumInputLength) return;
  
      loading(true)
      this.$http.post('reports/users/', { params: { data: { q: search }} })
        .then((data) => {
          self.persons = data.data.data.map(({id, first_name, last_name}) => { return { id, text: `${first_name} ${last_name}` }})
        })
        .catch((e) => {

        })
        .finally(() => {
          loading(false)
        })
    },

    getDesksData(params, success, failure) {
      this.$http.post('reports/desks/', { params })
        .then((data) => {
          self.desks = data.data.data.map(({id, name}) => { return { id, text: `${name}`}})
          success({ results: self.desks })
        })
        .catch((e) => failure(e))
    },

    getRoomsData(params, success, failure) {
      this.$http.post('reports/meeting_rooms/', { params })
        .then((data) => {
          self.meeting_rooms = data.data.data.map(({id, name}) => { return { id, text: `${name}`}})
          success({ results: self.meeting_rooms })
        })
        .catch((e) => failure(e))
    },
    
    updatePerson($event){
      this.person_info = $event
      this.persons = [$event]
    },

    changePerson($event) {
      this.person = $event  
      this.getData()
    },

    updateDesk($event) {
      this.desk_info = $event
      this.desks = [$event]
    },

    changeDesk($event) {
      this.desk = $event
      this.getData()
    },

    updateMeetingRoom($event) {
      this.meeting_room_info = $event
      this.meeting_rooms = [$event]
    },

    changeMeetingRoom($event) {
      this.meeting_room = $event
      this.getData()
    },

    setDesk(row) {
      this.room_info = null
      this.room = null

      this.person_info = null
      this.person = null

      this.updateDesk({ id: row.item.desk, text:row.item.desk_name })
      this.changeDesk(row.item.desk)

    },
    setUser(row) {

      this.room_info = null
      this.room = null

      this.desk_info = null
      this.desk = null

      this.updatePerson({ id: row.item.user, text:row.item.user_name })
      this.changePerson(row.item.user)
    },
    setRoom(row) {
      this.desk_info = null
      this.desk = null

      this.person_info = null
      this.person = null

      this.updateMeetingRoom({ id: row.item.room, text:row.item.room_name })
      this.changeMeetingRoom(row.item.room)
    },
    goRegisterGuest() {
      this.$router.push({name: 'register_guest',})
    },

    setDateRange($event ){
      // this.$data.timeFrom = `${$event.startTime.HH}:${$event.startTime.mm}`
      // this.$data.timeTo = `${$event.endTime.HH}:${$event.endTime.mm}`
      this.$data.dateFrom = $event.startDate
      this.$data.dateTo = $event.endDate
      this.$bvModal.hide('modal-1')
      this.getData()
    },

    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    },

    generateCSV() {
      const csv = parse(this.checkins, { fields: this.table_fields.map(el => el.key) });

      const fileName = `flexapp_${this.$data.dateFrom.toLocaleDateString().replace('.', '_')}_${this.$data.dateTo.toLocaleDateString().replace('.', '_')}.csv`

      const fileToSave = new Blob([csv], {
          type: 'text/csv',
          name: fileName
      });

      saveAs(fileToSave, fileName)
    },

    generateJSON() {
      const fileName = `flexapp_${this.$data.dateFrom.toLocaleDateString().replace('.', '_')}_${this.$data.dateTo.toLocaleDateString().replace('.', '_')}.json`

      const fileToSave = new Blob([JSON.stringify(this.checkins)], {
          type: 'application/json',
          name: fileName
      });

      saveAs(fileToSave, fileName)
    }

  },
  created() {
    this.getData()
  }
}
</script>
<template>
  <b-overlay :show.sync="isLoading" rounded="sm">
    <div class="az-content az-content-dashboard">
      <div class="container" id="dashboard">     

        <b-modal 
          ref="modal-calendar"
          id="modal-1"
          hide-header
          hide-footer
        >
          <date-time-picker-modal 
            @cancelHandler="() => this.$bvModal.hide('modal-1')"
            @submitHandler="setDateRange($event)"
            :startDate="dateFrom"
            :endDate="dateTo"
            :singleDate="false"
            timeFormat="HH:mm"
          />
        </b-modal>

        <b-container>
          <b-row>
            <b-col>
              <div class="card card-table-one mg-b-20" >


                <div class="az-dashboard-one-title">
                  <div>
                    <h6 class="card-title">{{ $t('checkins.search.title') }}</h6>
                    <p class="az-dashboard-text"></p>
                  </div>

                  <div class="az-content-header-right">
                    <div class="media">
                      <div class="media-body" v-b-modal.modal-1>
                        <label>{{ $t('dashboard.search.start_datetime') }}</label>
                        <h6>{{ dateFrom ? `${this.$moment(dateFrom).format('MMM Do YY')} ${timeFrom}` : '-' }}</h6>
                      </div>
                    </div>
                    <div class="media">
                      <div class="media-body" v-b-modal.modal-1>
                        <label>{{ $t('dashboard.search.end_datetime') }}</label>
                        <h6>{{ dateTo ? `${this.$moment(dateTo).format('MMM Do YY')} ${timeTo}` : '-' }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
        
                <b-row>
                  <b-col>

                    <!-- <v-select 
                      :value="text"
                      :options="persons" 
                      @search="getUsersData2"
                    >
                      <template slot="no-options">
                        <div v-if="">
                          Please enter 2 or more characters
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.text }}
                        </div>
                      </template>
                    </v-select> -->

                    <Select2
                      v-model="person"
                      :options="persons"
                      :settings="settings_persons"
                      @change="changePerson($event)"
                      @select="updatePerson($event)"
                      @input="(value) => $emit('input', value)" 
                    />
                  </b-col>
                  <b-col>
                    <Select2
                      v-model="desk"
                      :options="desks"
                      :settings="settings_desks"
                      @change="changeDesk($event)"
                      @select="updateDesk($event)"
                    />
                  </b-col>
                  <b-col>
                    <Select2
                      v-model="meeting_room"
                      :options="meeting_rooms"
                      :settings="settings_rooms"
                      @change="changeMeetingRoom($event)"
                      @select="updateMeetingRoom($event)"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="margin-top: 10px;">
                    <b-card>
                      <div v-if="$store.state.configuration.settings.theme.enable_register_guest"> 
                        <b-form-group
                          style="margin-bottom: 0;"
                          label-for="checkbox-only-guest"
                          :description="$t('checkins.search.only_guest_description')"
                          label-align-sm="right"
                          label-cols-sm="0"
                          label-cols-lg="0"
                          content-cols-sm
                          content-cols-lg="6"
                        >
                          <b-form-checkbox
                            id="checkbox-only-guest"
                            v-model="only_guest"
                            name="checkbox-only-guest"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{$t('checkins.search.only_guest')}}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                      <b-form-group
                        style="margin-bottom: 0;"
                        label-for="checkbox-pretty-date"
                        :description="$t('checkins.search.disable_pretty_date_description')"
                        label-align-sm="right"
                        label-cols-sm="0"
                        label-cols-lg="0"
                        content-cols-sm
                        content-cols-lg="6"
                      >
                        <b-form-checkbox
                          id="checkbox-pretty-date"
                          v-model="disable_pretty_date"
                          name="checkbox-pretty_date"
                          :value="true"
                          :unchecked-value="false"
                        >
                          {{$t('checkins.search.disable_pretty_date')}}
                        </b-form-checkbox>
                      </b-form-group>


                    </b-card>
                  </b-col>

                </b-row>
                <p class="mg-b-20"></p>
                <b-row>
                  <b-col class="lg-9">
                    <h6 class="card-title">{{ $t('checkins.table.title') }}</h6>
                    <p class="mg-b-20">{{ $t('checkins.table.desc') }}: {{ checkins.length }}</p>
                  </b-col>
                  
                  <b-col class="lg-3 text-align-right" style="text-align:right">
                    <b-button v-if="$store.state.configuration.settings.theme.enable_register_guest" @click="goRegisterGuest()">
                      <i class="typcn typcn-user-outline "></i>
                      {{ $t('register_guest.button')}}
                    </b-button>

                    <b-dropdown class="m-md-2">
                      <template #button-content>
                        <i class="typcn typcn-download "></i>
                        {{ $t('checkins.actions.download')}}
                      </template>
                      <b-dropdown-item @click="generatePDF()">PDF</b-dropdown-item>
                      <b-dropdown-item @click="generateCSV()">CSV</b-dropdown-item>
                      <b-dropdown-item @click="generateJSON()">JSON</b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>

                <div class="table-responsive table-checkins">
                  <b-table
                    responsive="sm"
                    bordered
                    :fields="table_fields"
                    :items="filtered_checkins"
                  >
                    <template v-slot:cell(desk_name)="row">
                      <a class="btn" @click="setDesk(row)">{{ row.value }}</a>
                    </template>

                    <template v-slot:cell(room_name)="row">
                      <a class="btn" @click="setRoom(row)">{{ row.value }}</a>
                    </template>

                    <template v-slot:cell(user_name)="row">
                      <a class="btn" @click="setUser(row)">{{ row.value }}</a>
                    </template>
                  </b-table>
                </div>

                <div v-if="checkins.length == 0">
                  <b-card
                    style="max-width: 100em; background-color:#eee"
                    class="lg-2 mg-b-20"
                  >
                    <b-card-text>
                      {{ $t('checkins.table.no_data') }}
                    </b-card-text>
                  </b-card>

                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <vue-html2pdf 
      :paginate-elements-by-height="1400"
      ref="html2Pdf"
      pdf-orientation="landscape"
      pdf-content-width="100%"
    >
      <section class="pdf-content" slot="pdf-content" >
        <div>
          <b-row>
            <b-col>
              Checkins ({{checkins.length}})
            </b-col>
            <b-col style="text-align: right;">
              {{this.$moment(dateFrom).format('MMM Do YY')}} {{timeFrom}} - {{this.$moment(dateTo).format('MMM Do YY')}} {{timeTo}}
            </b-col>
          </b-row>
        </div>

        <div v-if="person">
          Selected person: {{ person_info.text }}
        </div>

        <div v-if="desk">
          Selected desk: {{ desk_info.text }}
        </div>

        <div v-if="meeting_room">
          Selected room: {{ meeting_room_info.text }}
        </div>

        <hr />

        <div class="table-responsive">
          <b-table
            responsive="sm"
            bordered
            :fields="table_fields"
            :items="filtered_checkins"
          >
          </b-table>
        </div>

        <div v-if="checkins.length == 0">
          <b-card
            style="max-width: 100em;"
            class="lg-2 mg-b-20"
          >
            <b-card-text>
              {{ $t('checkins.table.no_data') }}
            </b-card-text>
          </b-card>
        </div>

      </section>
    </vue-html2pdf>
  </b-overlay>
</template>