<script>
export default {
  name: 'statistics',
  props: {
    data: { required: true },
  },
};
</script>
<template>
  <div class="card card-dashboard-pageviews">
    <div class="card-header">
      <h6 class="card-title">{{ $t('dashboard.statistic') }}</h6>
      <p class="card-text">{{ /* $t('dashboard.statistic_desc') */ }}</p>
    </div>

    <div class="card-body">
      <div
        class="az-list-item"          
        v-for="statistic in data"
        :key="statistic.label"
      >
        <div>
          <h6>{{ statistic.label }}</h6>
        </div>
        <div>
          <h6 class="tx-primary">{{ statistic.value }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>