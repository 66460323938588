<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <v-text-field
          v-model="searchText"
          dense
          style="margin: 10px 10px 0 10px"
          outlined
          clearable
          :label="searchLabel"
          prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-btn
          @click="() => this.filterView = true"
          style="margin-bottom: 15px"
          class="mx-2"
          icon
      >
        <v-icon
            title="Filter"
            large
            dark>
          mdi-tune
        </v-icon>
      </v-btn>
    </div>
    <v-card
        tile
        dense
        color="primary"
    >
    </v-card>
    <ColleaguesFilterBar
        v-if="getUsersResponse.succeed && appliedFilters.length"
        :applied-filters="appliedFilters"
        :hidden-results="resultsHidden"
        @clearFilters="clearFilters"
    />
    <v-virtual-scroll
        v-if="users && users.results.length"
        style="margin: auto"
        :items="items"
        height="85vh"
        @scroll.native="scrolling"
        bench="10"
        item-height="100"
        >
          <template v-slot:default="{ item }">
            <div
                v-ripple
                @click="onColleagueClick(item)"
                class="clickable-info d-flex justify-space-between align-center text-center pa-3 text-h5"
            >
              <v-avatar
                  class="user-avatar"
                  style="margin: 0 30px"
                  rounded
                  size="44"
                  color="grey lighten-1">
              <v-icon color="#ffffff">
                mdi-account-circle
              </v-icon>
              </v-avatar>
              {{`${item.first_name} ${item.last_name}`}}
              <v-spacer></v-spacer>
              <v-avatar
                  class="user-avatar"
                  rounded
                  size="22"
                  :color="item.active_checkins.length ? 'success': 'error'">
              </v-avatar>
            </div>
          </template>
    </v-virtual-scroll>
    <span
        class="no-users-find"
        v-if="getUsersResponse.succeed && (!users || users && !users.results.length)"
    >
      {{$t('No results were found for your search')}}
    </span>
    <ColleaguesFilterPopup
        :filterView="filterView"
        :clear-filter="clearFilterTags"
        @filterView="filterViewChange"
        @appliedFilters="onAppliedFilters"
    />
  </div>
</template>

<script>
import {API_VERSION} from "../../variables";
import NavigationBar from "../NavigationBar/NavigationBar";
import {debounce} from 'lodash';
import ColleaguesFilterPopup from "./ColleaguesFilterPopup";
import ColleaguesFilterBar from "./ColleaguesFilterBar";

export default {
  name: "ColleaguesList",
  components: {
    NavigationBar,
    ColleaguesFilterPopup,
    ColleaguesFilterBar
  },
  async mounted() {
    await this.getUsersList(this.searchText);
  },
  data: () => ({
    clearFilterTags: false,
    appliedFilters: [],
    filterView: false,
    searchText: '',
    users: {
      count: 0,
      filtered_count: 0,
      next: null,
      previous: null,
      results: []
    },
    items: [],
    getUsersResponse: {
      loading: false,
      error: false,
      errorInfo: null,
      succeed: false
    }
  }),
  methods: {
    async getUsersList(query, filters) {
      this.getUsersResponse = {
        ...this.getUsersResponse,
        loading: true,
        succeed: false
      }
      try {
        const filters_url = filters ? `&include=${filters.join(',')}` : '';
        const response = await this.$http.get(
            `users/?${query === 'bhv' ? 'is_bhv=true' : `query=${query}`}${filters_url}`,
            {
              headers: {
                'X-APP-Version': '7.10.7',
                'X-API-Version': API_VERSION
              }
            }
        );
        this.users = {...response.data};
        this.items = [...response.data.results];
        this.getUsersResponse.succeed = true;
      } catch (e) {
        this.getUsersResponse = {
          ...this.getUsersResponse,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.getUsersResponse.loading = false;
      }
    },
    async getNextUsers() {
      this.getUsersResponse = {
        ...this.getUsersResponse,
        loading: true,
        succeed: false
      }
      try {
        const response = await this.$http.get(this.users.next);
        this.users = {...response.data};
        this.items = [...this.items, ...response.data.results];
        this.getUsersResponse.succeed = true;
      } catch (e) {
        this.getUsersResponse = {
          ...this.getUsersResponse,
          error: true,
          errorInfo: e.response.data
        }
      } finally {
        this.getUsersResponse.loading = false;
      }
    },
    getSearchUser: debounce(async function(searchedText) {
      await this.getUsersList(searchedText, this.appliedFilters);
    }, 200),
    async scrolling(event) {
      const element = event.currentTarget || event.target
      if (element && element.scrollHeight - element.scrollTop <= element.clientHeight + 400) {
        this.$emit('scroll-end');
        if (this.users.next) {
          await this.getNextUsers();
        }
      }
    },
    onColleagueClick(item) {
      this.$router.push({name: 'colleague_details', params: {id: item.id}});
    },
    async clearFilters() {
      this.appliedFilters = [];
      this.clearFilterTags = !this.clearFilterTags;
      await this.getUsersList(this.searchText, this.appliedFilters);
    },
    filterViewChange(event) {
      this.filterView = event;
    },
    async onAppliedFilters(filters) {
      this.appliedFilters = filters;
      await this.getUsersList(this.searchText, this.appliedFilters);
    }
  },
  watch: {
    async searchText(newValue) {
      await this.getSearchUser(newValue || '');
    }
  },
  created () {
    this.scrolling = debounce(this.scrolling, 200)
  },
  computed: {
    searchLabel() {
      return i18n.t('Search');
    },
    filterLabel() {
      return i18n.t('switch.filter');
    },
    resultsHidden() {
      return this.users.count - this.users.filtered_count
    }
  }
}
</script>

<style lang="scss" scoped>
.clickable-info {
  transition: 0.3s;

  &::before {
    background-color: transparent;
  }

  &:hover {
    background-color: #F5F5F5;
    cursor: pointer;
  }
}

.user-avatar {
  transform: rotate(45deg);
  .v-icon {
    transform: rotate(-45deg);
  }
}

.no-users-find {
  padding-top: 10vh;
  font: 1.2rem bold;
  display: block;
  text-align: center;
}

</style>